<template>
  <div class="live-type-video-wrap program-video-list-sw">
    <div class="swiper-container live-type-video" id="liveTypeVideo">
      <div class="swiper-wrapper">
        <SwLiveVideo
          v-for="(item, index) in swList"
          :key="index"
          swType="program"
          :videoId="item.videoId"
          :img="item.img"
          :tit="item.tit"
          :cont="item.cont"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import SwLiveVideo from "@/components/SwLiveVideo.vue";

export default {
  name: "ProgramBannerSw",
  props: ["swList"],
  data() {
    return {
      swCont: "",
    };
  },
  mounted() {
    this.swCont = new Swiper("#liveTypeVideo", {
      slidesPerView: "1",
      spaceBetween: 15,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
      centeredSlides: true,
    });
    this.swCont.slideTo(1, 0);
  },
  updated() {
    console.log("== updated ==");
    // console.log(this.$("#" + this.swId).find(".live-type-video-box").length);
    this.swCont.update();
  },
  components: {
    SwLiveVideo,
  },
};
</script>