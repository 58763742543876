<template>
  <div>
    <VideoLightBox />
    <RadioLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="search-box">
        <div class="page-tit">SEARCH</div>
        <div class="page-area">
          <!-- Program -->
          <div v-if="srcType == 1">
            <div class="program-video-list-area">
              <ProgramBanner
                :mainProgramData="mainProgramData"
                v-if="mainProgramData.mainProgramId"
              />
            </div>
            <div class="live-type-video-wrap program-video-list-sw">
              <ProgramBlurSw
                ind="1"
                swName="bannerProgramSw"
                :swId="mainProgramData.mainProgramId"
                :swList="SwProgramVideoList"
                :swPage="mainProgramData.page"
                :swPageTotal="mainProgramData.pageTotal"
                :isBanner="true"
                v-if="SwProgramVideoList.length != 0"
              />
            </div>
          </div>

          <!-- Radio -->
          <div v-if="srcType == 2">
            <div class="program-video-list-area">
              <ProgramBanner
                :mainProgramData="mainRadioData"
                v-if="mainRadioData.mainId"
              />
            </div>
            <div class="live-type-video-wrap program-video-list-sw">
              <RadioSrcCubeSw
                ind="1"
                swName="RadioSrcSw"
                :swId="mainRadioData.mainId"
                :swList="SwRadioVideoList"
                :swPage="mainRadioData.page"
                :swPageTotal="mainRadioData.pageTotal"
                :isBanner="true"
                v-if="SwRadioVideoList.length != 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// // import Swiper JS
// import Swiper from "swiper";

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";

import ProgramBanner from "@/components/ProgramBanner.vue";
import ProgramBlurSw from "@/components/ProgramBlurSw.vue";

import RadioSrcCubeSw from "@/components/RadioSrcCubeSw.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";
import RadioLightBox from "@/components/RadioLightBox.vue";

import {
  apiGetProgramDetail,
  apiGetProgramEpisodeList,
  apiGetRadioDetail,
} from "@/api/api.js";

export default {
  data() {
    return {
      //=== 目前搜尋進來的分類 ===
      srcType: "",
      eListType: "", //0=原視節目, 1=廣播
      //=== 節目 ===
      mainProgramData: {
        mainProgramId: "",
        img: "--",
        tit: "--",
        cont: "--",
        page: "",
        pageTotal: "",
      },
      //== 節目級數列表 ==
      SwProgramVideoList: [
        // {
        //   videoId: "1",
        //   img: "program_main_01.jpg",
        //   tit: "酷wawa",
        //   cont: "如果爸媽可以放任孩子天馬行空的想像力!放心~讓他們去做想作的事…你會發現，小孩其實是天生的工程師，創意十足且擅長解決問題。這個節目要做的一件最重要的事是，讓孩子自己去完成一些事，挑",
        //   // thumbNum: "10",
        //   // videoUrl: "https://youtu.be/u02MQmi-pCA",
        // },
        // {
        //   videoId: "2",
        //   img: "program_main_02.jpg",
        //   tit: "Tahu生火吧",
        //   cont: "Tahu生火吧【第三季】全新的一季《Tahu生火吧》我們來到了花蓮太魯閣國家公園上方的大同大禮部落，這次我們找來兩組家庭，分別有著不同的生活背景，他們要和接待家庭一起回家!在大同大禮部落度過四",
        //   // thumbNum: "20",
        //   // videoUrl: "https://youtu.be/cOoyPfGqpEc",
        // },
        // {
        //   videoId: "3",
        //   img: "program_main_03.jpg",
        //   tit: "Alang，我的名字",
        //   cont: "【第二季】第二季節目以萬榮鄉各村與瑞穗鄉富民村等部落地名內容為主。「Alang，我的名字」節目以全族語紀錄當代部落遷徙的故事，從部落語言觀察被殖民下的多樣性與複雜性，因同化政策，族語逐漸消失，為找",
        //   // thumbNum: "30",
        //   // videoUrl: "https://youtu.be/y8yH565s8kQ",
        // },
        // {
        //   videoId: "3",
        //   img: "program_main_03.jpg",
        //   tit: "Alang，我的名字",
        //   cont: "【第二季】第二季節目以萬榮鄉各村與瑞穗鄉富民村等部落地名內容為主。「Alang，我的名字」節目以全族語紀錄當代部落遷徙的故事，從部落語言觀察被殖民下的多樣性與複雜性，因同化政策，族語逐漸消失，為找",
        //   // thumbNum: "30",
        //   // videoUrl: "https://youtu.be/y8yH565s8kQ",
        // },
        // {
        //   videoId: "3",
        //   img: "program_main_03.jpg",
        //   tit: "Alang，我的名字",
        //   cont: "【第二季】第二季節目以萬榮鄉各村與瑞穗鄉富民村等部落地名內容為主。「Alang，我的名字」節目以全族語紀錄當代部落遷徙的故事，從部落語言觀察被殖民下的多樣性與複雜性，因同化政策，族語逐漸消失，為找",
        //   // thumbNum: "30",
        //   // videoUrl: "https://youtu.be/y8yH565s8kQ",
        // },
      ],
      //=== 廣播 ===
      mainRadioData: {
        mainId: "",
        img: "--",
        tit: "--",
        cont: "--",
        page: "",
        pageTotal: "",
      },
      SwRadioVideoList: [],
    };
  },
  mounted() {
    //=== 如果網址有帶值，需要先做搜尋 ===
    var nowId = this.$route.params.id;
    var nowType = this.$route.query.type; //節目-1 ,廣播-2
    this.srcType = nowType;
    if (nowType == 1) {
      this.eListType = 0;
      this.getProgramDetail(nowId);
      // this.getProgramEpisodeList(nowId, 1);
    } else {
      this.eListType = 1;
      this.getRadioDetail(nowId);
    }
    // var actKey = this.$route.query.key;
    // this.searchTypeAct = actType;
    // this.searchInfo.Key = actKey;
    // console.log(actType);
    // if (actType != undefined) {
    //   console.log("有直要搜尋");
    //   this.toApiSearch();
    // }
    //== 更多節目集數載入 ==
    this.$bus.$on("programSwPage", (data) => {
      // console.log("更多集數載入ID");
      // console.log("更多節目集數載入ID", data);
      // this.getProgramEpisodeList(data.typeId, data.page);
      // //== 取得集數更多列表 ==
      // if (data.isBanner) {
      //   this.getProgramEpisodeList(data.typeId, data.page);
      // } else {
      //   this.getProgramEpisodeList(data.typeId, data.page);
      // }
    });
    //== 更多廣播集數 ==
    this.$bus.$on("RadioSwPage", (data) => {
      // console.log("更多廣播集數", data);
      // this.getProgramEpisodeList(data.typeId, data.page);
    });
  },
  methods: {
    //=== 節目詳細資料 ===
    getProgramDetail(id) {
      apiGetProgramDetail({ ID: id })
        .then((result) => {
          console.log("節目'全'詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            this.mainProgramData.mainProgramId = resData.Program.ID.toString();
            this.mainProgramData.img = resData.Program.Img;
            this.mainProgramData.tit = resData.Program.Title;
            this.mainProgramData.cont = resData.Program.Introduction;
            //== 抓取級數列表 ==
            this.getProgramEpisodeList(resData.Program.ID.toString(), 1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 詳細廣播資訊 ===
    getRadioDetail(id) {
      apiGetRadioDetail({ ID: id })
        .then((result) => {
          console.log("廣播詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            console.log(resData.Program);
            console.log("radioDetail");
            var { ID, Img, Title, Introduction } = resData.Program;
            this.mainRadioData.mainId = ID.toString();
            this.mainRadioData.img = Img;
            this.mainRadioData.tit = Title;
            this.mainRadioData.cont = Introduction;
            //== 抓取級數列表 ==
            this.getProgramEpisodeList(ID.toString(), 1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目集數 ===
    getProgramEpisodeList(id, page) {
      apiGetProgramEpisodeList({
        index: page,
        ProgramType: this.eListType,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("集數列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var { Page, TotalPage } = result.data;
            if (this.srcType == 1) {
              //== 節目 ==
              console.log("節目集數列表");
              this.mainProgramData.page = Page;
              this.mainProgramData.pageTotal = TotalPage;
              var resData = result.data.RetData;
              console.log(resData);
              //== 取得節目所有集數後，放入sw列表  ==
              this.$.each(resData, (ind, val) => {
                var { ID, Episode, Title, Image, m3u8Url } = val;
                this.SwProgramVideoList.push({
                  id: ID.toString(),
                  img: Image, //http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg
                  tit: Episode, //"第" + Episode + "集"
                  cont: Title,
                  videoUrl: m3u8Url,
                });
              });
            } else if (this.srcType == 2) {
              //== 廣播 ==
              console.log("廣播集數列表");
              this.mainRadioData.page = Page;
              this.mainRadioData.pageTotal = TotalPage;
              var resRadioData = result.data.RetData;
              console.log(resRadioData);
              //== 取得節目所有集數後，放入sw列表  ==
              this.$.each(resRadioData, (ind, val) => {
                var { ID, Episode, Title, Image, m3u8Url } = val;
                this.SwRadioVideoList.push({
                  id: ID.toString(),
                  img: Image, //http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg
                  tit: Episode, //"第" + Episode + "集"
                  cont: Title,
                  videoUrl: m3u8Url,
                });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {},
  //== 元件銷毀前要取消監聽 ==<br />
  beforeDestroy() {
    // this.$bus.$off("toProgramTypeId");
    // this.$bus.$off("toProgramEpisodeId");
    this.$bus.$off("programSwPage");
    this.$bus.$off("RadioSwPage");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    ProgramBanner,
    ProgramBlurSw,
    RadioSrcCubeSw,
    VideoLightBox,
    RadioLightBox,
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>