<template>
  <div class="swiper-container news-banner-wrap" id="swiperNews">
    <div class="swiper-wrapper">
      <SlideNew
        v-for="(item, ind) in swNews"
        :key="ind"
        :videoId="item.videoId"
        :tabType="item.subtitle"
        :imgName="item.imgName"
        :tit="item.tit"
        :txt="item.txt"
      />
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import SlideNew from "@/components/SlideNew.vue";

export default {
  name: "IndexBannerSw",
  props: ["swNews"],
  data() {
    return {
      swCont: "",
    };
  },
  mounted() {
    //== swiper ==
    this.swCont = new Swiper("#swiperNews", {
      slidesPerView: "1",
      spaceBetween: 15,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    // this.swCont.slideTo(1, 0);
    // swiperNews.slideTo(1, 0);
  },
  updated() {
    this.swCont.update();
    this.swCont.slideTo(1, 0);
  },
  components: {
    SlideNew,
  },
};
</script>