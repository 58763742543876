<template>
  <div class="tit-blur-area program-tit">
    <!-- <img :src="require('@/assets/img/' + img)" alt="" />  :style="{backgroundImage:'url('+img+')'}" -->
    <!-- <img :src="img" alt="" /> -->
    <div class="tit-blur-box">
      <div class="tit-blur">
        <div class="tit">{{ tit }}</div>
        <!-- <div class="sup" v-html="sup"></div> -->
        <div class="sup cont scroll-w" v-html="cont"></div>
      </div>
      <!-- <div class="btn-box" @click="swShowBtn = !swShowBtn"> -->
      <div class="btn-box" @click="openEpisodeBtn(id)">
        <div class="w-blur-btn program-btn">觀看節目</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgramBlurTit",
  props: ["id", "img", "tit", "sup", "cont", "swIsShow"],
  mounted() {
    //=== scroll bar ===
    // window.$(".scroll-w").mCustomScrollbar();
  },
  methods: {
    openEpisodeBtn(id) {
      this.swShowBtn = !this.swShowBtn;
      console.log(id);
      //=== 去取得節目集數列表 ===
      this.$bus.$emit("toProgramEpisodeId", id);
    },
  },
  computed: {
    swShowBtn: {
      get() {
        return this.swIsShow;
      },
      set(val) {
        this.$emit("update:swIsShow", val);
      },
    },
  },
};
</script>