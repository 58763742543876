<template>
  <div class="swiper-slide">
    <a @click="openVideo(videoId)" :val="videoId">
      <div class="episodes-cover">
        <img :src="episodeImg" alt="" />
      </div>
      <div class="episodes-name">{{ episodeTit }}</div>
    </a>
  </div>
</template>
<script>
export default {
  name: "NewsProgramBox",
  props: ["episodeImg", "episodeTit","videoId",],
  data() {
    return {
      videoUrl: "",
    };
  },
  methods: {
    openVideo(id) {
      this.$bus.$emit("swOpenYTVideo", this.videoId);

      // this.getProgramEpisode("21082515011733126"); 1 - 21082515011733126
      // this.$bus.$emit("swOpenVideo", this.videoUrl);
    },
  }
};
</script>