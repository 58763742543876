<template>
  <div class="swiper-slide">
    <div class="program-sub-video-img" @click="openProgramVideo(videoUrl)">
      <!-- <img :src="require('@/assets/img/' + img)" alt="" /> -->
      <img :src="img" alt="" />
    </div>
    <div class="program-sub-cont">
      <div class="tit">{{ tit }}</div>
      <div class="cont">{{ cont }}</div>
    </div>
  </div>
</template>
<script>
import { apiGetProgramEpisode } from "@/api/api.js";

export default {
  name: "ProgramSubVideo",
  props: ["id", "img", "tit", "cont", "videoUrl"],
  data() {
    return {
      // videoUrl: "",
    };
  },
  methods: {
    openProgramVideo(url) {
      // console.log(id);
      if (url != null) {
        var videoData = {
          videoLink: url,
          swType: "program",
        };
        this.$bus.$emit("swOpenVideo", videoData);
        // this.$bus.$emit("swOpenVideo", url);
      } else {
        alert("目前沒有影音檔案");
      }
      //=== 取得單集內容 ===
      // if (this.videoUrl == "") {
      //   this.getProgramEpisode(id);
      // } else {
      //   this.$bus.$emit("swOpenVideo", this.videoUrl);
      // }
    },
    //=== 取得單集內容 ===
    getProgramEpisode(id) {
      apiGetProgramEpisode({ ID: id })
        .then((result) => {
          console.log("集數節目詳細資料");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var videoLink = result.data.RetData.m3u8_url;
            this.videoUrl = result.data.RetData.m3u8_url;
            if (videoLink != null) {
              this.$bus.$emit("swOpenVideo", videoLink);
            } else {
              console.log("沒有影音檔案");
              alert("目前沒有影音檔案");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>