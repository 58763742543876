<template>
  <div>
    <RadioLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="radio-bg-circle">
        <img src="@/assets/img/bg_circle_radio.png" alt="" />
      </div>
      <div class="page-tit">ALIAN RADIO STATION</div>
      <div class="page-area">
        <div class="radio-status-area">
          <div class="radio-status-box status-box01">
            現在播出：{{ radioTime.now }}
          </div>
          <div class="radio-status-box status-box02">
            下節播出：{{ radioTime.next }}
          </div>
        </div>
        <div class="radio-play-area">
          <div class="radio-play-info">
            <div class="tit">{{ radioTime.now }}</div>
            <div class="cont">
              <p>
                主持人：<span>{{ radioTime.host }}</span>
                <!-- Call out：<span>高維正</span> -->
              </p>
              <p>{{ radioTime.cont }}</p>
              <!-- <p>
                頻道：<span>Alian FM 96.3<br /></span>
              </p>
              <p>
                播出時間：<span>每週一至週五 20:00~21:00</span>
              </p> -->
            </div>
            <div class="icon-box">
              <!-- <div class="icon">
                <img src="@/assets/img/icon_thumb.svg" alt="" />
              </div> -->
              <div class="icon">
                <img src="@/assets/img/icon_heart.svg" alt="" />
              </div>
              <div class="icon">
                <img src="@/assets/img/icon_fb.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="radio-play-img">
            <div class="live-audio-box">
              <audio
                autoplay
                preload="auto"
                class="video-js vjs-theme-forest video-banner audio-box"
                id="LiveRadio"
              >
                <!-- controls="controls" preload="auto" poster="@/assets/img/video_default.png" -->
                <source
                  src="https://streamipcf.akamaized.net/live2/_definst_/fm963/playlist.m3u8"
                  type="application/x-mpegURL"
                />
                <!-- <source src="@/assets/video/adv.mp4" type="video/mp4" />
                  <source
                    src="@/assets/video/adv.mp4"
                    type="video/webm"
                  /> -->
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that<a
                    href="https://videojs.com/html5-video-support/"
                    target="_blank"
                    >supports HTML5 video</a
                  >
                </p>
              </audio>
            </div>
            <img
              src="@/assets/img/bg_radio.png"
              alt=""
              class="ani-radio"
              id="aniRadio"
            />
            <!--
              :style="{ animationPlayState : aniRadioStatus ? 'running' : 'paused'}" 
              :class="aniRadioStatus ? 'ani-radio-paused' : 'ani-radio-play'" 
              :class="{'ani-radio-play': aniRadioStatus }" //物件寫法
            -->
            <div class="radio-play-btn-box">
              <div
                class="radio-play-open"
                id="radioPlayOpen"
                style="display: none"
              >
                <img src="@/assets/img/radio_play.svg" alt="" />
              </div>
              <!-- <div class="radio-play-btn radio-play01">
                <img src="@/assets/img/radio_heart.svg" alt="" />
              </div>
              <div class="radio-play-btn radio-play02">
                <img src="@/assets/img/radio_play.svg" alt="" />
              </div> -->
              <div
                class="radio-play-btn radio-play03"
                id="videoOpen"
                @click="openVideo"
                style="display: none"
              >
                <img src="@/assets/img/radio_tv.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <RadioCudeBoxSwArea :list="radioCudeList" />
        <!-- <div class="radio-cude-sw-area">
          <div class="swiper-container radio-cude-sw" id="radioCudeSw">
            <div class="swiper-wrapper">
              <RadioCudeBoxSw
                v-for="(item, index) in radioCudeList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :host="item.host"
                :time="item.time"
              />
            </div>
          </div>
        </div> -->
        <SwNews :newsList="newsList" v-if="newsList.length" />
        <TitBlurArea
          :bg="titBlurData.img"
          :tit="titBlurData.tit"
          :sup="titBlurData.sup"
          :linkName="titBlurData.link"
          btnName="前往觀看"
        />
        <!-- 舊： <TitBlurArea
          bg="bg_blur03.jpg"
          tit="Program"
          sup="節目"
          linkName="Program"
          btnName="節目"
        /> -->
        <HostSwList
          :hostPerList="hostPerList"
          :pageIndex="hostPage"
          :pageTotal="hostPageTotal"
        />
        <!-- 有分類的廣播節目 -->
        <div class="live-type-area">
          <TypeTabSw
            swId="radioTypeTab"
            tabType="radio"
            :tabList.sync="radioType"
            v-if="radioType.length != 0"
          />
          <LiveVideoSwArea
            swType="radio"
            swId="liveTypeVideo"
            :swList.sync="RadioVideoList"
            v-if="RadioVideoList.length"
          />
          <!-- <div class="live-type-video-wrap">
            <div class="swiper-container live-type-video" id="radioTypeVideo">
              <div class="swiper-wrapper">
                <SwLiveVideo
                  v-for="(item, index) in RadioVideoList"
                  :key="index"
                  :img="item.img"
                  :tit="item.tit"
                  :cont="item.cont"
                  :thumbNum="item.thumbNum"
                  :videoUrl="item.videoUrl"
                />
              </div>
            </div>
          </div> -->
        </div>

        <!-- <div class="radio-cube-area">
          <RadioCudeBox
            v-for="(item, index) in radioCudeList2"
            :key="index"
            :img="item.img"
            :tit="item.tit"
            :host="item.host"
            :time="item.time"
          />
        </div> -->
        <AdmArea admType="Alian廣播" />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
import SwNews from "@/components/SwNews.vue";
import RadioCudeBoxSwArea from "@/components/RadioCudeBoxSwArea.vue";
// import RadioCudeBox from "@/components/RadioCudeBox.vue";
import TitBlurArea from "@/components/TitBlurArea.vue";
import HostSwList from "@/components/HostSwList.vue";
import TypeTabSw from "@/components/TypeTabSw.vue";
import LiveVideoSwArea from "@/components/LiveVideoSwArea.vue";
// import SwLiveVideo from "@/components/SwLiveVideo.vue";
import AdmArea from "@/components/AdmArea.vue";

import RadioLightBox from "@/components/RadioLightBox.vue";

import {
  apiGetProgramTimeList,
  apiGetCategorys,
  apiGetProgramList,
  apiGetRadioDetail,
  apiGetHostList,
  apiGetNewsList,
  apiGetNews,
  apiBannerMiddle,
} from "@/api/api.js";

export default {
  data() {
    return {
      //=== 中幅廣告 ===
      titBlurData: {
        img: "",
        tit: "",
        sup: "",
        link: "",
      },
      //=== radio 時刻 ===
      nowTime: "",
      radioTimeData: {
        index: 0,
        ProgramType: 1, //0=原視節目, 1=廣播
        AirDate: "",
      },
      radioTimeList: [],
      radioTime: {
        now: " - ", //Kamaro_an!輕輕聽
        next: " - ", //Tupa我們的麥克風
        host: " - ",
        cont: " - ",
      },
      radioLiveArea: "",
      //=== 廣播方塊 ===
      radioCudeList: [
        // {
        //   img: "radio01.png",
        //   tit: "早安!Alian",
        //   host: "Tjuku、Abas",
        //   time: "每週一至週五7:00～8:00",
        // },
        // {
        //   img: "radio02.png",
        //   tit: "太平洋的風",
        //   host: "tjuku 朱連珠、Cudjuy 朱正勇",
        //   time: "每週一至週五14:00～15:00",
        // },
        // {
        //   img: "radio03.png",
        //   tit: "Sawali Music Style",
        //   host: "馬耀．喇外",
        //   time: "週六日 23:00-24:00",
        // },
        // {
        //   img: "radio04.png",
        //   tit: "原來是maiducu",
        //   host: "卡樂斯．葛斯、Cudjuy 朱正勇",
        //   time: "每週六、日 16:00-17:00",
        // },
      ],
      //=== 新聞消息 ===
      newsListData: {
        MenuTypeID: "21050416184989967", //固定由 /ArticleNews/GetArticleNewsMenuType 取得 { "ID": 21050416184989967,"Title": "主頁"},
        index: 0,
        CategoryID: [],
        Keyword: "",
        IsAll: false,
      },
      newsList: [
        // { link: "###", tit: "桃源勤和-復興便道開放通行 一早車輛大排長龍" },
        // { link: "###", tit: "聚會所啟用.頒原保地所有權狀 福原熱鬧慶祝" },
        // { link: "###", tit: "56廣播金鐘獎入圍名單揭曉 Alian獲8項提名" },
        // { link: "###", tit: "睽違已久再現本土 0 指揮中心:把握清零機會" },
        // { link: "###", tit: "'minngayan醫猶未盡' 張維倫攝影展新竹登場" },
        // { link: "###", tit: "參與運動競賽曾受阻 展覽陳列女性發聲過程" },
        // { link: "###", tit: "台東大溪基礎設施未完善 通過發振興禮金引質疑" },
      ],
      //=== 主持人列表 ===
      hostListData: {
        index: 0,
        Keyword: "",
        IsAll: false,
        Parts: ["Alian廣播"], // 原視節目/Alian廣播/原視新聞/原視新聞族語
      },
      hostRandomPage: "",
      hostPage: "",
      hostPageTotal: "",
      hostPageList: [],
      hostPerList: [
        // { img: "r_host_01.jpg", name: "Xuzi 曾玉蘭" },
        // { img: "r_host_02.png", name: "寶杜．巴燕" },
        // { img: "r_host_03.jpg", name: "李若櫻" },
        // { img: "r_host_04.jpg", name: "Abas 潘玫伶" },
        // { img: "r_host_05.jpg", name: "Nick 杜冠頎" },
        // { img: "r_host_06.jpg", name: "甘克帆・巴宴爾" },
        // { img: "r_host_07.jpg", name: "toway 朱瑞" },
        // { img: "r_host_08.jpg", name: "Tjuku" },
      ],
      //=== 廣播分類 ===
      radioTypeData: {
        index: 0,
        ProgramType: 1, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
      },
      radioType: [
        // { ID: 1, txt: "全部", isAct: true },
        // { ID: 2, txt: "大眾娛樂類", isAct: false },
        // { ID: 3, txt: "公共服務類", isAct: false },
        // { id: 4, txt: "教育文化類", isAct: false },
        // { id: 5, txt: "產學合作類", isAct: false },
        // { id: 6, txt: "新聞資訊類", isAct: false },
      ],
      // === 廣播列表 ===
      getRadioListData: {
        index: 0,
        ProgramType: 1, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      RadioVideoList: [
        {
          img: "radio_01.jpg",
          tit: "哈勞的音樂小酒館",
          cont: "主持人：李明德 | 播出時間：每週一至週五 20:00~21:00",
          // thumbNum: "10",
          // videoUrl: "https://youtu.be/fMd1B-lgbv8",
        },
        {
          img: "radio_default.png",
          tit: "音樂不夜城",
          cont: "主持人：Alian愛音樂 | 播出時間：每週一至週五 00:00~02:00",
          // thumbNum: "10",
          // videoUrl: "",
        },
        {
          img: "radio_03.jpg",
          tit: "阿塔里歐音樂廳",
          cont: "主持人：Nick 阿塔溜 | 播出時間：每週六、週日 19:00~20:00",
          // thumbNum: "10",
          // videoUrl: "",
        },
        {
          img: "radio_default.png",
          tit: "Kamaro'an!輕輕聽",
          cont: "主持人：寶杜･巴燕 | 播出時間：22:00~23:00",
          // thumbNum: "10",
          // videoUrl: "https://youtu.be/xecLoyoDlHs",
        },
        // {
        //   img: "radio_default.png",
        //   tit: "senai na kacalisian",
        //   cont: "主持人：muakay | 播出時間：18:00-19:00",
        //   thumbNum: "10",
        //   videoUrl: "fb",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "哈娜麥莎微醺時刻",
        //   cont: "主持人：Suyang蘇婭 | 播出時間：",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "Alian原創力",
        //   cont: "主持人：尹儷 | 播出時間：週一至週五 P.M.23-24",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "Sawali Music Style",
        //   cont: "主持人：馬耀˙喇外 | 播出時間：週六日 23:00-24:00",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "聽見心在唱歌",
        //   cont: "主持人：發法·頂戈嚕倫 | 播出時間：00:00-01:00",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "1201讓我陪您",
        //   cont: "主持人：Umus高瑞蓮播出時間：每週一 00:00~01:00",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
      ],
      //=== 中間radio方塊 ===
      radioCudeList2: [
        {
          img: "radio01.png",
          tit: "豐音樂1",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio02.png",
          tit: "豐音樂2",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio03.png",
          tit: "豐音樂3",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio04.png",
          tit: "豐音樂4",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio_c04.png",
          tit: "豐音樂5",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio_c05.png",
          tit: "豐音樂6",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio_c06.png",
          tit: "豐音樂7",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "radio_c07.png",
          tit: "豐音樂8",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
        {
          img: "cube05.png",
          tit: "豐音樂9",
          host: "寶杜･巴燕",
          time: "22:00~23:00",
        },
      ],
      aniRadioStatus: true, //true 是 play, false 是 pause
    };
  },
  mounted() {
    //=== 取得節目撥出時間列表 ===
    var newDate = new Date();
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var date = newDate.getDate();
    var hours = newDate.getHours();
    var min = newDate.getMinutes();
    var sec = newDate.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    var today = year + "/" + month + "/" + date;
    var nowTimeTxt =
      year + "/" + month + "/" + date + " " + hours + ":" + min + ":" + sec;
    this.nowTime = nowTimeTxt;
    this.radioTimeData.AirDate = today;
    this.getProgramTimeList(this.radioTimeData);
    //=== 取得所有廣播列表 ===
    this.getProgramList(this.getRadioListData, true);
    //=== 取得主持人列表 ===
    this.getHostPageList();
    // this.getHostList();
    //=== 廣播 type tap 取得 ===
    this.getCategorys(this.radioTypeData);
    //=== News最新消息 ===
    // this.getNewsList();
    this.getNews();
    //=== 中幅廣告取得 ===
    this.getBannerMiddle();
    //=== 暫停RadioLive ===
    this.$bus.$on("setRadioPause", (data) => {
      console.log(data);
      this.radioLiveArea.pause();
    });
    //=== 取得類別節目列表 ===
    this.$bus.$on("toProgramTypeId", (data) => {
      console.log("點擊tab", data.type);
      console.log("點擊接收ID", data.typeId);

      // this.getProgramListData.CategoryID = [data];
      // //=== 取得節目列表 ===
      this.getRadioListData.CategoryID = [data.typeId]; //reList[0].ID //產學合作
      this.getProgramList(this.getRadioListData, false);
      // this.getProgramList(this.getRadioListData, false);
    });
    //=== 主持人列表頁數 ===
    this.$bus.$on("hostPageData", (data) => {
      // console.log("接收主持人列表頁數", data);
      // this.hostListData.index = data;
      console.log("== 多加列表 ==");
      console.log(this.hostPageList[data]);
      this.hostListData.index = this.hostPageList[data];
      this.getMoreHostList();
    });

    let aniRadioObj = document.querySelector("#aniRadio");
    //=== 最上方live ===
    var radioLive = window.videojs(
      "LiveRadio",
      {
        controls: true,
        autoplay: true,
        preload: "auto",
        poster: require("@/assets/img/video_default.png"),
      },
      function () {
        this.on("play", function () {
          console.log("playing");
          // this.aniRadioStatus = true;
          // this.$("#aniRadio").addClass("ani-radio-play");
          aniRadioObj.classList.remove("ani-radio-paused");
          aniRadioObj.classList.add("ani-radio-play");

          // ele.style.animationPlayState === 'running';
        });
        this.on("pause", function () {
          console.log("pause");
          // this.aniRadioStatus = false;
          // this.$("#aniRadio").addClass("ani-radio-paused");
          aniRadioObj.classList.remove("ani-radio-play");
          aniRadioObj.classList.add("ani-radio-paused");
          // ele.style.animationPlayState === 'paused';
        });
      }
    );
    this.radioLiveArea = radioLive;
    this.radioLiveArea.on("play", () => {
      var isPause = true;
      //== 關閉目前播放的Radio ==
      this.$bus.$emit("swOpenRadioClose", isPause);
    });
    // this.radioLiveArea.autoplay();

    //== swiper ==
    //== radioCudeSw ==
    // var radioCudeSw = new Swiper("#radioCudeSw", {
    //   slidesPerView: "auto",
    //   spaceBetween: 15,
    //   //- centeredSlides: true,
    //   freeMode: true,
    // });
    //== swiperMarquee ==
    // var swiperMarquee = new Swiper("#swiperMarquee", {
    //   direction: "vertical",
    //   spaceBetween: 0,
    //   //- freeMode: true,
    //   noSwipingClass: "news-marquee-box",
    //   loop: true,
    //   speed: 500,
    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false,
    //   },
    // });
    //== hostSw ==
    // var hostSw = new Swiper("#hostSw", {
    //   slidesPerView: "auto",
    //   spaceBetween: 15,
    //   freeMode: true,
    // });
    //== 廣播分類TypeTab ==
    // var radioTypeTab = new Swiper("#radioTypeTab", {
    //   slidesPerView: "auto",
    //   spaceBetween: 25,
    //   freeMode: true,
    // });

    //== 廣播分類Video ==
    // var radioTypeVideo = new Swiper("#radioTypeVideo", {
    //   slidesPerView: "1",
    //   spaceBetween: 25,
    //   // freeMode: true,
    // });

    //=== admSwiper ===
    // var admSwiper = new Swiper("#admSwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
  },
  methods: {
    //=== 取得節目撥出時間列表 ===
    getProgramTimeList(data) {
      apiGetProgramTimeList(data)
        .then((result) => {
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log("時刻表");
            console.log(resData);
            this.$.each(resData, (ind, val) => {
              var { PlayEndTime } = val;
              // console.log(PlayEndTime);
              var PlayEndTimeChange = PlayEndTime.split(" ");
              var PlayEndTimeChangeData = PlayEndTimeChange[0]
                .split("-")
                .join("/");
              var PlayEndTimeChangeTxt =
                PlayEndTimeChangeData + " " + PlayEndTimeChange[1];
              var endTime = new Date(PlayEndTimeChangeTxt);
              // var endTime = new Date(PlayEndTime);
              var endTimeStr = endTime.getTime();
              // console.log(endTime.getTime());
              var newNowTime = new Date(this.nowTime);
              var NowTimeStr = newNowTime.getTime();
              if (endTimeStr > NowTimeStr) {
                this.radioTimeList.push(val);
              }
            });

            //= 如果剩下的第一筆開始時間沒有在目前時間內 =
            var PlayStartTimeChange =
              this.radioTimeList[0].PlayStartTime.split(" ");
            var PlayStartTimeChangeData = PlayStartTimeChange[0]
              .split("-")
              .join("/");
            var PlayStartTimeChangeTxt =
              PlayStartTimeChangeData + " " + PlayStartTimeChange[1];
            var stratTime = new Date(PlayStartTimeChangeTxt);
            var startTimeStr = stratTime.getTime();
            var newNowTime = new Date(this.nowTime);
            var NowTimeStr = newNowTime.getTime();
            if (Number(startTimeStr) > Number(NowTimeStr)) {
              this.radioTimeList.unshift({ Title: "目前無節目" });
            }

            //= 填字 =
            if (this.radioTimeList.length == 0) {
              this.radioTime.now = "-";
              this.radioTime.next = "-";
            } else {
              this.radioTime.now = this.radioTimeList[0].Title;
              this.radioTime.host = this.radioTimeList[0].Host;
              this.radioTime.cont = this.radioTimeList[0].Introduction;
            }
            this.radioTimeList.length == 1
              ? (this.radioTime.next = "-")
              : (this.radioTime.next = this.radioTimeList[1].Title);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目類別 ===
    getCategorys(data) {
      apiGetCategorys(data)
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            var reList = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title } = val;
              ind == 0
                ? reList.push({ ID: ID.toString(), Title: Title, isAct: true })
                : reList.push({
                    ID: ID.toString(),
                    Title: Title,
                    isAct: false,
                  });
            });
            this.radioType = reList;
            //=== 第一次取得 類別 廣播列表 ===
            this.getRadioListData.CategoryID.push(reList[0].ID.toString()); //reList[0].ID //產學合作
            this.getProgramList(this.getRadioListData, false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目列表ＡＰＩ ===
    getProgramList(apiData, isAll) {
      apiGetProgramList(apiData)
        .then((result) => {
          console.log("廣播節目列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            if (isAll) {
              console.log("取的全部的");
              var dataListAll = [];
              this.$.each(resData, (ind, val) => {
                var { ID, Img, Title } = val;
                dataListAll.push({
                  videoId: ID.toString(),
                  img: Img,
                  tit: Title,
                  host: "",
                  time: "",
                });
              });
              this.radioCudeList = dataListAll;
              //=== 節目詳細資料 ===
              // console.log("21082705564821012");
              this.$.each(this.radioCudeList, (ind, val) => {
                // console.log(val.videoId);
                this.getRadioDetail(val.videoId, true); //原來這麼厲害
              });
              // this.getRadioDetail("21082705564821012", true); //原來這麼厲害
            } else {
              //== 有分類 ==
              var dataList = [];
              this.$.each(resData, (ind, val) => {
                var { ID, Img, Title } = val;
                dataList.push({
                  videoId: ID.toString(),
                  img: Img,
                  tit: Title,
                  cont: "",
                });
              });
              this.RadioVideoList = dataList;
              //=== 取得節目詳細資料 ===
              this.$.each(this.RadioVideoList, (ind, val) => {
                // console.log(val.videoId);
                this.getRadioDetail(val.videoId, false);
              });
              // this.getRadioDetail("21082515011733126", false); //Vuvu樂起來
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 節目詳細資料 ===
    getRadioDetail(id, isAll) {
      apiGetRadioDetail({ ID: id })
        .then((result) => {
          console.log("廣播詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            console.log("radioDetail");
            var { Host, PlayDuration } = resData.Program;
            if (isAll) {
              var indAll = this.radioCudeList.findIndex(function (item) {
                return item.videoId == id; // id 原來這麼厲害
              });
              console.log(Host);
              this.radioCudeList[indAll].host = Host; //Host 測試主持人
              this.radioCudeList[indAll].time = PlayDuration; //PlayDuration 每週日 13:00 ~ 14:00
              // var { Host, PlayDuration } = resData.Program;
            } else {
              // var { PlayDuration } = resData.Program;
              // console.log(PlayDuration);
              var ind = this.RadioVideoList.findIndex(function (item) {
                return item.videoId == id; // id
              });
              this.RadioVideoList[ind].cont = PlayDuration;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 主持人列表 ===
    getHostPageList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人列表頁數處理");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var { Page, TotalPage } = result.data;
            console.log(result.data);
            //== 隨機初始頁碼 ==
            //因為如果只有一筆的話，random 會是0 但沒關係，因為給 0 api 會回第一頁
            //最後一筆因為不會 random 到，所以可以判斷 loading 到最後一筆時不要在call api
            var maxPage = TotalPage; //TotalPage + 1 先不加一 因為最後一頁可能只有一筆會跑不了
            console.log(maxPage);
            this.hostRandomPage = Math.floor(Math.random() * maxPage);
            //== 如果為 0 雖然會是第一頁 但是進到讀取列表內 會無法判斷到 0 第一頁不會排除掉 ==
            if (this.hostRandomPage == 0) {
              this.hostRandomPage = 1;
            }
            //== 放入所有頁碼 ==
            for (var i = 0; i < TotalPage; i++) {
              var page = i + 1;
              if (this.hostRandomPage != page) {
                this.hostPageList.push(page);
              }
            }
            this.hostPage = 0;
            this.hostPageTotal = this.hostPageList.length;
            //== 呼叫主持人隨機頁列表 ==
            this.hostListData.index = this.hostRandomPage;
            this.getHostList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHostList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            // var { Page, TotalPage } = result.data;
            // console.log(Page, TotalPage);
            // this.hostPage = Page;
            // this.hostPageTotal = TotalPage;
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Name, Icon } = val;
              list.push({
                hostId: ID.toString(),
                img: Icon.ImgUrl,
                name: Name,
              });
            });
            this.hostPerList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMoreHostList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          // console.log("主持人後續列表");
          // console.log(JSON.parse(result.data));
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            // var { Page, TotalPage } = result.data;
            // // console.log(Page, TotalPage);
            // this.hostPage = Page;
            // this.hostPageTotal = TotalPage;
            this.hostPage = this.hostPage + 1;
            var resData = result.data.RetData;
            // console.log(resData);
            this.$.each(resData, (ind, val) => {
              var { ID, Name, Icon } = val;
              this.hostPerList.push({
                hostId: ID.toString(),
                img: Icon.ImgUrl,
                name: Name,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== News最新消息 ===
    getNewsList() {
      apiGetNewsList(this.newsListData)
        .then((result) => {
          console.log("News列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title } = val;
              list.push({
                newsId: ID.toString(),
                tit: Title,
                link: "###",
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNews() {
      apiGetNews({
        params: {
          part: 3,
        },
      })
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Content, Link } = val;
              list.push({
                newsId: ID.toString(),
                tit: Content,
                link: Link,
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 中幅廣告 ===
    getBannerMiddle() {
      apiBannerMiddle()
        .then((result) => {
          console.log("=== 中幅廣告 ===");
          // console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            console.log(resData);
            //category -> Live,廣播,新聞
            this.$.each(resData, (ind, val) => {
              var { category, img_url, title, subtitle, link } = val;
              if (category == "廣播") {
                this.titBlurData.img = img_url;
                this.titBlurData.tit = title;
                this.titBlurData.sup = subtitle;
                this.titBlurData.link = link;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 點選 ===
    //== 傳送項目 ==
    openVideo() {
      this.$bus.$emit("swOpenVideo", "https://youtu.be/9N94EJ_g5-Q");
    },
  },
  beforeDestroy() {
    //=== 離開時要取消videojs 再次進入才不會因為重複宣告設定出問題 ===
    window.videojs("LiveRadio").dispose();
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("setRadioPause");
    this.$bus.$off("toProgramTypeId");
    this.$bus.$off("hostPageData");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    SwNews,
    RadioCudeBoxSwArea,
    // RadioCudeBox,
    TitBlurArea,
    HostSwList,
    TypeTabSw,
    LiveVideoSwArea,
    // SwLiveVideo,
    AdmArea,
    RadioLightBox,
  },
};
</script>
<style scoped>
.live-audio-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
.audio-box {
  height: 0;
  padding-bottom: 0;
  background: #f00;
}

.audio-box .vjs-poster {
  background: #f00;
}
.audio-box .video-js .vjs-big-play-button {
  height: 50px;
  width: 50px;
  background-image: url("/assets/img/radio_play.svg");
}
.audio-box .video-js .vjs-big-play-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/assets/img/radio_play.svg");
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50% calc(50% - 10px);
  border: none !important;
  box-shadow: none !important;
}
.audio-box .video-js .vjs-big-play-button:before {
  content: "";
  display: none;
}
.audio-box .vjs-big-play-button:hover {
  background-color: transparent;
  opacity: 0.7;
}
.audio-box .vjs-big-play-button span {
  display: none;
}
</style>