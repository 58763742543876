<template>
  <div>
    <NavTop />
    <NavProBar
      :isLiveShow.sync="isLiveShow"
      :liveAllTimeList="liveAllTimeList"
      :radioAllTimeList="radioAllTimeList"
    />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <!-- 節目 -->
      <div v-if="isLiveShow">
        <div class="no-data no-data-timelist" v-if="liveAllTimeList == 0">
          Loading...
        </div>
        <!-- liveTimeList -->
        <OnairBox
          :playSwName="'programPlaySw'"
          :playList="liveAllTimeList"
          :isTitv="true"
          :isAlian="false"
          :swTo="liveSwTo"
        />
        <!-- <OnairBox
          v-for="(item, index) in liveTimeList"
          :key="'program' + index"
          :swAreaBg="item.Img"
          :isOnair="item.isOnair"
          :title="item.Title"
          :startTime="item.PlayStartTime"
          :endTime="item.PlayEndTime"
          :isTitv="true"
          :isAlian="false"
        /> -->
      </div>
      <!-- 廣播 -->
      <div v-if="!isLiveShow">
        <div class="no-data no-data-timelist" v-if="radioAllTimeList == 0">
          Loading...
        </div>
        <!-- radioTimeList -->
        <OnairBox
          :playSwName="'radioPlaySw'"
          :playList="radioAllTimeList"
          :isTitv="false"
          :isAlian="true"
          :swTo="radioSwTo"
        />
        <!-- <OnairBox
          v-for="(item, index) in radioTimeList"
          :key="'radio' + index"
          :swAreaBg="item.Img"
          :title="item.Title"
          :isOnair="item.isOnair"
          :startTime="item.PlayStartTime"
          :endTime="item.PlayEndTime"
          :isTitv="false"
          :isAlian="true"
        /> -->
      </div>
      <!-- <OnairBox
        v-for="(item, index) in OnairList"
        :key="'tt' + index"
        :swAreaBg="item.swAreaBg"
        :isTitv="item.isTitv"
        :isAlian="item.isAlian"
      /> -->
    </div>
  </div>
</template>
<script>
import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavProBar from "@/components/NavProBar.vue";

import OnairBox from "@/components/OnairBox.vue";

import { apiGetProgramTimeList } from "@/api/api.js";

export default {
  data() {
    return {
      //=== radio 時刻 ===
      nowTime: "",
      isLiveShow: true,
      //== live 節目 ==
      liveTimeData: {
        //radioTimeData
        index: 0,
        ProgramType: 0, //0=原視節目, 1=廣播
        AirDate: "",
      },
      liveSwTo: 0,
      liveAllTimeList: [],
      liveTimeList: [],
      //== radio 廣播 ==
      radioTimeData: {
        index: 0,
        ProgramType: 1, //0=原視節目, 1=廣播
        AirDate: "",
      },
      radioSwTo: 0,
      radioAllTimeList: [],
      radioTimeList: [],
      //=== 主題圖列表 ===
      OnairList: [
        { swAreaBg: "sw-area1", isTitv: true, isAlian: false },
        { swAreaBg: "sw-area2", isTitv: false, isAlian: true },
        { swAreaBg: "sw-area3", isTitv: true, isAlian: false },
        { swAreaBg: "sw-area4", isTitv: false, isAlian: true },
      ],
    };
  },
  mounted() {
    //=== 取得節目撥出時間列表 ===
    var newDate = new Date();
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var date = newDate.getDate();
    var hours = newDate.getHours();
    var min = newDate.getMinutes();
    var sec = newDate.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    var today = year + "/" + month + "/" + date;
    var nowTimeTxt =
      year + "/" + month + "/" + date + " " + hours + ":" + min + ":" + sec;
    this.nowTime = nowTimeTxt;
    this.liveTimeData.AirDate = today;
    this.radioTimeData.AirDate = today;
    //== 節目 ==
    this.getProgramTimeList(this.liveTimeData, true);
    //== 廣播 ==
    this.getProgramTimeList(this.radioTimeData, false);
  },
  methods: {
    //=== 取得節目撥出時間列表 ===
    getProgramTimeList(data, isLive) {
      apiGetProgramTimeList(data)
        .then((result) => {
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log("時刻表");
            console.log(resData);
            if (isLive) {
              console.log("是節目");
              this.$.each(resData, (ind, val) => {
                var {
                  ID,
                  PlayStartTime,
                  PlayEndTime,
                  Title,
                  Img,
                  StraightImg,
                } = val;
                var strImg;
                StraightImg != null ? (strImg = StraightImg) : (strImg = Img);
                this.liveAllTimeList.push({
                  id: ID.toString(),
                  PlayStartTime,
                  PlayEndTime,
                  Title,
                  Img,
                  strImg: strImg,
                  isOnair: false,
                  isTop: false,
                });
                //== 多加一個項目 ==
                // this.liveAllTimeList[ind].isOnair = false;
                //== 圖列項目 ==
                var PlayEndTimeChange = PlayEndTime.split(" ");
                var PlayEndTimeChangeData = PlayEndTimeChange[0]
                  .split("-")
                  .join("/");
                var PlayEndTimeChangeTxt =
                  PlayEndTimeChangeData + " " + PlayEndTimeChange[1];
                var endTime = new Date(PlayEndTimeChangeTxt);
                // var endTime = new Date(PlayEndTime);
                var endTimeStr = endTime.getTime();
                var newNowTime = new Date(this.nowTime);
                var NowTimeStr = newNowTime.getTime();
                if (Number(endTimeStr) > Number(NowTimeStr)) {
                  this.liveTimeList.push({
                    id: ID.toString(),
                    PlayStartTime,
                    PlayEndTime,
                    Title,
                    Img: strImg,
                    isOnair: false,
                  });
                }
              });
              //== 給目前onair狀態 ==
              //= 圖文列表 = = 還未播放的列表，因為要挑第一個目前正在播放的節目 =
              if (this.liveTimeList.length != 0) {
                this.liveTimeList[0].isOnair = true;
                //= 文字列表 尋找剩下節目的第一筆 =
                var indOnair = this.liveAllTimeList.findIndex((item) => {
                  return (
                    item.PlayStartTime == this.liveTimeList[0].PlayStartTime
                  ); // 0
                });

                //= 如果剩下的第一筆開始時間沒有在目前時間內 =
                var PlayStartTimeChange =
                  this.liveTimeList[0].PlayStartTime.split(" ");
                var PlayStartTimeChangeData = PlayStartTimeChange[0]
                  .split("-")
                  .join("/");
                var PlayStartTimeChangeTxt =
                  PlayStartTimeChangeData + " " + PlayStartTimeChange[1];
                var stratTime = new Date(PlayStartTimeChangeTxt);
                var startTimeStr = stratTime.getTime();
                var newNowTime = new Date(this.nowTime);
                var NowTimeStr = newNowTime.getTime();
                console.log(
                  "開始時間",
                  Number(startTimeStr),
                  "現在時間",
                  Number(NowTimeStr)
                );
                //== 順序筆數 ==
                if (indOnair != -1) {
                  //== 補上無資料 ==
                  if (Number(startTimeStr) > Number(NowTimeStr)) {
                    console.log("不是現在時間");
                    var noOnTimeData = {
                      Img: require("@/assets/img/default_program_hor.jpg"),
                      strImg: require("@/assets/img/default_program.jpg"),
                      Title: "資料載入中",
                      PlayStartTime: "",
                      isTop: true,
                    };
                    this.liveAllTimeList.splice(indOnair, 0, noOnTimeData);
                    // this.liveAllTimeList[indOnair].isOnair = true;
                    this.liveSwTo = indOnair;
                  } else {
                    console.log("是目前時間");
                    this.liveAllTimeList[indOnair].isOnair = true;
                    this.liveAllTimeList[indOnair].isTop = true;
                    //=== 滑動到目前節目筆數 ===
                    this.liveSwTo = indOnair;
                  }
                }

                //== 當前放置第一筆 ==
                // if (Number(startTimeStr) > Number(NowTimeStr)) {
                //   console.log("不是現在時間");
                //   var noOnTimeData = {
                //     Img: require("@/assets/img/default_program_hor.jpg"),
                //     strImg: require("@/assets/img/default_program.jpg"),
                //     Title: "資料載入中",
                //     PlayStartTime: "",
                //   };
                //   this.liveAllTimeList.unshift(noOnTimeData);
                // } else {
                //   console.log("是目前時間");
                //   if (indOnair != -1) {
                //     this.liveAllTimeList[indOnair].isOnair = true;
                //     //=== onair移到第一筆 ===
                //     var liveOnair = this.liveAllTimeList.filter((item) => {
                //       return (
                //         item.PlayStartTime == this.liveTimeList[0].PlayStartTime
                //       );
                //     });
                //     //== 移除後加到第一個 ==
                //     this.liveAllTimeList.splice(indOnair, 1);
                //     this.liveAllTimeList.unshift(liveOnair[0]);
                //   }
                // }
              }
            } else {
              console.log("是廣播");
              this.$.each(resData, (ind, val) => {
                var {
                  ID,
                  PlayStartTime,
                  PlayEndTime,
                  Title,
                  Img,
                  StraightImg,
                } = val;
                var strImg;
                StraightImg != null ? (strImg = StraightImg) : (strImg = Img);
                this.radioAllTimeList.push({
                  id: ID.toString(),
                  PlayStartTime,
                  PlayEndTime,
                  Title,
                  Img,
                  strImg: strImg,
                  isOnair: false,
                  isTop: false,
                });
                //== 多加一個項目 ==
                // this.radioAllTimeList[ind].isOnair = false;
                //== 圖列項目 ==
                var PlayEndTimeChange = PlayEndTime.split(" ");
                var PlayEndTimeChangeData = PlayEndTimeChange[0]
                  .split("-")
                  .join("/");
                var PlayEndTimeChangeTxt =
                  PlayEndTimeChangeData + " " + PlayEndTimeChange[1];
                var endTime = new Date(PlayEndTimeChangeTxt);
                // var endTime = new Date(PlayEndTime);
                var endTimeStr = endTime.getTime();
                var newNowTime = new Date(this.nowTime);
                var NowTimeStr = newNowTime.getTime();
                if (Number(endTimeStr) > Number(NowTimeStr)) {
                  this.radioTimeList.push({
                    id: ID.toString(),
                    PlayStartTime,
                    PlayEndTime,
                    Title,
                    Img: strImg,
                    isOnair: false,
                  });
                }
              });
              //== 給目前onair狀態 ==
              if (this.radioTimeList.length != 0) {
                //= 圖文列表 =
                this.radioTimeList[0].isOnair = true;
                //= 文字列表 尋找剩下節目的第一筆 =
                var indRaidoOnair = this.radioAllTimeList.findIndex((item) => {
                  return (
                    item.PlayStartTime == this.radioTimeList[0].PlayStartTime
                  );
                });

                //= 如果剩下的第一筆開始時間沒有在目前時間內 =
                var PlayStartTimeChangeR =
                  this.radioTimeList[0].PlayStartTime.split(" ");
                var PlayStartTimeChangeDataR = PlayStartTimeChangeR[0]
                  .split("-")
                  .join("/");
                var PlayStartTimeChangeTxtR =
                  PlayStartTimeChangeDataR + " " + PlayStartTimeChangeR[1];
                var stratTimeR = new Date(PlayStartTimeChangeTxtR);
                var startTimeStrR = stratTimeR.getTime();
                var newNowTimeR = new Date(this.nowTime);
                var NowTimeStrR = newNowTimeR.getTime();
                //== 順序筆數 ==
                if (indRaidoOnair != -1) {
                  //== 補上無資料 ==
                  if (Number(startTimeStrR) > Number(NowTimeStrR)) {
                    console.log("不是現在時間");
                    var noOnTimeDataR = {
                      Img: require("@/assets/img/default_program_hor.jpg"),
                      strImg: require("@/assets/img/default_program.jpg"),
                      Title: "資料載入中",
                      PlayStartTime: "",
                      isTop: true,
                    };
                    this.radioAllTimeList.splice(
                      indRaidoOnair,
                      0,
                      noOnTimeDataR
                    );
                    this.radioSwTo = indRaidoOnair;
                  } else {
                    console.log("是目前時間");
                    this.radioAllTimeList[indRaidoOnair].isOnair = true;
                    this.radioAllTimeList[indRaidoOnair].isTop = true;
                    //=== 滑動到目前節目筆數 ===
                    this.radioSwTo = indRaidoOnair;
                  }
                }

                //== 當前放置第一筆 ==
                // if (Number(startTimeStrR) > Number(NowTimeStrR)) {
                //   console.log("不是現在時間");
                //   var noOnTimeDataR = {
                //     Img: require("@/assets/img/default_program_hor.jpg"),
                //     strImg: require("@/assets/img/default_program.jpg"),
                //     Title: "資料載入中",
                //     PlayStartTime: "",
                //   };
                //   this.radioAllTimeList.unshift(noOnTimeDataR);
                // } else {
                //   console.log("是目前時間");
                //   if (indRaidoOnair != -1) {
                //     this.radioAllTimeList[indRaidoOnair].isOnair = true;
                //     //=== onair移到第一筆 ===
                //     var radioOnair = this.radioAllTimeList.filter((item) => {
                //       return (
                //         item.PlayStartTime ==
                //         this.radioTimeList[0].PlayStartTime
                //       );
                //     });
                //     //== 移除後加到第一個 ==
                //     this.radioAllTimeList.splice(indRaidoOnair, 1);
                //     this.radioAllTimeList.unshift(radioOnair[0]);
                //   }
                // }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    NavTop,
    NavSide,
    NavProBar,
    OnairBox,
  },
};
</script>