<template>
  <div class="swiper-slide">
    <div class="radio-cube-box">
      <div class="radio-cube-img" @click="openVideo(videoId)">
        <img :src="img" alt="" />
        <!-- <img :src="require('@/assets/img/' + img)" alt="" /> -->
      </div>
      <div class="radio-cube-cont">
        <div class="radio-cube-tit">
          <div class="tit">{{ tit }}</div>
          <div class="icon-box">
            <!-- <div class="icon">
              <img src="@/assets/img/icon_thumb_b.svg" alt="" />
            </div> -->
            <!-- <div class="icon">
              <img src="@/assets/img/icon_heart_r.svg" alt="" />
            </div> -->
          </div>
        </div>
        <div class="radio-cube-txt">
          <div>主持人：{{ host }}</div>
          <div>播出時間：<span v-html="time"></span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetProgramEpisodeList, apiGetRadioEpisode } from "@/api/api.js";

export default {
  name: "RadioCudeBoxSw",
  props: ["videoId", "img", "tit", "host", "time"],
  data() {
    return {
      videoUrl: "",
    };
  },
  methods: {
    openVideo(id) {
      console.log(id);
      //=== 取得第一個節目第一集的單集內容 ===
      if (this.videoUrl == "") {
        this.getProgramEpisodeList(id);
      } else {
        this.$bus.$emit("swOpenRadio", this.videoUrl);
        //== 暫停目前Live ==
        var isPause = true;
        this.$bus.$emit("setRadioPause", isPause);
      }
    },
    //== 取的節目集數列表 ==
    getProgramEpisodeList(id) {
      apiGetProgramEpisodeList({
        index: 0,
        ProgramType: 1,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("RadioCude節目級數列表");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            // 因為最新的很少廣播黨，保險先抓第五筆
            // var videoLink = resData[4].m3u8Url;
            // var videoTit = resData[4].Title

            var videoLink;
            var videoTit;
            //回圈跑有檔案才播
            for (var i = 0; i < resData.length; i++) {
              console.log("第" + i + "筆" + resData[i].m3u8Url);
              var videoLinkVal = resData[i].m3u8Url;
              if (videoLinkVal) {
                videoLink = resData[i].m3u8Url;
                videoTit = resData[i].Title;
                break;
              }
            }

            console.log("播放", videoTit);
            if (videoLink != null) {
              this.videoUrl = videoLink;
              this.$bus.$emit("swOpenRadio", videoLink);
              //== 暫停目前Live ==
              var isPause = true;
              this.$bus.$emit("setRadioPause", isPause);
            } else {
              alert("目前沒有廣播檔案！");
            }
            // var firstEpisodeId = resData[0].ID.toString();
            //=== 取得第一集資料 ===
            // this.getRadioEpisode(firstEpisodeId);
          } else if (resStatuse == true && resCode == 1) {
            console.log("沒有Radio節目級數列");
            alert("目前沒有Radio節目級數列");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得單集內容 ===
    // getRadioEpisode(id) {
    //   apiGetRadioEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("Radio集數節目詳細資料 取得！");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         // var RadioLink = result.data.RetData.Episode.Title;
    //         // console.log(RadioLink);
    //         //== 播放影片 ==
    //         var videoLink = result.data.RetData.m3u8_url;
    //         if (videoLink != null) {
    //           this.videoUrl = videoLink;
    //           this.$bus.$emit("swOpenRadio", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有影音檔案");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>