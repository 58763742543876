<template>
  <div class="swiper-slide">
    <div class="live-type-video-box">
      <div class="live-type-video-img video-play" @click="openVideo(videoId)">
        <img :src="img" alt="" />
        <!-- <img :src="require('@/assets/img/' + img)" alt="" /> -->
      </div>
      <div class="live-type-video-info">
        <div class="tit-box">
          <div class="tit">{{ tit }}</div>
          <div class="icon-box">
            <!-- <div class="num">{{ thumbNum }}</div> -->
            <!-- <div class="icon">
              <img src="@/assets/img/icon_thumb_b.svg" alt="" />
            </div> -->
            <!-- <div class="icon">
              <img src="@/assets/img/icon_heart_r.svg" alt="" />
            </div> -->
          </div>
        </div>
        <div class="cont scroll-w" v-html="cont"></div>
        <!-- <div class="cont">{{ cont }}</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  apiGetProgramEpisodeList,
  apiGetProgramEpisode,
  apiGetRadioEpisode,
} from "@/api/api.js";
export default {
  name: "SwLiveVideo",
  props: ["swType", "videoId", "img", "tit", "cont"], //thumbNum , videoUrl
  data() {
    return {
      videoUrl: "",
    };
  },
  mounted() {
    //=== scroll bar ===
    window.$(".scroll-w").mCustomScrollbar();
    this.$bus.$on("toCleanUrl", (data) => {
      if (data) {
        this.videoUrl = "";
      }
    });
  },
  methods: {
    //=== 點選 ===
    //== 傳送項目 ==
    openVideo(id) {
      //== 取的節目集數列表 才能拿到第一集 ==
      // if (this.swType == "program") {
      //   console.log(this.videoId);
      //   this.getProgramEpisode(this.videoId); //this.videoId 0-21082703080578337
      // } else if (this.swType == "radio") {
      //   this.getRadioEpisode(this.videoId);
      //   // console.log("廣播沒有資料！");
      // }
      if (this.videoUrl == "") {
        this.getProgramEpisodeList(id);
      } else {
        // this.$bus.$emit("swOpenVideo", this.videoUrl);
        var videoData = {
          videoLink: this.videoUrl,
          swType: this.swType,
        };
        if (this.swType == "program") {
          this.$bus.$emit("swOpenVideo", videoData);
          var isPause = true;
          //== 暫停目前Live ==
          this.$bus.$emit("setLivePause", isPause);
          //== 關閉目前播放的Radio ==
          this.$bus.$emit("swOpenRadioClose", isPause);
        } else if (this.swType == "radio") {
          this.$bus.$emit("swOpenRadio", this.videoUrl);
          var isPauseR = true;
          //== 暫停目前Live ==
          this.$bus.$emit("setLivePause", isPauseR);
          this.$bus.$emit("setRadioPause", isPauseR);
        }
      }

      // this.getProgramEpisode("21082515011733126"); 1 - 21082515011733126
      // this.$bus.$emit("swOpenVideo", this.videoUrl);
    },
    //== 取的節目集數列表 ==
    getProgramEpisodeList(id) {
      var type;
      if (this.swType == "program") {
        console.log("programVideo");
        type = 0;
      } else if (this.swType == "radio") {
        console.log("radioVideo");
        type = 1;
      }
      apiGetProgramEpisodeList({
        index: 0,
        ProgramType: type,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("live節目級數列表");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log("live節目級數列表", resData);

            var videoLink;
            //回圈跑有檔案才播
            for (var i = 0; i < resData.length; i++) {
              console.log("第" + i + "筆" + resData[i].m3u8Url);
              var videoLinkVal = resData[i].m3u8Url;
              if (videoLinkVal) {
                videoLink = resData[i].m3u8Url;
                break;
              }
            }
            
            // var videoLink = resData[0].m3u8Url;
            if (videoLink != null) {
              this.videoUrl = videoLink;
              //希波的： this.$bus.$emit("swOpenVideo", this.videoUrl);
              // **傳送type，判斷radio或是video
              var videoData = {
                videoLink: this.videoUrl,
                swType: this.swType,
              };
              if (this.swType == "program") {
                this.$bus.$emit("swOpenVideo", videoData);
                var isPause = true;
                //== 暫停目前Live ==
                this.$bus.$emit("setLivePause", isPause);
                //== 關閉目前播放的Radio ==
                this.$bus.$emit("swOpenRadioClose", isPause);
              } else if (this.swType == "radio") {
                this.$bus.$emit("swOpenRadio", this.videoUrl);
                var isPauseR = true;
                //== 暫停目前Live ==
                this.$bus.$emit("setLivePause", isPauseR);
                this.$bus.$emit("setRadioPause", isPauseR);
              }
              // if (this.swType == "program") {
              //   this.$bus.$emit("swOpenVideo", {
              //     videoLink: this.videoUrl,
              //     swType: "program",
              //   });
              // } else if (this.swType == "radio") {
              //   this.$bus.$emit("swOpenVideo", {
              //     videoLink: this.videoUrl,
              //     swType: "radio",
              //   });
              // }
            } else {
              if (this.swType == "program") {
                alert("目前沒有節目檔案！");
              } else if (this.swType == "radio") {
                alert("目前沒有廣播檔案！");
              }
            }
            // if (this.swType == "program") {
            //   this.getProgramEpisode(resData[0].ID.toString());
            // } else if (this.swType == "radio") {
            //   this.getRadioEpisode(resData[0].ID.toString());
            // }
          } else if (resStatuse == true && resCode == 1) {
            console.log("沒有廣播集數列表");
            alert("目前沒有廣播集數列表");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // //=== 取得節目單集內容 ===
    // getProgramEpisode(id) {
    //   apiGetProgramEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("取得'節目'單集內容");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var videoLink = result.data.RetData.m3u8_url;
    //         if (videoLink != null) {
    //           this.videoUrl = videoLink;
    //           this.$bus.$emit("swOpenVideo", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有廣播檔案");
    //         }
    //       } else if (resStatuse == true && resCode == 1) {
    //         console.log("沒有單集資訊");
    //         alert("目前沒有廣播單集資訊");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // //=== 取得廣播單集內容 ===
    // getRadioEpisode(id) {
    //   apiGetRadioEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("取得'廣播'單集內容");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var videoLink = result.data.RetData.m3u8_url;
    //         if (videoLink != null) {
    //           this.videoUrl = videoLink;
    //           this.$bus.$emit("swOpenVideo", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有廣播檔案");
    //         }
    //       } else if (resStatuse == true && resCode == 1) {
    //         console.log("沒有單集資訊");
    //         alert("目前沒有廣播單集資訊");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>