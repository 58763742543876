<template>
  <div>
    <Loading />
    <LgNote />
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
        <router-link to="/" class="nav-btn">
          <img src="@/assets/img/icon_home.svg" alt="" />
        </router-link>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="page-m-wrap">
          <div class="line-tit">編輯會員資料</div>
          <div class="acc-photo-area acc-photo-edit-area">
            <div class="acc-photo-box">
              <div class="acc-photo-edit" id="accPhotoEdit">
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  @change="imgChange"
                />
              </div>
              <div class="acc-photo">
                <img
                  :src="previewImgSrc"
                  alt=""
                  id="photoImg"
                  v-if="previewImgSrc"
                />
              </div>
            </div>
            <div class="acc-info-box">
              <div class="tit">帳號</div>
              <div class="cont">{{ userData.acc }}</div>
            </div>
          </div>
          <form id="form">
            <div class="acc-info-input-area">
              <div class="acc-input-wrap">
                <label for="userName">姓名</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="text"
                    name="userName"
                    id="userName"
                    v-model="userData.name"
                  />
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="email">聯絡信箱</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="email"
                    name="email"
                    id="email"
                    v-model="userData.mail"
                  />
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="clan">族別</label>
                <div class="acc-input-box">
                  <select
                    class="acc-sel-sty"
                    id="clan"
                    name="clan"
                    v-model="userData.clan"
                  >
                    <option
                      v-for="(item, index) in clanList"
                      :key="index"
                      :value="item.Id"
                    >
                      {{ item.Title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="birthYear">生日</label>
                <!-- acc-birth-box -->
                <div class="acc-input-box">
                  <date-pick
                    class="acc-sel-sty"
                    v-model="userData.birth"
                    :isDateDisabled="isFutureDate"
                    :inputAttributes="{ placeholder: '請選擇日期' }"
                    :months="monthList"
                    :weekdays="weekdayList"
                  ></date-pick>
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="sex">性別</label>
                <div class="acc-input-box label-item-wrap" id="sexChose">
                  <label class="label-item" for="rItem01">
                    <input
                      class="required"
                      id="rItem01"
                      type="radio"
                      name="rItem"
                      value="0"
                      v-model="userData.Gender"
                    />
                    <div class="radio-box"></div>
                    <span>男</span>
                  </label>
                  <label class="label-item" for="rItem02">
                    <input
                      class="required"
                      id="rItem02"
                      type="radio"
                      name="rItem"
                      value="1"
                      v-model="userData.Gender"
                    />
                    <div class="radio-box"></div>
                    <span>女</span>
                  </label>
                  <!-- <label class="label-item" for="rItem03">
                    <input
                      class="required"
                      id="rItem03"
                      type="radio"
                      name="rItem"
                      value="3"
                      v-model="sex"
                    />
                    <div class="radio-box"></div>
                    <span>其他</span>
                  </label> -->
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="phone">聯絡電話</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty"
                    type="text"
                    name="phone"
                    id="phone"
                    v-model="userData.phone"
                  />
                </div>
              </div>
            </div>
            <div class="btn-area">
              <div class="btn-w w-btn" id="submitBtn" @click="submitBtn">
                確認變更
              </div>
              <div class="btn-w blue-btn" @click="backPage">返回</div>
            </div>
          </form>
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
//=== 日期選取器 ===
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

import { apiImgUpload, apiGetClanList, apiUserEdit } from "@/api/api.js";

import LgNote from "@/components/LgNote.vue";
export default {
  data() {
    return {
      //== 族別 ==
      clanList: [],
      //=== 日期設定 ===
      monthList: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      weekdayList: ["ㄧ", "二", "三", "四", "五", "六", "日"],
      //=== 預覽圖片路徑 ===
      previewImgSrc: require("@/assets/img/acc_img.png"),
      imgFile: "", //img檔案
      userData: {
        Name: "布迪耶思",
        Clan: 1,
        Profile: "",
        Email: "tipus1992@gmail.com",
        BirthDay: "2000-01-01",
        Phone: "0952055879",
        Gender: 0,
      },
      userEditData: "",
      // userEditData: {
      //   Name: "-",
      //   Clan: 0,
      //   Profile: "-",
      //   Email: "-",
      //   BirthDay: "-",
      //   Phone: "-",
      //   Gender: 0,
      // },
    };
  },
  mounted() {
    //=== 帶入使用者資料 ===
    // this.userData = this.userInfo; //這樣會被完全取代為同樣的資料，只要更動資料就會連動，所以另外分出值後帶入
    var { userImg, name, acc, clan, birth, Gender, mail, phone } =
      this.userInfo;
    userImg ? (this.previewImgSrc = userImg) : (this.previewImgSrc = "");
    var GenderNum;
    Gender == "男" ? (GenderNum = 0) : (GenderNum = 1);
    this.userData = {
      Profile: userImg,
      name,
      acc,
      clan,
      birth,
      Gender: GenderNum,
      mail,
      phone,
    };
    //=== 取的族別 ===
    this.getClan();
  },
  methods: {
    //== 日期限制 ==
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    //=== 預覽圖片 ===
    imgChange(event) {
      console.log(event);
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          //== 圖片限制 jpeg,png,jpg ==
          var fileType = event.target.files[0].type;
          console.log(event.target.files[0].type);
          if (fileType.indexOf("image") == -1) {
            console.log("= 不是圖片 =");
            alert("請上傳圖片檔案。");
          } else {
            //== 圖片預覽路徑 ==
            this.previewImgSrc = e.target.result;
            //== 放入圖片檔案 ==
            this.imgFile = event.target.files[0];
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    //=== 取的族別API ===
    getClan() {
      apiGetClanList()
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            console.log("族別成功！");
            this.clanList = result.data.RetData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 送出 ===
    submitBtn() {
      var _form = window.$("#form").valid();
      if (_form) {
        // window.lgNote("修改成功！");
        // this.$router.push("/account");
        // console.log("修改成功！");
        if (!this.previewImgSrc) {
          //== Bus發送loading事件 ==
          // this.$bus.$emit("loading", true);
          //== 註冊 ==
          console.log("無圖片修改");
          this.toUserEdit();
        } else {
          //== Bus發送loading事件 ==
          // this.$bus.$emit("loading", true);
          //== 註冊含圖片 ==
          console.log("有圖片修改");
          this.upLoadImgRegister();
        }
      }
    },
    //=== 上傳照片 ===
    upLoadImgRegister() {
      apiImgUpload({
        Img: this.previewImgSrc,
        Folder: 0,
      })
        .then((result) => {
          console.log(result.data);
          console.log(result.data.RetData.FileName);
          this.userData.Profile = result.data.RetData.FileName;
          console.log(this.userData.Profile);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          console.log(resStatuse, resCode);
          if (resStatuse == true && resCode == 0) {
            console.log("圖片傳送後有去編輯");
            //== 編輯 ==
            this.toUserEdit();
            // this.toRegister();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 修改會員資料 ===
    toUserEdit() {
      var { name, clan, Profile, mail, birth, phone, Gender } = this.userData;
      this.userEditData = {
        Name: name,
        Clan: clan,
        Profile,
        Email: mail,
        BirthDay: birth,
        Phone: phone,
        Gender,
      };
      apiUserEdit(this.userEditData)
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          console.log(resStatuse, resCode);
          if (resStatuse == true && resCode == 0) {
            console.log("=== 會員資料修改 ===");
            console.log(result.data);
            this.$bus.$emit("loading", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 回上一頁 ===
    backPage() {
      // this.upLoadImgRegister();
      this.$router.go(-1);
    },
  },
  computed: {
    userInfo: {
      get() {
        // return this.$store.getters["userInfoGet"];
        return this.$store.state.userInfo;
      },
      set(val) {
        // this.$store.dispatch("GETTOUSERINFO", val);
        this.$store.commit("SETUSERINFO", val);
      },
    },
  },
  components: {
    Loading,
    LgNote,
    DatePick,
  },
};
</script>
<style>
.vdpWithInput {
  position: relative;
  padding: 0;
  z-index: 3;
}
.vdpClearInput {
  display: none;
}
.vdpWithInput input {
  font-size: 16px;
  color: #fff;
  padding: 10px;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
}
</style>