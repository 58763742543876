<template>
  <div>
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="page-m-wrap">
          <div class="line-tit">隱私權政策</div>
          <div class="privacy-txt-area">
            <div class="privacy-b-tit">
              財團法人原住民族文化事業基金會隱私權保護政策
            </div>
            <p>
              財團法人原住民族文化事業基金會為保障您個人資料之安全性，特針對您個人的隱私權擬定「個人資料暨隱私權保護政策」聲明（Privacy
              Policy，下簡稱隱私權政策），讓您明瞭財團法人原住民族文化事業基金會對於您的隱私權之尊重與保護，並告訴您有關您個人資料之相關資訊。本政策讓您明瞭本基金會在蒐集您的個人資料時：
            </p>
            <ol>
              <li>本基金會的名稱。</li>
              <li>蒐集之目的。</li>
              <li>個人資料之類別。</li>
              <li>個人資料利用之期間、地區、對象及方式。</li>
              <li>您得行使之權利及方式。</li>
              <li>若您無法提供完整的個人資料時，不提供將對您權益之影響。</li>
              <li>個人資料的安全維護。請您詳細閱讀本基金會「隱私權條款」。</li>
            </ol>
            <div class="privacy-tit">壹、適用範圍：</div>
            <p class="txt-cont">
              本隱私權保護政策及其所包含之告知事項，適用於財團法人原住民族文化事業基金會。
            </p>
            <div class="privacy-tit">貳、資料蒐集之目的及類別：</div>
            <p class="txt-cont">
              一、在您註冊財團法人原住民族文化事業基金會帳號、使用財團法人原住民族文化事業基金會產品或服務、瀏覽財團法人原住民族文化事業基金會網頁或某些合作夥伴的網頁，以及參加宣傳活動或贈獎遊戲時，財團法人原住民族文化事業基金會會蒐集您的個人資料。財團法人原住民族文化事業基金會也可能將商業夥伴或其他企業所提供的關於您的資訊與財團法人原住民族文化事業基金會所擁有的您的個人資料相結合。
            </p>
            <p class="txt-cont">
              二、財團法人原住民族文化事業基金會蒐集您與我們或我們的商業夥伴交易的資料，包括您使用我們所提供服務的相關資料。
            </p>
            <p class="txt-cont">
              三、財團法人原住民族文化事業基金會也自動接收並記錄您電腦和瀏覽器上的資料，包括
              IP位址、財團法人原住民族文化事業基金會cookie中的資料、軟體和硬體屬性以及您瀏覽的網頁紀錄。
            </p>
            <p class="txt-cont">
              四、在您使用財團法人原住民族文化事業基金會產品及服務期間，財團法人原住民族文化事業基金會得於全球地區將資料用作以下用途：客製化廣告及您看到的網頁內容、滿足您對產品和服務的要求、改進我們的服務、聯絡您、進行研究，以及提供內部及外部客戶不載有個人資料之市場分析或業務報告。
            </p>
            <p class="txt-cont">
              五、財團法人原住民族文化事業基金會蒐集、處理及利用個人資料之特定目的如下：行銷業務、消費者、客戶管理與服務、資訊通訊服務、網路購物及其他電子商務服務、廣告和商業行為管理業務以及其他契約、類似契約或法律關係管理之事務或業務。
            </p>
          </div>
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>