<template>
  <div>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area register-done-area">
        <div class="logo"><img src="@/assets/img/logo.svg" alt="" /></div>
        <div class="register-done-tit">
          會員註冊完成！<br />請至註冊信箱驗證後登入
        </div>
        <div class="register-done-txt">
          感謝您加入本會會員<br />
          請盡情享受由本會帶來的<br />
          各項優質內容<br />
          望您持續支持本會<br /><br />
          謝謝
        </div>
        <div class="btn-area">
          <router-link to="/login" class="btn-w red-btn">前往登入</router-link>
          <!-- <a class="btn-w red-btn" href="login.html">前往登入</a> -->
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>