<template>
  <div class="live-type-video-wrap">
    <div class="swiper-container live-type-video" :id="swId">
      <div class="swiper-wrapper">
        <SwLiveVideo
          v-for="(item, index) in getSwList"
          :key="index"
          :swType="swType"
          :videoId="item.videoId"
          :img="item.img"
          :tit="item.tit"
          :cont="item.cont"
        />
        <!-- :thumbNum="item.thumbNum"
        :videoUrl="item.videoUrl" -->
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import SwLiveVideo from "@/components/SwLiveVideo.vue";

export default {
  name: "LiveVideoSwArea",
  props: ["swType", "swId", "swList"],
  data() {
    return {
      swArea: "",
    };
  },
  mounted() {
    this.swArea = new Swiper("#" + this.swId, {
      slidesPerView: "1",
      spaceBetween: 25,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
  },
  computed: {
    getSwList: {
      get() {
        return this.swList;
      },
      set(val) {
        this.$emit("update:swList", val);
      },
    },
  },
  components: {
    SwLiveVideo,
  },
  //只有更新資料時就動作 dom還沒更新完
  watch: {
    // getSwList(newVal) {
    //   console.log("== watch ==");
    //   console.log(newVal);
    //   // console.log(this.$("#" + this.swId).find(".live-type-video-box").length);
    //   // this.swArea.updateSlides();
    //   // this.swArea.slideTo(9, 0);
    // },
  },
  //updated 會等dom跑完才動作
  updated() {
    console.log("== updated ==");
    // console.log(this.$("#" + this.swId).find(".live-type-video-box").length);
    this.swArea.update();
    this.swArea.slideTo(0, 0);
  },
};
</script>