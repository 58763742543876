<template>
  <div class="swiper-slide">
    <div class="program-video-box video-play" @click="openVideo(videoId)">
      <!-- <img :src="require('@/assets/img/' + img)" alt="" /> -->
      <img :src="img" alt="" v-if="img" />
      <!-- 如果沒有圖片放預設圖片 -->
      <img
        :src="require('@/assets/img/default_program_hor.jpg')"
        alt=""
        v-if="!img"
      />
      <div class="program-video-cont">
        <div class="tit">{{ tit }}</div>
        <div class="sub">{{ sub }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsTopVideo",
  props: ["img", "tit", "sub", "videoId"],
  methods: {
    //== 傳送項目 ==
    openVideo(id) {
      //= ytVideo =
      // this.$bus.$emit("swOpenYTVideo", id);
      //= m3u8 =
      console.log(this.videoId);
      if (this.videoId == null) {
        alert("目前沒有影音檔案");
      } else {
        var videoData = {
          videoLink: this.videoId,
          swType: "program",
        };
        this.$bus.$emit("swOpenVideo", videoData);
      }
    },
  },
};
</script>
<style>
.program-video-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
</style>