<template>
  <div class="program-video-list-banner">
    <img
      :src="mainProgramData.img"
      alt=""
      class="program-video-list-banner-cover"
    />
    <!-- :src="require('@/assets/img/' + mainProgramData.img)" -->
    <!-- :style="{ backgroundImage: `url('${mainProgramData.img}')` }" -->
    <div class="program-video-list-banner-cont">
      <div class="tit-box">
        <!-- <div class="program-btn-box">
          <div
            class="program-banner-btn"
            @click="openVideo(mainProgramData.mainProgramId)"
          >
            觀看
          </div>
        </div> -->
        <div class="tit">{{ mainProgramData.tit }}</div>
        <div class="icon-box">
          <!-- <div class="num">123</div> -->
          <!-- <div class="icon">
                    <img src="@/assets/img/icon_thumb_b.svg" alt="" />
                  </div> -->
          <!-- <div class="icon">
            <img src="@/assets/img/icon_heart_r.svg" alt="" />
          </div> -->
        </div>
      </div>
      <div class="cont scroll-w" v-html="mainProgramData.cont"></div>
    </div>
  </div>
</template>
<script>
import { apiGetProgramEpisodeList, apiGetProgramEpisode } from "@/api/api.js";
export default {
  name: "ProgramBanner",
  props: ["mainProgramData"],
  data() {
    return {
      videoUrl: "",
    };
  },
  mounted() {
    //=== scroll bar ===
    // window.$(".scroll-w").mCustomScrollbar();
  },
  methods: {
    // // === 變成級數在下面所以banner不用點選跑出第一則 ===
    // openVideo(id) {
    //   if (this.videoUrl == "") {
    //     // console.log(id);
    //     this.getProgramEpisodeList(id);
    //   } else {
    //     this.$bus.$emit("swOpenVideo", this.videoUrl);
    //   }
    // },
    // //== 取的節目集數列表 ==
    // getProgramEpisodeList(id) {
    //   apiGetProgramEpisodeList({
    //     index: 0,
    //     ProgramType: 0,
    //     ScheduleID: id,
    //     IsAll: false,
    //   })
    //     .then((result) => {
    //       console.log("live節目級數列表");
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var resData = result.data.RetData;
    //         this.getProgramEpisode(resData[0].ID.toString());
    //       } else if (resStatuse == true && resCode == 1) {
    //         console.log("沒有廣播集數列表");
    //         alert("目前沒有廣播集數列表");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // //=== 取得節目單集內容 ===
    // getProgramEpisode(id) {
    //   apiGetProgramEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("取得'節目'單集內容");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var videoLink = result.data.RetData.m3u8_url;
    //         if (videoLink != null) {
    //           this.videoUrl = videoLink;
    //           this.$bus.$emit("swOpenVideo", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有廣播檔案");
    //         }
    //       } else if (resStatuse == true && resCode == 1) {
    //         console.log("沒有單集資訊");
    //         alert("目前沒有廣播單集資訊");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>