<template>
  <div>
    <Loading />
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
        <router-link to="/" class="nav-btn">
          <img src="@/assets/img/icon_home.svg" alt="" />
        </router-link>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="login-icon-area">
          <div class="txt">使用</div>
          <div class="login-icon-box" style="display:;"><!-- **1027暫時隱藏  -->
            
            <div class="icon" @click="FBRegister">
              <img src="@/assets/img/icon_c_fb.svg" alt="" />
            </div>
            <div class="icon google-icon">
              <img src="@/assets/img/icon_c_google.svg" alt="" />
              <GoogleLogin
                :params="params"
                :onSuccess="onSuccess"
                :onFailure="onFailure"
              ></GoogleLogin>
            </div>
            <!-- <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">Login</GoogleLogin> -->
            <div class="icon" v-if="false">
              <img src="@/assets/img/icon_c_apple.svg" alt="" />
            </div>
          </div>
          <div class="txt" v-if="false">
            或
            <div class="w-blur-btn reg-org-btn" @click="ordinaryReg">
              一般註冊
            </div>
          </div>
        </div>
        <div class="page-m-wrap page-m-wrap-reg">
          <div class="line-tit">{{ regTit }}</div>
          <div class="acc-photo-area acc-photo-edit-area">
            <div class="acc-photo-box">
              <div
                class="acc-photo-icon"
                :class="threePartIcon"
                v-if="threePartIcon"
              ></div>
              <div class="acc-photo" id="accPhoto" v-if="threePartImg">
                <img :src="threePartImg" id="photoImg" />
              </div>
              <div
                class="acc-photo-edit"
                id="accPhotoEdit"
                v-if="!threePartIcon"
              >
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  @change="imgChange"
                />
              </div>
              <div class="acc-photo" id="accPhoto" v-if="!threePartIcon">
                <img :src="previewImgSrc" id="photoImg" v-if="previewImgSrc" />
              </div>
            </div>
          </div>
          <form id="form">
            <div class="acc-info-input-area">
              <div class="acc-input-wrap" v-if="norUse">
                <label for="account">帳號</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="text"
                    name="account"
                    id="account"
                    v-model="registerData.MemberShipSignUpInfo.Account"
                    placeholder="請輸入帳號"
                  />
                </div>
              </div>
              <div class="acc-input-wrap" v-if="norUse">
                <label for="pwd">密碼</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="password"
                    name="pwd"
                    id="pwd"
                    v-model="registerData.MemberShipSignUpInfo.Password"
                    placeholder="請輸入密碼"
                  />
                </div>
              </div>
              <div class="acc-input-wrap" v-if="norUse">
                <label for="checkPwd">確認密碼</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="password"
                    name="checkPwd"
                    id="checkPwd"
                    v-model="registerData.MemberShipSignUpInfo.PasswordAgain"
                    placeholder="請再次輸入密碼"
                  />
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="userName">姓名</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="text"
                    name="userName"
                    id="userName"
                    v-model="registerData.MemberShipSignUpInfo.Name"
                    placeholder="請輸入姓名"
                  />
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="email">聯絡信箱</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty required"
                    type="email"
                    name="email"
                    id="email"
                    v-model="registerData.MemberShipSignUpInfo.Email"
                    placeholder="請輸入信箱"
                  />
                </div>
              </div>
              <div class="hr-line"></div>
              <div class="acc-input-wrap">
                <label for="clan">族別</label>
                <div class="acc-input-box">
                  <select
                    class="acc-sel-sty"
                    id="clan"
                    v-model="registerData.MemberShipSignUpInfo.Clan"
                    name="clan"
                  >
                    <option
                      v-for="(item, index) in clanList"
                      :key="index"
                      :value="item.Id"
                    >
                      {{ item.Title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="year">生日</label>
                <!-- acc-birth-box -->
                <div class="acc-input-box">
                  <date-pick
                    class="acc-sel-sty"
                    v-model="registerData.MemberShipSignUpInfo.BirthDay"
                    :isDateDisabled="isFutureDate"
                    :inputAttributes="{
                      placeholder: '請選擇日期',
                    }"
                    :months="monthList"
                    :weekdays="weekdayList"
                  ></date-pick>
                  <!-- :inputAttributes="{ placeholder: '請選擇日期', class: 'required',}" -->
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="sex">性別</label>
                <div class="acc-input-box label-item-wrap" id="sexChose">
                  <label class="label-item" for="rItem01">
                    <input
                      class="required"
                      id="rItem01"
                      type="radio"
                      name="rItem"
                      value="0"
                      v-model="registerData.MemberShipSignUpInfo.Gender"
                    />
                    <div class="radio-box"></div>
                    <span>男</span>
                  </label>
                  <label class="label-item" for="rItem02">
                    <input
                      class="required"
                      id="rItem02"
                      type="radio"
                      name="rItem"
                      value="1"
                      v-model="registerData.MemberShipSignUpInfo.Gender"
                    />
                    <div class="radio-box"></div>
                    <span>女</span>
                  </label>
                  <!-- <label class="label-item" for="rItem03">
                    <input
                      class="required"
                      id="rItem03"
                      type="radio"
                      name="rItem"
                      value="3"
                      v-model="sex"
                    />
                    <div class="radio-box"></div>
                    <span>其他</span>
                  </label> -->
                </div>
              </div>
              <div class="acc-input-wrap">
                <label for="phone">聯絡電話</label>
                <div class="acc-input-box">
                  <input
                    class="acc-input-sty"
                    type="text"
                    name="phone"
                    id="phone"
                    v-model="registerData.MemberShipSignUpInfo.Phone"
                    placeholder="請輸入電話"
                  />
                </div>
              </div>
              <div class="input-box-area">
                <div class="label-item-wrap privacy-box" id="privacyBox">
                  <label class="label-item" for="privacy">
                    <input
                      id="privacy"
                      type="checkbox"
                      v-model="privacy"
                      name="privacy"
                    />
                    <div class="check-box fas"></div>
                    <span
                      >同意遵守<router-link to="/privacy"
                        >隱私權政策</router-link
                      >及<a href="###">使用條款</a></span
                    >
                  </label>
                </div>
              </div>
            </div>
            <div class="btn-area">
              <div class="btn-w red-btn" id="submitBtn" @click="submitBtn">
                註冊
              </div>
              <router-link to="/login" class="btn-w w-btn">取消</router-link>
              <!-- <a class="btn-w w-btn" href="login.html">取消</a> -->
            </div>
          </form>
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
//=== 日期選取器 ===
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

import GoogleLogin from "vue-google-login";

import Loading from "@/components/Loading.vue";

import { apiGetClanList, apiRegister, apiImgUpload } from "@/api/api.js";

export default {
  data() {
    return {
      //=== 註冊標題 ===
      regTit: "一般註冊",
      //== 族別 ==
      clanList: [],
      //=== 日期設定 ===
      monthList: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      weekdayList: ["ㄧ", "二", "三", "四", "五", "六", "日"],
      //=== 預覽圖片路徑 ===
      previewImgSrc: "",
      imgFile: "", //img檔案
      //== 第三方登入資料 ==
      norUse: true,
      threePartIcon: "",
      threePartImg: "",
      registerData: {
        MemberShipSignUpInfo: {
          MemberType: 0,
          Account: "",
          Password: "",
          PasswordAgain: "",
          Name: "",
          Clan: 1,
          Profile: "", //大頭貼路徑
          Email: "",
          BirthDay: "",
          Phone: "",
          Gender: 0, //0:男,1:女
        },
        MemberType: 0, // 0:一般註冊, 1:Google, 2:Apple, 3:Facebook
        SocialData: null,
        // SocialData: {
        //   userID: "",
        // },
      },
      privacy: false,
      //=== google ID和樣式 ===
      params: {
        client_id:
          "738963824675-ushi8snc0dtvvko7kme0kiqoik63v1lg.apps.googleusercontent.com",
      },
      // renderParams: {
      //   width: 250,
      //   height: 50,
      //   theme: "dark",
      //   longtitle: true,
      // },
    };
  },
  mounted() {
    //== 表單設定 ==
    window.$("#form").validate({
      rules: {
        //checkNew這裡的是name 屬性 不是 id 屬性
        checkPwd: {
          equalTo: "#pwd",
        },
      },
      messages: {
        checkPwd: {
          equalTo: "密碼不符",
        },
      },
    });
    //=== 取的族別 ===
    this.getClan();
  },
  methods: {
    //== 日期限制 ==
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    //=== 預覽圖片 ===
    imgChange(event) {
      console.log(event);
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          //== 圖片限制 jpeg,png,jpg ==
          var fileType = event.target.files[0].type;
          console.log(event.target.files[0].type);
          if (fileType.indexOf("image") == -1) {
            console.log("= 不是圖片 =");
            alert("請上傳圖片檔案。");
          } else {
            //== 圖片預覽路徑 ==
            this.previewImgSrc = e.target.result;
            console.log(event);
            //== 放入圖片檔案 ==
            // this.imgFile = event.target.files[0];
            this.imgFile = e.target.result;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    //=== 送出 ===
    submitBtn() {
      var _form = window.$("#form").valid();
      if (_form) {
        if (!this.privacy) {
          alert("請同意遵守隱私權政策及使用條款。");
          return;
        }
        if (!this.previewImgSrc) {
          //== Bus發送loading事件 ==
          this.$bus.$emit("loading", true);
          //== 註冊 ==
          console.log("無圖片");
          this.toRegister();
        } else {
          //== Bus發送loading事件 ==
          this.$bus.$emit("loading", true);
          //== 註冊含圖片 ==
          console.log("有圖片");
          this.upLoadImgRegister();
        }
        //== 送出含圖片資料 ==
        // let formData = new FormData();
        // formData.append("image", this.imgFile);
        // formData.append("type", this.type);
        // formData.append("chinese_name", this.tit);
        // formData.append("english_name", this.titEn);
        // formData.append("content", this.content);
        // apiAreaAdd(formData)
        //   .then((result) => {
        //     console.log(result.data);
        //     if (result.data.message == "Success") {
        //       alert("已新增！");
        //       //回上一頁
        //       this.$router.push("/AreaList/" + this.typeUrl);
        //       // this.backPage();
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     if (err.request.status == 422) {
        //       console.log("資料參數錯誤");
        //     }
        //   });
      }
    },
    //=== 取的族別API ===
    getClan() {
      apiGetClanList()
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            console.log("族別成功！");
            this.clanList = result.data.RetData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 上傳照片 ===
    upLoadImgRegister() {
      apiImgUpload({
        Img: this.previewImgSrc,
        Folder: 0,
      })
        .then((result) => {
          console.log(result.data);
          console.log(result.data.RetData.FileName);
          this.registerData.MemberShipSignUpInfo.Profile =
            result.data.RetData.FileName;
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          console.log(resStatuse, resCode);
          if (resStatuse == true && resCode == 0) {
            console.log("圖片傳送後有去註冊");
            //== 註冊 ==
            this.toRegister();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 註冊 ===
    toRegister() {
      // var { MemberShipSignUpInfo, MemberType, SocialData } = this.registerData;
      // console.log({ MemberShipSignUpInfo, MemberType, SocialData });
      apiRegister(this.registerData)
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            console.log("註冊成功！");
            this.$bus.$emit("loading", false);
            this.$router.push("/registerDone");
          } else if (resStatuse == false && resCode == 4001) {
            this.$bus.$emit("loading", false);
            alert("此帳號已註冊。");
          }
        })
        .catch((err) => {
          console.log(err);
          // if (err.request.status == "422") {
          //   alert("搜尋資料參數錯誤。");
          //   this.$router.push("/Sum").catch((err) => {
          //     err;
          //   });
          // }
        });
    },
    //=== 一般註冊 ===
    ordinaryReg() {
      this.regTit = "一般註冊";
      this.norUse = true;
      this.registerData.MemberType = 0;
      this.registerData.SocialData = null;
      this.registerData.MemberShipSignUpInfo.MemberType = 0;
      this.registerData.MemberShipSignUpInfo.Account = "";
      this.registerData.MemberShipSignUpInfo.Password = "";
      this.registerData.MemberShipSignUpInfo.PasswordAgain = "";
      this.registerData.MemberShipSignUpInfo.Name = "";
      this.registerData.MemberShipSignUpInfo.Email = "";
    },
    //=== FB註冊 ===
    FBRegister() {
      window.FB.getLoginStatus((res) => {
        console.log(`status:${res.status}`); //Debug
        if (res.status === "connected") {
          let userID = res["authResponse"]["userID"];
          console.log(
            "用戶已授權您的App，用戶須先revoke撤除App後才能再重新授權你的App"
          );
          //console.log(`已授權App登入FB 的 userID:${userID}`);
          var accessToken = res.authResponse.accessToken;
          console.log("登入的token:", accessToken);
          //console.log("res:", res);
          this.GetFBProfile();
        } else if (
          res.status === "not_authorized" ||
          res.status === "unknown"
        ) {
          //console.log(`res.status是:${res.status}`); //Debug
          //App未授權或用戶登出FB網站才讓用戶執行登入動作
          window.FB.login(
            (response) => {
              //console.log(response); //debug用
              if (response.status === "connected") {
                //user已登入FB
                //抓userID
                let userID = response["authResponse"]["userID"];
                console.log(`已授權App登入FB 的 userID:${userID}`);
                this.GetFBProfile();
              } else {
                // user FB取消授權
                alert("Facebook帳號無法登入");
              }
              //"public_profile"可省略，仍然可以取得name、userID
            },
            { scope: "public_profile, email" }
          );
        }
        //取得用戶姓名、email
        //this.GetFBProfile();
      });
    },
    //取得用戶姓名、email
    GetFBProfile() {
      //取得用戶個資
      window.FB.api(
        "/me",
        "GET",
        { fields: "id,last_name,first_name,name,email,picture" },
        (user) => {
          //user物件的欄位：https://developers.facebook.com/docs/graph-api/reference/user
          if (user.error) {
            console.log("user.error？", user.error);
            //console.log('出現錯誤？',response);
          } else {
            // document.getElementById('content').innerHTML = JSON.stringify(user);
            // console.log(user);
            // console.log(user.id);
            // console.log(user.first_name);
            // console.log(user.last_name);
            // console.log(user.email);
            // console.log(user.first_name + user.name);
            // console.log(user.picture.data.url);
            // console.log("這裡都還有~");
            console.log(user);
            var { id, name, email, picture } = user;
            this.regTit = "FB註冊";
            this.norUse = false;
            this.registerData.MemberType = 3;
            this.registerData.SocialData = { userID: id };
            this.registerData.MemberShipSignUpInfo.MemberType = 3;
            this.registerData.MemberShipSignUpInfo.Account = email;
            this.registerData.MemberShipSignUpInfo.Password = null;
            this.registerData.MemberShipSignUpInfo.PasswordAgain = null;
            this.registerData.MemberShipSignUpInfo.Name = name;
            email == undefined
              ? (this.registerData.MemberShipSignUpInfo.Email = "")
              : (this.registerData.MemberShipSignUpInfo.Email = email);
            // if (picture != undefined) {
            //   this.threePartImg = picture.data.url;
            //   // this.registerData.MemberShipSignUpInfo.Profile = picture.data.url;
            //   this.threePartIcon = "acc-icon-fb";
            // }
          }
        }
      );
    },
    //=== google 功能 ===
    onSuccess(googleUser) {
      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
      console.log('.getId():',googleUser.getBasicProfile().getId())
      //Re - 全名,Tt - 信箱,lK - 頭像,sT - ID
      // var { Re, Yt, Tt, lK, sT, uT} = googleUser.getBasicProfile();

      //11/18變數名稱變了， hf - 全名, pv - 信箱, GW - ID,
      var { hf, pv, GW,} = googleUser.getBasicProfile();
      
      this.regTit = "Google註冊";
      this.norUse = false;
      this.registerData.MemberType = 1;
      this.registerData.SocialData = { userID: GW };
      this.registerData.MemberShipSignUpInfo.MemberType = 1;
      this.registerData.MemberShipSignUpInfo.Account = pv;
      this.registerData.MemberShipSignUpInfo.Password = null;
      this.registerData.MemberShipSignUpInfo.PasswordAgain = null;
      this.registerData.MemberShipSignUpInfo.Name = hf;
      this.registerData.MemberShipSignUpInfo.Email = pv;
      // if (lK != undefined) {
      //   this.threePartImg = lK;
      //   // this.registerData.MemberShipSignUpInfo.Profile = picture.data.url;
      //   this.threePartIcon = "acc-icon-google";
      // }
    },
    onFailure(error) {
      console.log(error);
    },
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
      // this.upLoadImgRegister();
    },
  },
  components: {
    DatePick,
    Loading,
    GoogleLogin,
  },
};
</script>
<style>
.vdpWithInput {
  position: relative;
  padding: 0;
  z-index: 3;
}
.vdpClearInput {
  display: none;
}
.vdpWithInput input {
  font-size: 16px;
  color: #fff;
  padding: 10px;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
}
.page-m-wrap-reg {
  padding-top: 0;
}
.hr-line {
  height: 1px;
  margin: 15px 0;
  background: #fff;
}

.google-icon {
  position: relative;
}
.google-icon button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}
</style>