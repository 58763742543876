<template>
  <div class="host-sw-area">
    <div class="swiper-container host-sw" id="hostSw">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in hostPerList"
          :key="index"
        >
          <div @click="openHostInfo(item.hostId)">
            <div class="host-sw-img">
              <img :src="item.img" alt="" />
              <!-- <img :src="require('@/assets/img/' + item.img)" alt="" /> -->
            </div>
            <div class="host-sw-tit">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

export default {
  name: "HostNewsSwList",
  props: ["hostPerList", "pageIndex", "pageTotal"],
  data() {
    return {
      hostSw: "",
    };
  },
  mounted() {
    this.hostSw = new Swiper("#hostSw", {
      slidesPerView: 4.5,
      spaceBetween: 15,
      // freeMode: true,
    });
    this.hostSw.on("slideChange", () => {
      if (this.hostSw.isEnd && this.pageIndex != this.pageTotal) {
        console.log("== 看我 ==");
        console.log(this.pageIndex);
        this.$bus.$emit("hostPageData", this.pageIndex);
        // this.$bus.$emit("hostPageData", this.pageIndex + 1);
      }
    });
  },
  methods: {
    openHostInfo(name) {
      this.$bus.$emit("hostInfoData", name);
    },
  },
  updated() {
    this.hostSw.update();
  },
};
</script>