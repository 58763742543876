<template>
  <div>
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <p style="color:#fff">廣播</p>
        <iframe src="https://rds.ginnet.cloud/player/m/default/vod/vod0130vh-e418/TBS-FM-1-07+_20211027_070000" style="width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen"></iframe>

        <p style="color:#fff">影音</p>
        <iframe src="https://rds.ginnet.cloud/player/vod/vod0130vh-e418/TBS-FM-1-07+_20211027_070000" style="width:100%;height:300px;" frameborder="0" allow="autoplay; fullscreen"></iframe>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  data() {
    return {};
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
  },
};
</script>