<template>
  <div>
    <VideoLightBox />
    <VideoYTLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-tit">PROGRAM</div>
      <div class="page-area">
        <!-- 後台上方影片自選列表 -->
        <TopVideoSw
          :NewsVideoList="programVideoList"
          v-if="programVideoList.length"
        />

        <!--  **原本取得官網的      
        <ProgramBannerVideo
          :programVideoList="programVideoList"
          v-if="programVideoList.length"
        /> -->

        <!-- <div class="program-video-wrap">
          <div class="swiper-container program-video" id="programVideo">
            <div class="swiper-wrapper">
              <ProgramVideo
                v-for="(item, index) in ProgramVideoList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :sub="item.sub"
                :videoUrl="item.videoUrl"
              />
            </div>
          </div>
        </div> -->
        <div class="program-video-list-area">
          <ProgramBanner
            :mainProgramData="mainProgramData"
            v-if="mainProgramData.mainProgramId"
          />
          <!-- <div class="program-video-list-banner">
            <img
              :src="require('@/assets/img/' + mainProgramData.img)"
              alt=""
              class="program-video-list-banner-cover"
            />
            :style="{ backgroundImage: `url('${mainProgramData.img}')` }"
            <div class="program-video-list-banner-cont">
              <div class="tit-box">
                <div class="tit">{{ mainProgramData.tit }}</div>
                <div class="icon-box">
                  <div class="num">123</div>
                  <div class="icon">
                    <img src="@/assets/img/icon_thumb_b.svg" alt="" />
                  </div>
                  <div class="icon">
                    <img src="@/assets/img/icon_heart_r.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="cont">
                {{ mainProgramData.cont }}
              </div>
            </div>
          </div> -->
        </div>
        <div class="live-type-video-wrap program-video-list-sw">
          <ProgramBlurSw
            ind="1"
            swName="bannerProgramSw"
            :swId="mainProgramData.mainProgramId"
            :swList="SwProgramVideoList"
            :swPage="mainProgramData.page"
            :swPageTotal="mainProgramData.pageTotal"
            :isBanner="true"
            v-if="SwProgramVideoList.length != 0"
          />
          <div class="no-data" v-if="SwProgramVideoList.length == 0">
            尚無節目。
          </div>
        </div>

        <!-- <ProgramBannerSw
          :swList="SwProgramVideoList"
          v-if="SwProgramVideoList.length"
        /> -->

        <!-- <div class="live-type-video-wrap program-video-list-sw">
          <div class="swiper-container live-type-video" id="liveTypeVideo">
            <div class="swiper-wrapper">
              <SwLiveVideo
                v-for="(item, index) in SwProgramVideoList"
                :key="index"
                swType="program"
                :videoId="item.videoId"
                :img="item.img"
                :tit="item.tit"
                :cont="item.cont"
              />
            </div>
          </div>
        </div> -->
        <div class="live-type-area">
          <TypeTabSw
            swId="programTypeTab"
            tabType="program"
            :tabList.sync="programType"
            v-if="programType.length != 0"
          />
        </div>
        <div class="no-data" v-if="!ProgramBlurList.length">Loading...</div>
        <!-- :dataL="ProgramBlurList.length" -->
        <ProgramBlurArea
          v-for="(item, index) in ProgramBlurList"
          :key="index"
          :ind="index"
          :titCont="item.titCont"
          :swPage="item.swPage"
          :swPageTotal="item.swPageTotal"
          :swIsShow.sync="item.swIsShow"
          :swName="ProgramBlurSwName"
          :swList="item.swList"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";

import ProgramBannerVideo from "@/components/ProgramBannerVideo.vue";
import TopVideoSw from "@/components/TopVideoSw.vue"; //新增Top列表
import ProgramBanner from "@/components/ProgramBanner.vue";
import ProgramBlurSw from "@/components/ProgramBlurSw.vue";
import ProgramBannerSw from "@/components/ProgramBannerSw.vue";
import TypeTabSw from "@/components/TypeTabSw.vue";
import ProgramBlurArea from "@/components/ProgramBlurArea.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";
import VideoYTLightBox from "@/components/VideoYTLightBox.vue";

import {
  apiGetCategorys,
  apiGetProgramList,
  apiGetProgramDetail,
  apiGetProgramEpisodeList,
  apiProgramTop,
  apiProgramTopic,
} from "@/api/api.js";

export default {
  data() {
    return {
      // MainProgramBg: require("@/assets/img/program_main.jpg"),
      //=== 最上面推薦節目列表 ===
      //**1027先上YT預告 */
      programVideoList: [
        // {
        //   img: "https://i.ytimg.com/vi/FwYNpcDJtLk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《zalan見識南島》第一季預告",
        //   videoUrl: "FwYNpcDJtLk",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/zqIAaxuBQec/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《isa isa 動次動》預告",
        //   videoUrl: "zqIAaxuBQec",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/Rv_xsGGMGfY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《SEMENAY我們的歌》第1集-驀然回首最遙遠的路",
        //   videoUrl: "Rv_xsGGMGfY",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/i_8wvH66T1E/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《LIMA幫幫忙》 第三季 第14集-加分,錯了嗎？",
        //   videoUrl: "i_8wvH66T1E",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/QVEFOiDP3tk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《 Kari不停學》預告",
        //   videoUrl: "QVEFOiDP3tk",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/Ip2i_9ECmEQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《LIMA幫幫忙》第三季 第13集-喝酒，錯了嗎？",
        //   videoUrl: "Ip2i_9ECmEQ",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/GGTUEriX1hw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《Tahu生火吧》第三季第30-33集預告",
        //   videoUrl: "GGTUEriX1hw",
        // },
        // {
        //   img: "https://i.ytimg.com/vi/AxtVaW22E4g/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
        //   tit: "《Alang我的名字》預告",
        //   videoUrl: "AxtVaW22E4g",
        // },
        // {
        //   img: "program_top_01.jpg",
        //   tit: "Tahu生火吧",
        //   sub: "123",
        //   videoUrl: "https://youtu.be/mmxwGhmRpkc",
        // },
      ],
      //=== 取得未分類節目列表  ===
      swProgramAllData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      //== 未分類節目列表 主要節目 ==
      mainProgramData: {
        mainProgramId: "",
        img: "--",
        tit: "--",
        cont: "--",
        page: "",
        pageTotal: "",
      },
      // mainProgramData: {
      //   mainProgramId: "1",
      //   img: "program_main.jpg",
      //   tit: "部落防疫總動員",
      //   cont: "要帶給觀眾疫情之下的相關資訊，最重要的是透過視訊的方式，了解台灣各部落的防疫措施；還有生活所面臨到的大小事；重要的是為了緩解大家的不安情緒，透過專家和在家運動短片，舒緩身心情，增加免疫力。主持人：臧芮軒 ",
      // },
      //== 未分類節目列表 ==
      SwProgramVideoList: [
        // {
        //   videoId: "1",
        //   img: "program_main_01.jpg",
        //   tit: "酷wawa",
        //   cont: "如果爸媽可以放任孩子天馬行空的想像力!放心~讓他們去做想作的事…你會發現，小孩其實是天生的工程師，創意十足且擅長解決問題。這個節目要做的一件最重要的事是，讓孩子自己去完成一些事，挑",
        //   // thumbNum: "10",
        //   // videoUrl: "https://youtu.be/u02MQmi-pCA",
        // },
        // {
        //   videoId: "2",
        //   img: "program_main_02.jpg",
        //   tit: "Tahu生火吧",
        //   cont: "Tahu生火吧【第三季】全新的一季《Tahu生火吧》我們來到了花蓮太魯閣國家公園上方的大同大禮部落，這次我們找來兩組家庭，分別有著不同的生活背景，他們要和接待家庭一起回家!在大同大禮部落度過四",
        //   // thumbNum: "20",
        //   // videoUrl: "https://youtu.be/cOoyPfGqpEc",
        // },
        // {
        //   videoId: "3",
        //   img: "program_main_03.jpg",
        //   tit: "Alang，我的名字",
        //   cont: "【第二季】第二季節目以萬榮鄉各村與瑞穗鄉富民村等部落地名內容為主。「Alang，我的名字」節目以全族語紀錄當代部落遷徙的故事，從部落語言觀察被殖民下的多樣性與複雜性，因同化政策，族語逐漸消失，為找",
        //   // thumbNum: "30",
        //   // videoUrl: "https://youtu.be/y8yH565s8kQ",
        // },
      ],
      //=== 節目分類 ===
      programTypeData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
      },
      programType: [],
      // [
      //   { ID: 1, Title: "兒童。青少年", isAct: true },
      //   { ID: 2, Title: "文化。藝術", isAct: false },
      //   { ID: 3, Title: "生活休閒", isAct: false },
      // { id: 4, txt: "紀錄片", isAct: false },
      // { id: 5, txt: "銀髮族服務", isAct: false },
      // { id: 6, txt: "戲劇。電影", isAct: false },
      // { id: 7, txt: "音樂。綜藝", isAct: false },
      // { id: 8, txt: "新聞。體育。時事", isAct: false },
      // ],
      //=== 節目列表 ===
      getProgramListData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      //=== 節目分類列表 ===
      ProgramBlurSwName: "programSubSwiper",
      ProgramBlurList: [],
      // ProgramBlurList: [
      //   {
      //     titCont: {
      //       img: "http://titv.ipcf.org.tw/upload_image/coverimg/2fa1bd18-f618-4fad-b72c-c51aedd58d73.jpg",
      //       tit: "A'iyalaeho - A",
      //       sup: "2021年4月起 隔週一 <br/>22:00~23:00 首播 - A",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202108/202108170200/202108170200.jpg",
      //         tit: "A'iyalaeho第 95 集 - A",
      //         cont: "'a'iyalaeho:開會了【看見百合花的高貴榮耀 魯凱族傳統頭飾植物文化】	",
      //       },
      //       {
      //         img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202108/16279645469034/16279645469034.jpg",
      //         tit: "A'iyalaeho第 94 集 - A",
      //         cont: "'a'iyalaeho:開會了【看見台灣因文化而偉大 原住民族日】	",
      //       },
      //       {
      //         img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16267529575321/16267529575321.jpg",
      //         tit: "A'iyalaeho第 93 集 - A",
      //         cont: "a'iyalaeho:開會了",
      //       },
      //       {
      //         img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg",
      //         tit: "A'iyalaeho第 92 集 - A",
      //         cont: "'a'iyalaeho:開會了【賽夏族師徒制】",
      //       },
      //       {
      //         img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202106/16244194817681/16244194817681.jpg",
      //         tit: "A'iyalaeho第 91 集 - A",
      //         cont: "a'iyalaeho:開會了",
      //       },
      //     ],
      //   },
      // ],
      //=== 需執行的節目SW ===
      //   /swFunctionList: ["programSubSwiper1"],
    };
  },
  mounted() {
    //=== 0115後台節目上方列表 ===
    this.getProgramTop();
    //=== 取得全部節目列表 ===
    // this.getAllProgramList();
    //=== 隨機主題Banner ===
    this.getProgramTopic();
    //=== 取得節目類別 ===
    apiGetCategorys(this.programTypeData)
      .then((result) => {
        console.log(result.data);
        var resStatuse = result.data.success;
        var resCode = result.data.errorcode;
        if (resStatuse == true && resCode == 0) {
          var resData = result.data.RetData;
          var reList = [];
          this.$.each(resData, (ind, val) => {
            var { ID, Title } = val;
            ind == 0
              ? reList.push({ ID: ID.toString(), Title: Title, isAct: true })
              : reList.push({ ID: ID.toString(), Title: Title, isAct: false });
          });
          this.programType = reList;
          //=== 第一次取得 節目列表 ===
          this.getProgramListData.CategoryID.push(reList[0].ID); //帶入第一個 新聞・體育・時事 的ID
          //原本是取得最上方sw列表內容，改為自訂選取廣告，但需要取得下方列表，是不是banner已無關。
          this.getProgramList(true);
          //=== 取得列表 ===
          // this.getProgramDetail("21081609193308851");
          // this.getProgramEpisode("21081609193308851");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //=== 取得類別節目列表 ===
    this.$bus.$on("toProgramTypeId", (data) => {
      console.log("點擊接收ID", data.typeId);
      this.getProgramListData.CategoryID = [data.typeId];
      //=== 取得節目列表 ===
      this.getProgramList(false);
    });
    //=== 取得節目集數 ===
    this.$bus.$on("toProgramEpisodeId", (data) => {
      console.log("點擊接收節目級數ID", data);
      //== 看是在第幾筆，要加入節目集數內容 ==
      var ind = this.ProgramBlurList.findIndex((item) => {
        return item.titCont.id == data; // 0
      });
      //== 看是否有集數有的話就不用再取一次 ==
      var listL = this.ProgramBlurList[ind].swList.length;
      if (!listL) {
        //== 取得集數列表 ==
        this.getProgramEpisodeList(data, 1);
      }
    });
    //更多集數載入
    this.$bus.$on("programSwPage", (data) => {
      // console.log("更多集數載入ID");
      // console.log("更多集數載入ID", data);
      // //== 取得集數更多列表 ==
      // if (data.isBanner) {
      //   this.getAllProgramEpisodeList(data.typeId, data.page);
      // } else {
      //   this.getProgramEpisodeList(data.typeId, data.page);
      // }
    });
    // //== swiper ==
    // var programVideo = new Swiper("#programVideo", {
    //   slidesPerView: "1",
    //   spaceBetween: 20,
    //   // freeMode: true,
    // });
    // programVideo.slideTo(1, 0);
    //== liveTypeVideo ==
    // var liveTypeVideo = new Swiper("#liveTypeVideo", {
    //   slidesPerView: "1",
    //   spaceBetween: 15,
    //   // freeMode: true,
    //   centeredSlides: true,
    // });
    // liveTypeVideo.slideTo(1, 0);
    // //== programTypeTab ==
    // var programTypeTab = new Swiper("#programTypeTab", {
    //   slidesPerView: "auto",
    //   // centeredSlides: true,
    //   freeMode: true,
    //   spaceBetween: 25,
    // });
    //=== 啟動swiper ===
    // this.$.each(this.swFunctionList, (ind, val) => {
    //   new Swiper("#" + val, {
    //     slidesPerView: "auto",
    //     spaceBetween: 15,
    //     freeMode: true,
    //   });
    // });
    // //=== 啟動後關閉 sub swiper ===
    // this.$.each(this.ProgramBlurList, (ind, val) => {
    //   val.swIsShow = false;
    // });
  },
  methods: {
    //=== 取得全部節目列表API (banner第一筆主題)===
    getAllProgramList() {
      apiGetProgramList(this.swProgramAllData)
        .then((result) => {
          console.log("節目'全'列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var bannerData = resData.shift();
            //第一筆banner ， 測試 - 21081518580777557
            this.getAllProgramDetail(bannerData.ID.toString(), true);
            // this.getAllProgramDetail("21081518580777557", true);
            //== 下方列表 ==
            // var list = [];
            // this.$.each(resData, (ind, val) => {
            //   // var { ID, Title, Img } = val;
            //   // list.push({
            //   //   videoId: ID.toString(),
            //   //   img: Img,
            //   //   tit: Title,
            //   //   cont: "",
            //   // });
            //   //=== 取得節目詳細資訊 ===
            //   this.getAllProgramDetail(val.ID.toString(), false);
            // });
            // this.SwProgramVideoList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 隨機主題 (banner隨機取得主題) ===
    getProgramTopic() {
      apiProgramTopic()
        .then((result) => {
          console.log("=== 隨機主題 ===");
          console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            console.log(resData);
            var { program_id } = resData;
            console.log(program_id);
            this.getAllProgramDetail(program_id, true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 節目詳細資料 ===
    getAllProgramDetail(id, isBanner) {
      apiGetProgramDetail({ ID: id })
        .then((result) => {
          console.log("節目'全'詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            if (isBanner) {
              this.mainProgramData.mainProgramId =
                resData.Program.ID.toString();
              this.mainProgramData.img = resData.Program.Img;
              this.mainProgramData.tit = resData.Program.Title;
              this.mainProgramData.cont = resData.Program.Introduction;
              //== 抓取級數列表 ==
              this.getAllProgramEpisodeList(resData.Program.ID.toString(), 1);
            } else {
              //== 放入列表 ==
              var { ID, Title, Img, Introduction } = resData.Program;
              this.SwProgramVideoList.push({
                videoId: ID.toString(),
                img: Img,
                tit: Title,
                cont: Introduction,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目集數 ===
    getAllProgramEpisodeList(id, page) {
      apiGetProgramEpisodeList({
        index: page,
        ProgramType: 0,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("節目'全'級數列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var { Page, TotalPage } = result.data;
            this.mainProgramData.page = Page;
            this.mainProgramData.pageTotal = TotalPage;
            var resData = result.data.RetData;
            //== 取得節目所有集數後，放入sw列表  ==
            this.$.each(resData, (ind, val) => {
              var { ID, Episode, Title, Image, m3u8Url } = val;
              this.SwProgramVideoList.push({
                id: ID.toString(),
                img: Image, //http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg
                tit: Episode, //"第" + Episode + "集"
                cont: Title,
                videoUrl: m3u8Url,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目列表ＡＰＩ ===
    getProgramList(bannerOnly) {
      apiGetProgramList(this.getProgramListData)
        .then((result) => {
          console.log("節目列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            // console.log(resData[0].ID);
            //== 如果是第一次讀列表 需要取得第一個類別節目列表在banner上(bannerOnly)==
            //=== 節目影音列表banner ===
            var bannerList = [];
            //=== 要先節目清空 ===
            this.ProgramBlurList = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title, Img } = val;
              if (bannerOnly) {
                bannerList.push({ id: ID.toString(), tit: Title, img: Img });
              }
              //=== 取得節目詳細資訊 ===
              this.getProgramDetail(ID.toString());
            });
            if (bannerOnly) {
              //=== 這裡是最上方sw列表 改為自訂節目 ===
              console.log(this.programVideoList);
              //**  this.programVideoList = bannerList;
            }
            //=== 取得節目詳細資訊 ===
            // this.getProgramDetail("21082311303063038");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 節目詳細資料 ===
    getProgramDetail(id) {
      apiGetProgramDetail({ ID: id })
        .then((result) => {
          console.log("節目詳細資料");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            var { Img, Title, PlayDuration, Introduction } = resData.Program;
            this.ProgramBlurList.push({
              titCont: {
                id: id,
                img: Img,
                tit: Title,
                sup: PlayDuration,
                cont: Introduction,
              },
              swIsShow: false, //true
              swPage: "", //加入當前頁碼
              swPageTotal: "",
              swList: [],
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目集數 ===
    getProgramEpisodeList(id, page) {
      apiGetProgramEpisodeList({
        index: page,
        ProgramType: 0,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("節目級數列表_Program");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            //== 取得節目所有集數後，放入sw列表  ==
            var ProgramBlurListInd = this.ProgramBlurList.findIndex((item) => {
              return item.titCont.id == id; // 0
            });
            // 給總頁數
            var { Page, TotalPage } = result.data;
            this.ProgramBlurList[ProgramBlurListInd].swPage = Page;
            this.ProgramBlurList[ProgramBlurListInd].swPageTotal = TotalPage;
            // 放入sw列表
            this.$.each(resData, (ind, val) => {
              var { ID, Episode, Title, Image, m3u8Url } = val;
              this.ProgramBlurList[ProgramBlurListInd].swList.push({
                id: ID.toString(),
                img: Image, //http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg
                tit: Episode, //"第" + Episode + "集"
                cont: Title,
                videoUrl: m3u8Url,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 0115後台節目上方列表 ===
    getProgramTop() {
      apiProgramTop()
        .then((result) => {
          console.log("節目上方影片列表");
          console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            var dataList = [];
            this.$.each(resData, (ind, val) => {
              var { id, title, program_title, img, m3u8_url } = val;
              dataList.push({
                id,
                img,
                tit: program_title,
                sub: title,
                videoLink: m3u8_url,
                swType: "program",
              });
            });
            this.programVideoList = dataList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    //=== 抓取需要跑 swiper 的 Id ===
    // swFunctionList() {
    //   var list = [];
    //   this.$.each(this.ProgramBlurList, (ind, val) => {
    //     list.push(this.ProgramBlurSwName + (ind + 1));
    //   });
    //   console.log("swFunctionList");
    //   return list;
    // },
  },
  //== 元件銷毀前要取消監聽 ==<br />
  beforeDestroy() {
    this.$bus.$off("toProgramTypeId");
    this.$bus.$off("toProgramEpisodeId");
    this.$bus.$off("programSwPage");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    // ProgramVideo,
    // ProgramBannerVideo,
    TopVideoSw,
    ProgramBanner,
    ProgramBlurSw,
    // ProgramBannerSw,
    TypeTabSw,
    ProgramBlurArea,
    VideoLightBox,
    VideoYTLightBox,
  },
};
</script>
<style>
.tit-blur-box iframe {
  width: 100px;
  height: 50px;
}
.program-video {
  margin: 0 auto 20px;
}
</style>