<template>
  <div class="swiper-slide">
    <router-link to="/onair" class="close">
      <img src="@/assets/img/icon_news_close.svg" alt="" />
    </router-link>
    <!-- <a
      :href="videoId"
      target="_blank"
      class="news-banner"
      :style="{
        background:
          'url(' +
          require('@/assets/img/' + imgName) +
          ') no-repeat top center/100%',
      }"
    > -->
    <a
      :href="videoId"
      target="_blank"
      class="news-banner"
      :style="{ background: 'url(' + imgName + ') no-repeat top center/100%' }"
    >
      <!-- 10/27 本來點了會跳影片，先改成連結外連 @click="openVideo(videoId)" -->
      <!-- <div
      class="news-banner"
      :style="{
        background:
          'url(' +
          require('@/assets/img/' + imgName) +
          ') no-repeat top center',
        backgroundSize: '100%',
      }"
    > -->

      <div class="news-banner-info">
        <div class="tab-box">
          <div class="tab tab-on" v-if="tabType == 'news'">NEW</div>
          <div class="tab tab-tv" v-if="tabType == 'program'">TITV</div>
          <div class="tab tab-alian" v-if="tabType == 'alian'">Alian</div>
        </div>
        <div class="news-banner-cont scroll-w">
          <div class="tit">{{ tit }}</div>
          <div class="cont" v-html="txt"></div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "SlideNew",
  props: ["videoId", "tabType", "imgName", "tit", "txt"],
  mounted() {
    //=== scroll bar ===
    // window.$(".scroll-w").mCustomScrollbar();
  },
  methods: {
    openVideo(id) {
      console.log(id);
      //開YTlightbox
      // this.$bus.$emit("swOpenYTVideo", this.videoUrl);
    },
  },
};
</script>