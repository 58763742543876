<template>
  <div class="live-type-tab-area">
    <div class="swiper-container live-type-tab" :id="swId">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in getTabList" :key="item.ID">
          <div
            class="type-tab"
            :class="item.isAct ? 'act' : ''"
            @click="item.isAct ? '' : changeTypeData(item.ID)"
          >
            {{ item.Title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

export default {
  name: "TypeTabSw",
  props: ["swId", "tabList", "tabType"], //programTypeTab
  data() {
    return {
      typeSw: "",
    };
  },
  mounted() {
    //=== 需要有內容才會出現才讓他建立出來跑Swiper(data會先被傳入就有資料，就可以跑Swiper) ===
    console.log("== swType mounted ==");
    //== programTypeTab ==
    this.typeSw = new Swiper("#" + this.swId, {
      slidesPerView: "auto",
      // centeredSlides: true,
      freeMode: true,
      spaceBetween: 25,
    });
  },
  methods: {
    //=== 切換節目分類 ===
    changeTypeData(id) {
      this.$.each(this.getTabList, (index, val) => {
        val.isAct = false;
        if (val.ID == id) {
          val.isAct = true;
        }
      });
      var sendData = {
        type: this.tabType,
        typeId: id,
      };
      this.$bus.$emit("toProgramTypeId", sendData);
      //=== 清除以點選的下方方塊檔案連結 ===
      this.$bus.$emit("toCleanUrl", true);

      //   if (id == 1) {
      //     console.log("兒童青少年");
      //     this.ProgramBlurList = [
      //       {
      //         titCont: {
      //           img: "http://titv.ipcf.org.tw/upload_image/coverimg/2fa1bd18-f618-4fad-b72c-c51aedd58d73.jpg",
      //           tit: "A'iyalaeho - A",
      //           sup: "2021年4月起 隔週一 <br/>22:00~23:00 首播 - A",
      //         },
      //         swIsShow: true,
      //         swList: [
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202108/202108170200/202108170200.jpg",
      //             tit: "A'iyalaeho第 95 集 - A",
      //             cont: "'a'iyalaeho:開會了【看見百合花的高貴榮耀 魯凱族傳統頭飾植物文化】	",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202108/16279645469034/16279645469034.jpg",
      //             tit: "A'iyalaeho第 94 集 - A",
      //             cont: "'a'iyalaeho:開會了【看見台灣因文化而偉大 原住民族日】	",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16267529575321/16267529575321.jpg",
      //             tit: "A'iyalaeho第 93 集 - A",
      //             cont: "a'iyalaeho:開會了",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg",
      //             tit: "A'iyalaeho第 92 集 - A",
      //             cont: "'a'iyalaeho:開會了【賽夏族師徒制】",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202106/16244194817681/16244194817681.jpg",
      //             tit: "A'iyalaeho第 91 集 - A",
      //             cont: "a'iyalaeho:開會了",
      //           },
      //         ],
      //       },
      //       // {
      //       //   titCont: {
      //       //     img: "http://titv.ipcf.org.tw/upload_image/coverimg/a6135cfb-b486-4495-9848-4e0999944f20.jpg",
      //       //     tit: "PULIMA - A",
      //       //     sup: "2017-03-01 每週三<br/>21：00~22：00 首播 - A",
      //       //   },
      //       //   swIsShow: true,
      //       //   swList: [
      //       //     {
      //       //       img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704122100/201704122100.jpg",
      //       //       tit: "PULIMA第 8 集 - A",
      //       //       cont: "Tminum生命的編織者",
      //       //     },
      //       //     {
      //       //       img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201703082100/201703082100.jpg",
      //       //       tit: "PULIMA第 7 集 - A",
      //       //       cont: "生命印記",
      //       //     },
      //       //     {
      //       //       img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704052100/201704052100.jpg",
      //       //       tit: "PULIMA第 6 集 - A",
      //       //       cont: "無畏世代",
      //       //     },
      //       //     {
      //       //       img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201703152100/201703152100.jpg",
      //       //       tit: "PULIMA第 5 集 - A",
      //       //       cont: "守護者的高度",
      //       //     },
      //       //     {
      //       //       img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704192100/201704192100.jpg",
      //       //       tit: "PULIMA第 4 集 - A",
      //       //       cont: "另類空間",
      //       //     },
      //       //   ],
      //       // },
      //     ];
      //   } else if (id == 2) {
      //     console.log("文化藝術");
      //     this.ProgramBlurList = [
      //       {
      //         titCont: {
      //           img: "http://titv.ipcf.org.tw/upload_image/coverimg/d007372f-444d-41d3-85f4-c5cf7c452132.jpg",
      //           tit: "祭得serangawan - B",
      //           sup: "週日 22:00-23:00",
      //         },
      //         swIsShow: true,
      //         swList: [
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201910/201910132200/201910132200.jpg",
      //             tit: "祭得serangawan第 8 集 - B",
      //             cont: "祭得seranagwan【金峰小米向前衝】",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201910/201910062200/201910062200.jpg",
      //             tit: "祭得serangawan第 7 集 - B",
      //             cont: "祭得seranagwan【眉溪蝴蝶飛】",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201909/201909292200/201909292200.jpg",
      //             tit: "祭得serangawan第 6 集 - B",
      //             cont: "祭得seranagwan【大社芋頭窯】",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201909/201909222200/201909222200.jpg",
      //             tit: "祭得serangawan第 5 集 - B",
      //             cont: "祭得seranagwan【福爾摩沙。臺灣】",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201909/201909152200/201909152200.jpg",
      //             tit: "祭得serangawan第 4 集 - B",
      //             cont: "祭得seranagwan【土坂。五年祭】",
      //           },
      //         ],
      //       },
      //       {
      //         titCont: {
      //           img: "http://titv.ipcf.org.tw/upload_image/coverimg/a6135cfb-b486-4495-9848-4e0999944f20.jpg",
      //           tit: "PULIMA - B",
      //           sup: "2017-03-01 每週三<br/>21：00~22：00 首播",
      //         },
      //         swIsShow: true,
      //         swList: [
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704122100/201704122100.jpg",
      //             tit: "PULIMA第 8 集 - B",
      //             cont: "Tminum生命的編織者",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201703082100/201703082100.jpg",
      //             tit: "PULIMA第 7 集 - B",
      //             cont: "生命印記",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704052100/201704052100.jpg",
      //             tit: "PULIMA第 6 集 - B",
      //             cont: "無畏世代",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201703152100/201703152100.jpg",
      //             tit: "PULIMA第 5 集 - B",
      //             cont: "守護者的高度",
      //           },
      //           {
      //             img: "http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/import_201810/201704192100/201704192100.jpg",
      //             tit: "PULIMA第 4 集 - B",
      //             cont: "另類空間",
      //           },
      //         ],
      //       },
      //     ];
      //   } else if (id == 3) {
      //     console.log("生活休閒");
      //     this.ProgramBlurList = [];
      //   }
    },
  },
  computed: {
    getTabList: {
      get() {
        return this.tabList;
      },
      set(val) {
        this.$emit("update:tabList", val);
      },
    },
  },
  watch: {
    // getTabList(newVal) {
    //   console.log("== watch ==");
    //   this.typeSw.updateSize();
    // },
  },
};
</script>