<template>
  <div class="swiper-slide">
    <div class="live-type-video-box aaa">
      <router-link
        :to="{ name: 'NewsCont', params: { id: ID }, query: { type: typeId } }"
      >
        <div class="live-type-video-img">
          <!-- 加play按鈕 video-play -->
          <!--@click="openVideo"-->
          <img :src="Image" alt="" />
        </div>
        <div class="live-type-video-info">
          <div class="tit-box">
            <div class="tit">{{ Title }}</div>
            <!-- <div>{{img}}</div> -->
            <div class="date-box">{{ CustomCreateTime }}</div>
            <div class="journalist">{{ Journalist }}</div>
            <div style="display: none">{{ ID }}</div>
            <div style="display: none">{{m3u8Url}}</div>
            <!-- <div class="icon-box"> -->
            <!-- <div class="num">{{ thumbNum }}</div> -->
            <!-- <div class="icon">
                <img src="@/assets/img/icon_thumb_b.svg" alt="" />
              </div> -->
            <!-- <div class="icon">
                <img src="@/assets/img/icon_heart_r.svg" alt="" />
              </div> -->
            <!-- </div> -->
          </div>
          <div class="cont" style="display: none">{{ Content }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  // name: "SwLiveVideo",
  props: [
    "Title",
    "m3u8Url",
    "Content",
    "CustomCreateTime",
    "Journalist",
    "ID",
    "typeId",
    "Image",
  ],
  methods: {
    //=== 點選 ===
    //== 傳送項目 ==
    // openVideo() {
    //   this.$bus.$emit("swOpenVideo", this.tit);
    // },
  },
};
</script>