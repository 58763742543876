<template>
  <div class="lg-note-area" id="lgNote">
    <div class="lg-note-wrap">
      <div class="lg-note-box">
        <p>-</p>
      </div>
      <div class="btn-box">
        <div class="btn-w w-btn" id="lgNoteClose">確定</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LgNote",
};
</script>