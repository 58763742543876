<template>
  <div>
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
        <router-link to="/" class="nav-btn">
          <img src="@/assets/img/icon_home.svg" alt="" />
        </router-link>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="logo"><img src="@/assets/img/logo.svg" alt="" /></div>
        <div class="login-icon-area" style="display:none;">
          <div class="txt">使用</div>
          <div class="login-icon-box">
            <div class="icon" @click="FBLogin">
              <img src="@/assets/img/icon_c_fb.svg" alt="" />
            </div>
            <div class="icon google-icon">
              <img src="@/assets/img/icon_c_google.svg" alt="" />
              <GoogleLogin
                :params="params"
                :onSuccess="onSuccess"
                :onFailure="onFailure"
              ></GoogleLogin>
            </div>
            <div class="icon" v-if="false">
              <img src="@/assets/img/icon_c_apple.svg" alt="" />
            </div>
          </div>
          <div class="txt">或</div>
        </div>
        <div class="login-area">
          <div class="txt">使用帳號密碼登入</div>
          <form id="form">
            <div class="input-area">
              <div class="input-box">
                <input
                  class="input-sty required"
                  id="acc"
                  type="text"
                  name="acc"
                  placeholder="請輸入帳號"
                  v-model="loginData.Account"
                />
              </div>
              <div class="input-box">
                <input
                  class="input-sty required"
                  id="pwd"
                  type="password"
                  name="pwd"
                  placeholder="請輸入密碼"
                  v-model="loginData.HashPasswd"
                />
              </div>
              <div class="login-note" v-if="loginNote">
                <span>{{ loginNoteTxt }}</span>
              </div>
              <div class="txt-right">
                <router-link to="/forgetPwd">忘記密碼？</router-link>
              </div>
              <div class="btn-area">
                <div class="btn-w w-btn" @click="submitBtn">登入</div>
                <router-link to="/register" class="btn-w blue-btn"
                  >註冊新帳號</router-link
                >
                <!-- <a class="btn-w blue-btn" href="register.html">註冊新帳號</a> -->
              </div>
            </div>
          </form>
        </div>
        <div class="login-footer-txt">
          財團法人原住民族文化事業基金會 版權所有<br />
          Copyright © 2021 Indigenous Peoples Cultural Foundation All Rights
          Reserved.<br />
          電話：(02)2788-1600 傳真：(02)2788-1500 地址：台北市南港區重陽路 120
          號 5 樓
        </div>
      </div>
    </div>
    <div class="header-bg"></div>
    <div class="footer-bg"></div>
  </div>
</template>
<script>
import GoogleLogin from "vue-google-login";

import { apiLogin, apiLoginThree } from "@/api/api.js";

export default {
  data() {
    return {
      loginNote: false,
      loginNoteTxt: " - ",
      loginData: {
        Account: "",
        HashPasswd: "",
      },
      //=== 社群登入 ===
      loginThreeData: {
        MemberType: 0, //1:google, 2:appleId, 3:facebook
        userID: "",
      },
      //=== google ID和樣式 ===
      params: {
        client_id:
          "738963824675-ushi8snc0dtvvko7kme0kiqoik63v1lg.apps.googleusercontent.com",
      },
      // renderParams: {
      //   width: 250,
      //   height: 50,
      //   theme: "dark",
      //   longtitle: true,
      // },
    };
  },
  mounted() {
    // var CryptoJS = require("crypto-js");
    // 密鑰: veajv9vc63ak9sw3cdfk3da9
    // iv: KidxIpcf
    // var data = "hello";
    // let key = "veajv9vc63ak9sw3cdfk3da9";
    // let IV = "KidxIpcf";
    // // let key = "1234567890ABCDEFGHIJKLMN";
    // // let IV = "12345678";
    // var keyHex = CryptoJS.enc.Utf8.parse(key);
    // var ivHex = CryptoJS.enc.Utf8.parse(IV);
    // //== 加密 ==
    // let encrypted = CryptoJS.TripleDES.encrypt(data, keyHex, {
    //   iv: ivHex,
    //   mode: CryptoJS.mode.CBC,
    //   // padding: CryptoJS.pad.Pkcs7, //填充方式
    // });
    // var enStrData = encrypted.toString();
    // console.log("加密=：", enStrData);
    // //== 解密 ==
    // var decrypted = CryptoJS.TripleDES.decrypt(
    //   {
    //     ciphertext: CryptoJS.enc.Base64.parse(enStrData),
    //   },
    //   keyHex,
    //   {
    //     iv: ivHex,
    //     mode: CryptoJS.mode.CBC,
    //     // padding: CryptoJS.pad.Pkcs7, //java好像會報錯
    //   }
    // );
    // var deStrData = decrypted.toString(CryptoJS.enc.Utf8);
    // // decrypted.toString(CryptoJS.enc.Utf8);
    // console.log("解密=：", deStrData);
    //=== FB登入 ===
    // let FB_appID = this.thirdParty.Facebook.appID;
    // let FB_appID = "925741248026832";
    // window.fbAsyncInit = () => {
    //   FB.init({
    //     appId: FB_appID, //"{your-app-id}"
    //     cookie: true,
    //     xfbml: true,
    //     version: "v12.0", //"{api-version}"
    //   });
    //   FB.AppEvents.logPageView();
    // };
    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
    //=== 表單送出 ===
    // this.$("#submitBtn").on("click", () => {
    //   var _form = window.$("#form").valid();
    //   if (_form) {
    //     // this.$router.push("/Sum");
    //   }
    // });
    //=== 如果為登入狀態 到首頁 ===
    if (this.userInfo.userToken !== "") {
      this.$router.push("/");
    }
  },
  methods: {
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
    submitBtn() {
      var _form = window.$("#form").valid();
      if (_form) {
        this.loginNote = false;
        //=== 登入 ===
        this.login();
        // this.$router.push("/Sum");
      }
    },
    //=== 加密 ===
    toEncrypted(txt) {
      var CryptoJS = require("crypto-js");
      let key = "veajv9vc63ak9sw3cdfk3da9";
      let IV = "KidxIpcf";
      // let key = "1234567890ABCDEFGHIJKLMN";
      // let IV = "12345678";
      var keyHex = CryptoJS.enc.Utf8.parse(key);
      var ivHex = CryptoJS.enc.Utf8.parse(IV);
      //== 加密 ==
      let encrypted = CryptoJS.TripleDES.encrypt(txt, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        // padding: CryptoJS.pad.Pkcs7, //填充方式
      });
      var enStrData = encrypted.toString();
      // console.log("加密=：", enStrData);
      return enStrData;
    },
    //=== 登入 ===this.loginData
    // {
    //     Account: this.loginData.Account,
    //     HashPasswd: this.toEncrypted(this.loginData.HashPasswd),
    //   }
    login() {
      apiLogin({
        Account: this.loginData.Account,
        HashPasswd: this.toEncrypted(this.loginData.HashPasswd),
      })
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          var resMessage = result.data.message;
          if (resStatuse == true && resCode == 0) {
            console.log("登入成功！");
            var resData = result.data.RetData;
            var {
              Account,
              BirthDay,
              Clan,
              Email,
              Gender,
              ID,
              MemberToken,
              MemberType,
              Name,
              Phone,
              Profile,
            } = resData;
            var chageData = {
              userId: ID.toString(),
              userToken: MemberToken,
              userType: MemberType,
              userImg: Profile,
              name: Name,
              acc: Account,
              clan: Clan,
              birth: BirthDay,
              Gender: Gender,
              mail: Email,
              phone: Phone,
            };
            this.$store.commit("SETUSERINFO", chageData);
            this.$router.push("/account");
            // this.clanList = result.data.RetData;
          } else if (resStatuse == false && resCode == null) {
            // 帳號或密碼錯誤;
            this.loginNote = true;
            this.loginNoteTxt = resMessage;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== FB登入 ===
    FBLogin() {
      window.FB.getLoginStatus((res) => {
        console.log(`status:${res.status}`); //Debug
        if (res.status === "connected") {
          let userID = res["authResponse"]["userID"];
          console.log(
            "用戶已授權您的App，用戶須先revoke撤除App後才能再重新授權你的App"
          );
          //console.log(`已授權App登入FB 的 userID:${userID}`);
          var accessToken = res.authResponse.accessToken;
          console.log("登入的token:", accessToken);
          //console.log("res:", res);
          this.GetFBProfile();
        } else if (
          res.status === "not_authorized" ||
          res.status === "unknown"
        ) {
          //console.log(`res.status是:${res.status}`); //Debug
          //App未授權或用戶登出FB網站才讓用戶執行登入動作
          window.FB.login(
            (response) => {
              //console.log(response); //debug用
              if (response.status === "connected") {
                //user已登入FB
                //抓userID
                let userID = response["authResponse"]["userID"];
                console.log(`已授權App登入FB 的 userID:${userID}`);
                this.GetFBProfile();
              } else {
                // user FB取消授權
                alert("Facebook帳號無法登入");
              }
              //"public_profile"可省略，仍然可以取得name、userID
            },
            { scope: "public_profile, email" }
          );
        }
        //取得用戶姓名、email
        //this.GetFBProfile();
      });
    },
    //取得用戶姓名、email
    GetFBProfile() {
      //取得用戶個資
      window.FB.api(
        "/me",
        "GET",
        { fields: "id,last_name,first_name,name,email,picture" },
        (user) => {
          //user物件的欄位：https://developers.facebook.com/docs/graph-api/reference/user
          if (user.error) {
            console.log("user.error？", user.error);
            //console.log('出現錯誤？',response);
          } else {
            console.log(user);
            var { id, name, email, picture } = user;
            this.loginThreeData.MemberType = 3;
            this.loginThreeData.userID = id;
            this.loginThree();
            // this.registerData.MemberType = 3;
            // this.registerData.SocialData = { userID: id };
            // this.registerData.MemberShipSignUpInfo.MemberType = 3;
            // this.registerData.MemberShipSignUpInfo.Account = email;
            // this.registerData.MemberShipSignUpInfo.Password = null;
            // this.registerData.MemberShipSignUpInfo.PasswordAgain = null;
            // this.registerData.MemberShipSignUpInfo.Name = name;
            // email == undefined
            //   ? (this.registerData.MemberShipSignUpInfo.Email = "")
            //   : (this.registerData.MemberShipSignUpInfo.Email = email);
            // if (picture != undefined) {
            //   this.threePartImg = picture.data.url;
            //   // this.registerData.MemberShipSignUpInfo.Profile = picture.data.url;
            //   this.threePartIcon = "acc-icon-fb";
            // }
          }
        }
      );
    },
    //=== google 功能 ===
    onSuccess(googleUser) {
      console.log(googleUser);
      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
      //Re - 全名,Tt - 信箱,lK - 頭像,sT - ID
      var { Re, Tt, lK, sT, uT } = googleUser.getBasicProfile();
      this.loginThreeData.MemberType = 1;
      this.loginThreeData.userID = uT;
      this.loginThree();
      // this.regTit = "Google註冊";
      // this.norUse = false;
      // this.registerData.MemberType = 1;
      // this.registerData.SocialData = { userID: sT };
      // this.registerData.MemberShipSignUpInfo.MemberType = 1;
      // this.registerData.MemberShipSignUpInfo.Account = null;
      // this.registerData.MemberShipSignUpInfo.Password = null;
      // this.registerData.MemberShipSignUpInfo.PasswordAgain = null;
      // this.registerData.MemberShipSignUpInfo.Name = Re;
      // this.registerData.MemberShipSignUpInfo.Email = Tt;
      // if (lK != undefined) {
      //   this.threePartImg = lK;
      //   // this.registerData.MemberShipSignUpInfo.Profile = picture.data.url;
      //   this.threePartIcon = "acc-icon-google";
      // }
    },
    onFailure(error) {
      console.log(error);
    },
    //=== 第三方登入 ===
    loginThree() {
      apiLoginThree(this.loginThreeData)
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          var resMessage = result.data.message;
          if (resStatuse == true && resCode == 0) {
            console.log("第三方登入成功！");
            var resData = result.data.RetData;
            var {
              BirthDay,
              Clan,
              Email,
              Gender,
              ID,
              MemberToken,
              MemberType,
              Name,
              Phone,
              Profile,
              Expire,
            } = resData;
            var chageData = {
              userId: ID.toString(),
              userToken: MemberToken,
              userType: MemberType,
              userImg: Profile,
              name: Name,
              acc: "", //第三方登入不會有值
              clan: Clan,
              birth: BirthDay,
              Gender: Gender,
              mail: Email,
              phone: Phone,
            };
            this.$store.commit("SETUSERINFO", chageData);
            this.$router.push("/account");
            // this.clanList = result.data.RetData;
          } else if (resStatuse == false && resCode == null) {
            // 帳號或密碼錯誤;
            this.loginNote = true;
            this.loginNoteTxt = resMessage;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // loginThreeData.MemberType=1
  },
  computed: {
    //=== 取的使用者資訊 ===
    userInfo: {
      get() {
        // return this.$store.getters["userInfoGet"];
        return this.$store.state.userInfo;
      },
      set(val) {
        // this.$store.dispatch("GETTOUSERINFO", val);
        this.$store.commit("SETUSERINFO", val);
      },
    },
  },
  components: {
    GoogleLogin,
  },
};

// FB Login code，可搭配pdf觀看

// FB app id: 925741248026832

// ＜script＞
//   window.fbAsyncInit = function() {
//     FB.init({
//       appId      : '{your-app-id}',
//       cookie     : true,
//       xfbml      : true,
//       version    : '{api-version}'
//     });

//     FB.AppEvents.logPageView();

//   };

//   (function(d, s, id){
//     var js, fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) {return;}
//     js = d.createElement(s); js.id = id;
//     js.src = "https://connect.facebook.net/en_US/sdk.js";
//     fjs.parentNode.insertBefore(js, fjs);
//   }(document, 'script', 'facebook-jssdk'));
// ＜/script＞

// 以下擷取自上述程式碼範例，為在頁面載入時用來檢查用戶登入狀態所執行的部分程式碼：

// FB.getLoginStatus(function(response) {
//     statusChangeCallback(response);
// });

// 提供給回呼的 response 物件含有數個欄位：
// {
//     status: 'connected',
//     authResponse: {
//         accessToken: '...',
//         expiresIn:'...',
//         signedRequest:'...',
//         userID:'...'
//     }
// }

// 按鈕上的 onlogin 屬性是用於設定檢查登入狀態的 JavaScript 回呼，以瞭解用戶是否已經成功登入：
// <fb:login-button
//   scope="public_profile,email"
//   onlogin="checkLoginState();">
// </fb:login-button>

// 就是這個回呼，它會呼叫 FB.getLoginStatus() 來取得最新的登入狀態（範例中處理這個回應的是 statusChangeCallback() 函式）。
// function checkLoginState() {
//   FB.getLoginStatus(function(response) {
//     statusChangeCallback(response);
//   });
// }
</script>
<style>
.google-icon {
  position: relative;
}
.google-icon button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}
</style>