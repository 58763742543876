<template>
  <div class="news-program-group">
    <!-- @click="openVideo"-->
    <div class="news-program-cover" @click="swShowStatus = !swShowStatus">
      <img :src="require('@/assets/img/' + img)" alt="" />
      <a :href="fb" class="fb-page" v-if="fb" target="_blank">
        <img src="@/assets/img/icon_fb_b.svg" alt="">
      </a>
      <div class="news-program-info-blur">
        <h3>{{ tit }}</h3>
        <p>{{ desc }}</p>
      </div>
    </div>
    <transition name="slide">
      <div class="news-program-sub-list" v-show="swShowStatus">
        <div class="swiper-container" :id="swName + (ind + 1)">
          <div class="swiper-wrapper">
            <NewsProgramBox
              v-for="(item, index) in episodeList"
              :key="index"
              :episodeImg="item.episodeImg"
              :episodeTit="item.episodeTit"
              :videoId="item.videoId"
            />
          </div>
        </div>
      </div>
    </transition>
    <!-- <div class="news-program-sub-list">
      <div class="news-episodes-scroll" style="width: 2000px">
        <a href="#">
          <div class="episodes-cover">
            <img :src="require('@/assets/img/'+img)" alt="">
          </div>
          <div class="episodes-name">第 89 集</div>
        </a>
        <a href="#">
          <div class="episodes-cover">
            <img :src="require('@/assets/img/'+img)" alt="">
          </div>
          <div class="episodes">第 88 集</div>
        </a>
        <a href="#">
          <div class="episodes-cover">
            <img :src="require('@/assets/img/'+img)" alt="">
          </div>
          <div class="episodes">第 87 集</div>
        </a>
        <a href="#">
          <div class="episodes-cover">
            <img :src="require('@/assets/img/'+img)" alt="">
          </div>
          <div class="episodes">第 86 集</div>
        </a>
        <a href="#">
          <div class="episodes-cover">
            <img :src="require('@/assets/img/'+img)" alt="">
          </div>
          <div class="episodes">第 85 集</div>
        </a>
      </div>
      
    </div> -->
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import NewsProgramBox from "@/components/NewsProgramBox.vue";
export default {
  name: "NewsProgramList",
  props: [
    // "id",
    "ind",
    "swName",
    "swIsShow",
    "img",
    "tit",
    "fb",
    "desc",
    "episodeList",
  ],
  methods: {
    //== 傳送項目 ==
    // openVideo() {
    //   this.$bus.$emit("swOpenVideo", this.tit);
    // },
  },
  mounted() {
    // console.log(this.id);
    //=== 啟動swiper ===
    var swName = this.swName + (this.ind + 1);
    new Swiper("#" + swName, {
      slidesPerView: "auto",
      spaceBetween: 15,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    this.swShowStatus = false;
  },
  computed: {
    swShowStatus: {
      get() {
        return this.swIsShow;
      },
      set(val) {
        this.$emit("update:swIsShow", val);
      },
    },
  },
  //如果有外部更換
  // updated() {
  //   console.log("RRRRR");
  //   console.log(this.id);
  // },
  components: {
    NewsProgramBox,
  },
};
</script>
<style>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: ease-in; */
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
}
.slide-enter-to,
.slide-leave {
  max-height: 135px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>