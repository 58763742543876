<template>
  <div>
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="meteorology-wrap">
          <div class="cricle"></div>
          <div class="line-tit">所在地區天氣</div>
          <div class="meteorology-info-box">
            <div class="info-left">
              <!-- <div class="area">
                {{ weatherData.city }} {{ weatherData.location }}
              </div> -->
              <div class="data" id="dateTime">{{ dateTime }}</div>
              <!-- <div class="desc" id="wx">{{ weatherData.wx }}</div> -->
            </div>
            <div class="meteorology-box two-sel">
              <select
                class="acc-sel-sty"
                id="localCity"
                name="localCity"
                v-model="citySelected"
                @change="cityOnChagne(citySelected, zoneSelected)"
              >
                <!-- <option value="F-D0047-001">宜蘭縣</option>
                <option value="F-D0047-041">花蓮縣</option>
                <option value="F-D0047-037">臺東縣</option>
                <option value="F-D0047-045">澎湖縣</option>
                <option value="F-D0047-087">金門縣</option>
                <option value="F-D0047-081">連江縣</option>
                <option value="F-D0047-069" selected>臺北市</option>
                <option value="F-D0047-069">新北市</option>
                <option value="F-D0047-005">桃園市</option>
                <option value="F-D0047-073">臺中市</option>
                <option value="F-D0047-077">臺南市</option>
                <option value="F-D0047-065">高雄市</option>
                <option value="F-D0047-049">基隆市</option>
                <option value="F-D0047-009">新竹縣</option>
                <option value="F-D0047-053">新竹市</option>
                <option value="F-D0047-013">苗栗縣</option>
                <option value="F-D0047-017">彰化縣</option>
                <option value="F-D0047-021">南投縣</option>
                <option value="F-D0047-025">雲林縣</option>
                <option value="F-D0047-029">嘉義縣</option>
                <option value="F-D0047-057">嘉義市</option>
                <option value="F-D0047-035">屏東縣</option> -->
                <option
                  v-for="item in citySelect"
                  :key="item.ID"
                  :value="item.val"
                >
                  {{ item.name }}
                </option>
              </select>

              <select
                class="acc-sel-sty"
                id="localZone"
                name="localZone"
                v-model="zoneSelected"
                @change="zoneOnChagne(citySelected)"
              >
                <option v-for="item in getZoneList" :key="item" :value="item">
                  {{ item }}
                </option>
                <!-- <option value="北投區">北投區</option>
                <option value="士林區">士林區</option>
                <option value="內湖區">內湖區</option>
                <option value="中山區">中山區</option>
                <option value="大同區">大同區</option>
                <option value="松山區">松山區</option>
                <option value="南港區" selected>南港區</option>
                <option value="中正區">中正區</option>
                <option value="萬華區">萬華區</option>
                <option value="信義區">信義區</option>
                <option value="大安區">大安區</option>
                <option value="文山區">文山區</option> -->
              </select>
            </div>

            <!-- <div>城市 = {{citySelected}}</div>
            <div>地區 = {{getZoneList}}</div> -->
            <!-- <div>ttest = {{ttt}}</div> -->
            <div class="weather-desc" id="weatherDesc">
              {{ weatherData.weatherDesc }}
            </div>
            <div class="info-right" style="display: none">
              <div class="info-1">
                降雨機率：<span id="pop">{{ weatherData.pop }}%</span>
              </div>
              <div class="info-2">
                濕度：<span id="rh">{{ weatherData.rh }}%</span>
              </div>
              <div class="info-3">
                風速：<span id="ws"
                  >{{ weatherData.ws }}{{ weatherData.wsMeas }}</span
                >
              </div>
              <div class="info-4">
                體感溫度：<span id="at">{{ weatherData.at }}°C</span>
              </div>
            </div>
          </div>

          <div class="weather-info">
            <div class="icon-weather">
              <img :src="weatherData.iconWeather" alt="" id="iconWeather" />
            </div>
            <div class="weather-t">
              <div class="temp-symbol">°C</div>
              <span id="temp">{{ weatherData.temp }}</span>
            </div>
          </div>
          <div class="meteorology-box">
            <select
              class="acc-sel-sty"
              v-model="ethnicSelected"
              @change="ethnicOnChange()"
            >
              <option
                v-for="item in groupList"
                :key="item.ID"
                :value="item.val"
              >
                {{ item.name }}
              </option>
              <!-- <option value="ethnic-1">卑南族</option>
              <option value="ethnic-2">噶瑪蘭族</option>
              <option value="ethnic-3">太魯閣族</option>
              <option value="ethnic-4">布農族</option>
              <option value="ethnic-5">排灣族</option>
              <option value="ethnic-6">撒奇萊雅族</option>
              <option value="ethnic-7">泰雅族</option>
              <option value="ethnic-8">賽夏族</option>
              <option value="ethnic-9">賽德克族</option>
              <option value="ethnic-10">邵族</option>
              <option value="ethnic-11">鄒族</option>
              <option value="ethnic-12">阿美族</option>
              <option value="ethnic-13">雅美族(達悟族)</option>
              <option value="ethnic-14">魯凱族</option>
              <option value="ethnic-15">拉阿魯哇族</option>
              <option value="ethnic-16">卡那卡那富族</option> -->
            </select>

            <select
              class="acc-sel-sty"
              v-model="ethnicCitySelected"
              @change="ethnicCityOnChange()"
            >
              <option
                v-for="item in getEthnicCityList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.name }}
              </option>
            </select>

            <select
              class="acc-sel-sty"
              v-model="ethnicZoneSelected"
              @change="ethnicZoneOnChange()"
            >
              <option
                v-for="item in getEthnicZoneList"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <!-- <div style="color: #fff">{{ ethnicSelected }}</div>
          <div style="color: #fff">{{ ethnicCitySelected }}</div> -->
          <div class="weather-event-box">
            <div>
              <h3 id="weatherEventTitle">{{ weatherEvent.title }}</h3>
              <p>
                <span id="weatherEventData">{{ weatherEvent.date }}</span>
                <span id="weatherEventCont">{{ weatherEvent.cont }}</span>
              </p>
            </div>
          </div>
          <div class="api-icon-group">
            <a href="https://www.cwb.gov.tw/V8/C/" target="_blank">
              <img :src="require('@/assets/img/api_01.svg')" alt="" />
              <p>中央氣象局</p>
            </a>
            <a href="https://246.swcb.gov.tw/Info/QA" target="_blank">
              <img :src="require('@/assets/img/api_02.svg')" alt="" />
              <p>土石流查詢</p>
            </a>
            <a href="https://www.cwb.gov.tw/V8/C/M/NSea.html" target="_blank">
              <img :src="require('@/assets/img/api_03.svg')" alt="" />
              <p>近海潮汐查詢</p>
            </a>
            <a
              href="https://www.cwb.gov.tw/V8/C/P/Warning/W26.html"
              target="_blank"
            >
              <img :src="require('@/assets/img/api_04.svg')" alt="" />
              <p>大雨警報查詢</p>
            </a>
          </div>
          <!-- <a href="https://www.cwb.gov.tw/V8/C/M/tide.html" target="_blank" class="tide-box">潮汐預報</a> -->
        </div>
      </div>

      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      citySelect: [
        {
          val: "F-D0047-061",
          name: "臺北市",
          zoneList: [
            "北投區",
            "士林區",
            "內湖區",
            "中山區",
            "大同區",
            "松山區",
            "南港區",
            "中正區",
            "萬華區",
            "信義區",
            "大安區",
            "文山區",
          ],
        },
        {
          val: "F-D0047-069",
          name: "新北市",
          zoneList: [
            "石門區",
            "三芝區",
            "金山區",
            "淡水區",
            "萬里區",
            "八里區",
            "汐止區",
            "林口區",
            "五股區",
            "瑞芳區",
            "蘆洲區",
            "雙溪區",
            "三重區",
            "貢寮區",
            "平溪區",
            "泰山區",
            "新莊區",
            "石碇區",
            "板橋區",
            "深坑區",
            "永和區",
            "樹林區",
            "中和區",
            "土城區",
            "新店區",
            "坪林區",
            "鶯歌區",
            "三峽區",
            "烏來區",
          ],
        },
        {
          val: "F-D0047-005",
          name: "桃園市",
          zoneList: [
            "大園區",
            "蘆竹區",
            "觀音區",
            "龜山區",
            "桃園區",
            "中壢區",
            "新屋區",
            "八德區",
            "平鎮區",
            "楊梅區",
            "大溪區",
            "龍潭區",
            "復興區",
          ],
        },
        {
          val: "F-D0047-073",
          name: "臺中市",
          zoneList: [
            "北屯區",
            "西屯區",
            "北區",
            "南屯區",
            "西區",
            "東區",
            "中區",
            "南區",
            "和平區",
            "大甲區",
            "大安區",
            "外埔區",
            "后里區",
            "清水區",
            "東勢區",
            "神岡區",
            "龍井區",
            "石岡區",
            "豐原區",
            "梧棲區",
            "新社區",
            "沙鹿區",
            "大雅區",
            "潭子區",
            "大肚區",
            "太平區",
            "烏日區",
            "大里區",
            "霧峰區",
          ],
        },
        {
          val: "F-D0047-077",
          name: "臺南市",
          zoneList: [
            "安南區",
            "中西區",
            "安平區",
            "東區",
            "南區",
            "北區",
            "白河區",
            "後壁區",
            "鹽水區",
            "新營區",
            "東山區",
            "北門區",
            "柳營區",
            "學甲區",
            "下營區",
            "六甲區",
            "南化區",
            "將軍區",
            "楠西區",
            "麻豆區",
            "官田區",
            "佳里區",
            "大內區",
            "七股區",
            "玉井區",
            "善化區",
            "西港區",
            "山上區",
            "安定區",
            "新市區",
            "左鎮區",
            "新化區",
            "永康區",
            "歸仁區",
            "關廟區",
            "龍崎區",
            "仁德區",
          ],
        },
        {
          val: "F-D0047-065",
          name: "高雄市",
          zoneList: [
            "楠梓區",
            "左營區",
            "三民區",
            "鼓山區",
            "苓雅區",
            "新興區",
            "前金區",
            "鹽埕區",
            "前鎮區",
            "旗津區",
            "小港區",
            "那瑪夏區",
            "甲仙區",
            "六龜區",
            "杉林區",
            "內門區",
            "茂林區",
            "美濃區",
            "旗山區",
            "田寮區",
            "湖內區",
            "茄萣區",
            "阿蓮區",
            "路竹區",
            "永安區",
            "岡山區",
            "燕巢區",
            "彌陀區",
            "橋頭區",
            "大樹區",
            "梓官區",
            "大社區",
            "仁武區",
            "鳥松區",
            "大寮區",
            "鳳山區",
            "林園區",
            "桃源區",
          ],
        },
        {
          val: "F-D0047-001",
          name: "宜蘭縣",
          zoneList: [
            "頭城鎮",
            "礁溪鄉",
            "壯圍鄉",
            "員山鄉",
            "宜蘭市",
            "大同鄉",
            "五結鄉",
            "三星鄉",
            "羅東鎮",
            "冬山鄉",
            "南澳鄉",
            "蘇澳鎮",
          ],
        },
        {
          val: "F-D0047-041",
          name: "花蓮縣",
          zoneList: [
            "秀林鄉",
            "新城鄉",
            "花蓮市",
            "吉安鄉",
            "壽豐鄉",
            "萬榮鄉",
            "鳳林鎮",
            "豐濱鄉",
            "光復鄉",
            "卓溪鄉",
            "瑞穗鄉",
            "玉里鎮",
            "富里鄉",
          ],
        },
        {
          val: "F-D0047-037",
          name: "臺東縣",
          zoneList: [
            "長濱鄉",
            "海端鄉",
            "池上鄉",
            "成功鎮",
            "關山鎮",
            "東河鄉",
            "鹿野鄉",
            "延平鄉",
            "卑南鄉",
            "臺東市",
            "太麻里鄉",
            "綠島鄉",
            "達仁鄉",
            "大武鄉",
            "蘭嶼鄉",
            "金峰鄉",
          ],
        },
        {
          val: "F-D0047-045",
          name: "澎湖縣",
          zoneList: [
            "白沙鄉",
            "西嶼鄉",
            "湖西鄉",
            "馬公市",
            "望安鄉",
            "七美鄉",
          ],
        },
        {
          val: "F-D0047-087",
          name: "金門縣",
          zoneList: [
            "金城鎮",
            "金湖鎮",
            "金沙鎮",
            "金寧鄉",
            "烈嶼鄉",
            "烏坵鄉",
          ],
        },
        {
          val: "F-D0047-081",
          name: "連江縣",
          zoneList: ["南竿鄉", "北竿鄉", "莒光鄉", "東引鄉"],
        },
        {
          val: "F-D0047-049",
          name: "基隆市",
          zoneList: [
            "安樂區",
            "中山區",
            "中正區",
            "七堵區",
            "信義區",
            "仁愛區",
            "暖暖區",
          ],
        },
        {
          val: "F-D0047-009",
          name: "新竹縣",
          zoneList: [
            "新豐鄉",
            "湖口鄉",
            "新埔鎮",
            "竹北市",
            "關西鎮",
            "芎林鄉",
            "竹東鎮",
            "寶山鄉",
            "尖石鄉",
            "橫山鄉",
            "北埔鄉",
            "峨眉鄉",
            "五峰鄉",
          ],
        },
        {
          val: "F-D0047-053",
          name: "新竹市",
          zoneList: ["北區", "香山區", "東區"],
        },
        {
          val: "F-D0047-013",
          name: "苗栗縣",
          zoneList: [
            "竹南鎮",
            "頭份市",
            "三灣鄉",
            "造橋鄉",
            "後龍鎮",
            "南庄鄉",
            "頭屋鄉",
            "獅潭鄉",
            "苗栗市",
            "西湖鄉",
            "通霄鎮",
            "公館鄉",
            "銅鑼鄉",
            "泰安鄉",
            "苑裡鎮",
            "大湖鄉",
            "三義鄉",
            "卓蘭鎮",
          ],
        },
        {
          val: "F-D0047-017",
          name: "彰化縣",
          zoneList: [
            "伸港鄉",
            "和美鎮",
            "線西鄉",
            "鹿港鎮",
            "彰化市",
            "秀水鄉",
            "福興鄉",
            "花壇鄉",
            "芬園鄉",
            "芳苑鄉",
            "埔鹽鄉",
            "大村鄉",
            "二林鎮",
            "員林市",
            "溪湖鎮",
            "埔心鄉",
            "永靖鄉",
            "社頭鄉",
            "埤頭鄉",
            "田尾鄉",
            "大城鄉",
            "田中鎮",
            "北斗鎮",
            "竹塘鄉",
            "溪州鄉",
            "二水鄉",
          ],
        },
        {
          val: "F-D0047-021",
          name: "南投縣",
          zoneList: [
            "仁愛鄉",
            "國姓鄉",
            "埔里鎮",
            "草屯鎮",
            "中寮鄉",
            "南投市",
            "魚池鄉",
            "水里鄉",
            "名間鄉",
            "信義鄉",
            "集集鎮",
            "竹山鎮",
            "鹿谷鄉",
          ],
        },
        {
          val: "F-D0047-025",
          name: "雲林縣",
          zoneList: [
            "麥寮鄉",
            "二崙鄉",
            "崙背鄉",
            "西螺鎮",
            "莿桐鄉",
            "林內鄉",
            "臺西鄉",
            "土庫鎮",
            "虎尾鎮",
            "褒忠鄉",
            "東勢鄉",
            "斗南鎮",
            "四湖鄉",
            "古坑鄉",
            "元長鄉",
            "大埤鄉",
            "口湖鄉",
            "北港鎮",
            "水林鄉",
            "斗六市",
          ],
        },
        {
          val: "F-D0047-029",
          name: "嘉義縣",
          zoneList: [
            "大林鎮",
            "溪口鄉",
            "阿里山鄉",
            "梅山鄉",
            "新港鄉",
            "民雄鄉",
            "六腳鄉",
            "竹崎鄉",
            "東石鄉",
            "太保市",
            "番路鄉",
            "朴子市",
            "水上鄉",
            "中埔鄉",
            "布袋鎮",
            "鹿草鄉",
            "義竹鄉",
            "大埔鄉",
          ],
        },
        {
          val: "F-D0047-057",
          name: "嘉義市",
          zoneList: ["東區", "西區"],
        },
        {
          val: "F-D0047-035",
          name: "屏東縣",
          zoneList: [
            "高樹鄉",
            "三地門鄉",
            "霧臺鄉",
            "里港鄉",
            "鹽埔鄉",
            "九如鄉",
            "長治鄉",
            "瑪家鄉",
            "屏東市",
            "內埔鄉",
            "麟洛鄉",
            "泰武鄉",
            "萬巒鄉",
            "竹田鄉",
            "萬丹鄉",
            "來義鄉",
            "潮州鎮",
            "新園鄉",
            "崁頂鄉",
            "新埤鄉",
            "南州鄉",
            "東港鎮",
            "林邊鄉",
            "佳冬鄉",
            "春日鄉",
            "獅子鄉",
            "琉球鄉",
            "枋山鄉",
            "牡丹鄉",
            "滿州鄉",
            "車城鄉",
            "恆春鎮",
            "枋寮鄉",
          ],
        },
      ],
      getCityDesc: "臺北市",
      citySelected: "F-D0047-061",
      zoneSelected: "南港區",

      ethnicSelected: "ethnic-1",
      ethnicCitySelected: "F-D0047-037",
      ethnicZoneSelected: "臺東市",
      groupList: [
        {
          val: "ethnic-1",
          name: "卑南族",
          cityList: [
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["臺東市", "卑南鄉"],
            },
          ],
        },
        {
          val: "ethnic-2",
          name: "噶瑪蘭族",
          cityList: [
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["長濱鄉"],
            },
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: ["豐濱鄉"],
            },
            {
              code: "F-D0047-001",
              name: "宜蘭縣",
              zoneList: ["南澳鄉"],
            },
          ],
        },

        {
          val: "ethnic-3",
          name: "太魯閣族",
          cityList: [
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: ["萬榮鄉", "秀林鄉", "卓溪鄉"],
            },
            {
              code: "F-D0047-021",
              name: "南投縣",
              zoneList: ["仁愛鄉"],
            },
          ],
        },
        {
          val: "ethnic-4",
          name: "布農族",
          cityList: [
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: ["萬榮鄉", "卓溪鄉"],
            },
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["海端鄉", "延平鄉"],
            },
            {
              code: "F-D0047-021",
              name: "南投縣",
              zoneList: ["信義鄉", "仁愛鄉"],
            },
            {
              code: "F-D0047-065",
              name: "高雄市",
              zoneList: ["桃源區", "那瑪夏區"],
            },
          ],
        },
        {
          val: "ethnic-5",
          name: "排灣族",
          cityList: [
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["金峰鄉", "太麻里鄉", "大武鄉", "達仁鄉"],
            },
            {
              code: "F-D0047-035",
              name: "屏東縣",
              zoneList: [
                "瑪家鄉",
                "滿州鄉",
                "獅子鄉",
                "泰武鄉",
                "春日鄉",
                "來義鄉",
                "牡丹鄉",
                "三地門鄉",
              ],
            },
          ],
        },
        {
          val: "ethnic-6",
          name: "撒奇萊雅族",
          cityList: [
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: [
                "新城鄉",
                "花蓮市",
                "豐濱鄉",
                "鳳林鎮",
                "壽豐鄉",
                "瑞穗鄉",
              ],
            },
          ],
        },
        {
          val: "ethnic-7",
          name: "泰雅族",
          cityList: [
            {
              code: "F-D0047-001",
              name: "宜蘭縣",
              zoneList: ["大同鄉", "南澳鄉"],
            },
            {
              code: "F-D0047-069",
              name: "新北市",
              zoneList: ["烏來區"],
            },
            {
              code: "F-D0047-005",
              name: "桃園市",
              zoneList: ["復興區"],
            },
            {
              code: "F-D0047-009",
              name: "新竹縣",
              zoneList: ["關西鎮", "尖石鄉", "五峰鄉"],
            },
            {
              code: "F-D0047-013",
              name: "苗栗縣",
              zoneList: ["泰安鄉"],
            },
            {
              code: "F-D0047-073",
              name: "臺中市",
              zoneList: ["和平區"],
            },
            {
              code: "F-D0047-021",
              name: "南投縣",
              zoneList: ["仁愛鄉"],
            },
          ],
        },
        {
          val: "ethnic-8",
          name: "賽夏族",
          cityList: [
            {
              code: "F-D0047-009",
              name: "新竹縣",
              zoneList: ["五峰鄉"],
            },
            {
              code: "F-D0047-013",
              name: "苗栗縣",
              zoneList: ["獅潭鄉", "南庄鄉"],
            },
          ],
        },
        {
          val: "ethnic-9",
          name: "賽德克族",
          cityList: [
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: ["秀林鄉", "萬榮鄉", "卓溪鄉"],
            },
            {
              code: "F-D0047-021",
              name: "南投縣",
              zoneList: ["仁愛鄉"],
            },
          ],
        },
        {
          val: "ethnic-10",
          name: "邵族",
          cityList: [
            {
              code: "F-D0047-021",
              name: "南投縣",
              zoneList: ["魚池鄉", "水里鄉"],
            },
          ],
        },
        {
          val: "ethnic-11",
          name: "鄒族",
          cityList: [
            {
              code: "F-D0047-029",
              name: "嘉義縣",
              zoneList: ["阿里山鄉"],
            },
            {
              code: "F-D0047-065",
              name: "高雄市",
              zoneList: ["桃源區", "那瑪夏區"],
            },
          ],
        },
        {
          val: "ethnic-12",
          name: "阿美族",
          cityList: [
            {
              code: "F-D0047-041",
              name: "花蓮縣",
              zoneList: [
                "新城鄉",
                "花蓮市",
                "吉安鄉",
                "壽豐鄉",
                "豐濱鄉",
                "鳳林鎮",
                "光復鄉",
                "瑞穗鄉",
                "玉里鎮",
                "富里鄉",
              ],
            },
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: [
                "池上鄉",
                "關山鎮",
                "鹿野鄉",
                "長濱鄉",
                "成功鎮",
                "東河鄉",
                "臺東市",
                "卑南鄉",
              ],
            },
            {
              code: "F-D0047-035",
              name: "屏東縣",
              zoneList: ["牡丹鄉"],
            },
          ],
        },
        {
          val: "ethnic-13",
          name: "雅美族(達悟族)",
          cityList: [
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["蘭嶼鄉"],
            },
          ],
        },
        {
          val: "ethnic-14",
          name: "魯凱族",
          cityList: [
            {
              code: "F-D0047-065",
              name: "高雄市",
              zoneList: ["茂林區"],
            },
            {
              code: "F-D0047-035",
              name: "屏東縣",
              zoneList: ["霧臺鄉", "瑪家鄉", "三地門鄉"],
            },
            {
              code: "F-D0047-037",
              name: "臺東縣",
              zoneList: ["卑南鄉"],
            },
          ],
        },
        {
          val: "ethnic-15",
          name: "拉阿魯哇族",
          cityList: [
            {
              code: "F-D0047-065",
              name: "高雄市",
              zoneList: ["桃源區", "那瑪夏區"],
            },
          ],
        },
        {
          val: "ethnic-16",
          name: "卡那卡那富族",
          cityList: [
            {
              code: "F-D0047-065",
              name: "高雄市",
              zoneList: ["那瑪夏區"],
            },
          ],
        },
      ],
      postTime: "",
      dateTime: " - ",
      weatherData: {
        city: "",
        location: "",
        pop: "--",
        rh: "--",
        ws: "--",
        wsMeas: "",
        wx: "--",
        wxNumber: "",
        temp: "--",
        at: "--",
        iconWeather: "",
        weatherDesc: "",
      },
      weatherEvent: {
        title: "--",
        date: "--",
        cont: "--",
      },
      testString: "跑data",
    };
  },
  mounted() {
    //== 取資料日期 ==
    const nowTime = new Date();
    console.log("👉 | window.addEventListener | nowTime", nowTime);
    const nowYear = nowTime.getFullYear();
    const nowMonth = nowTime.getMonth();
    const nowDate = nowTime.getDate();
    const nowDay = nowTime.getDay();
    var nowHour = nowTime.getHours();
    if (nowHour < 10) {
      nowHour = "0" + nowHour;
    }
    var nowMinute = nowTime.getMinutes();
    if (nowMinute < 10) {
      nowMinute = "0" + nowMinute;
    }
    const nowSecond = nowTime.getSeconds();
    this.postTime = `${nowYear}-${
      nowMonth + 1
    }-${nowDate}T${nowHour}:${nowMinute}:${nowSecond}`;
    //== 今天日期 ==
    const dayNamesZh = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ];
    let hourArea = "";
    if (nowHour >= 6 && nowHour <= 12) {
      hourArea = " 早上 ";
    } else if (nowHour > 12 && nowHour <= 18) {
      hourArea = " 下午 ";
    } else if (nowHour > 18 && nowHour <= 24) {
      hourArea = " 晚上 ";
    } else if (nowHour < 6) {
      hourArea = " 凌晨 ";
    }
    this.dateTime = dayNamesZh[nowDay] + hourArea + nowHour + ":" + nowMinute;
    this.getWeather(this.citySelected, this.getZoneList[0], this.postTime);
    this.getWeatherDesc(this.getCityDesc)
    //天氣的縣市代碼
    //取得花蓮縣的天氣預報
    //F-D0047-001 宜蘭縣
    //F-D0047-041 花蓮縣
    //F-D0047-037 臺東縣
    //F-D0047-045 澎湖縣
    //F-D0047-087 金門縣
    //F-D0047-081 連江縣
    //F-D0047-061 臺北市
    //F-D0047-069 新北市
    //F-D0047-005 桃園市
    //F-D0047-073 臺中市
    //F-D0047-077 臺南市
    //F-D0047-065 高雄市
    //F-D0047-049 基隆市
    //F-D0047-009 新竹縣
    //F-D0047-053 新竹市
    //F-D0047-013 苗栗縣
    //F-D0047-017 彰化縣
    //F-D0047-021 南投縣
    //F-D0047-025 雲林縣
    //F-D0047-029 嘉義縣
    //F-D0047-057 嘉義市
    //F-D0047-035 屏東縣

    
  },
  computed: {
    //computed 切換資料，但選擇第一個不在這裡選要在onchange
    getZoneList() {
      var ind = this.citySelect.findIndex((item) => {
        return item.val == this.citySelected; // 0
      });
      // console.log('ind是什麼',ind);
      return this.citySelect[ind].zoneList;
    },
    //== 族別縣市 ==
    getEthnicCityList() {
      var ind = this.groupList.findIndex((item) => {
        return item.val == this.ethnicSelected; // value
      });
      console.log("取得ind", ind);
      console.log("取得地區", this.groupList[ind].cityList);
      return this.groupList[ind].cityList;
    },
    //== 族別鄉鎮 ==
    getEthnicZoneList() {
      var ind = this.groupList.findIndex((item) => {
        return item.val == this.ethnicSelected; // value
      });
      console.log("Computed 族別/城市", ind);

      var ind2 = this.groupList[ind].cityList.findIndex((item) => {
        return item.code == this.ethnicCitySelected; // value
      });
      console.log("Computed 族別/鄉鎮", ind2);
      // console.log("return什麼", this.groupList[ind].cityList[0].zoneList);
      return this.groupList[ind].cityList[ind2].zoneList;
    },
  },
  methods: {
    printConsole() {
      //印出console.log
      console.log("跑console.log");
      //也可以直接從data帶過來，但要加this
      console.log(this.testString);

      //data可以直接從這邊改掉
      this.testString = "跑跑";
      console.log(this.testString);
    },
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
    cityOnChagne() {
      var ind = this.citySelect.findIndex((item) => {
        return item.val == this.citySelected; // 0
      });
      // let zonSelAct = e.target.value;
      this.zoneSelected = this.citySelect[ind].zoneList[0];
      this.getCityDesc = this.citySelect[ind].name;
      this.getWeather(this.citySelected, this.zoneSelected, this.postTime);
      this.getWeatherDesc(this.getCityDesc);
    },
    zoneOnChagne(citySelected) {
      console.log(citySelected);
      // let zonSelAct = e.target.value;
      this.getWeather(citySelected, this.zoneSelected, this.postTime);
    },
    //族別的選擇
    ethnicOnChange() {
      var ind = this.groupList.filter((item) => {
        return item.val == this.ethnicSelected; // 0
      });
      console.log("族別", ind);
      this.ethnicCitySelected = ind[0].cityList[0].code;
      this.ethnicZoneSelected = ind[0].cityList[0].zoneList[0];
      this.getCityDesc = this.ethnicCitySelected[ind].name;
      //=== 取的天氣 ===
      this.getWeather(
        this.ethnicCitySelected,
        this.ethnicZoneSelected,
        this.postTime
      );
      this.getWeatherDesc(this.getCityDesc);
    },
    ethnicCityOnChange() {
      // var ind = this.groupList.filter((item) => {
      //   return item.val == this.ethnicSelected; // 0
      // });
      // console.log("族別/城市", ind);
      // this.ethnicCitySelected = ind[0].cityList[0].code;
      var ind = this.groupList.findIndex((item) => {
        return item.val == this.ethnicSelected; // value
      });
      var ind2 = this.groupList[ind].cityList.findIndex((item) => {
        return item.code == this.ethnicCitySelected; // value
      });
      this.ethnicZoneSelected = this.groupList[ind].cityList[ind2].zoneList[0];
      this.getCityDesc = this.groupList[ind].cityList[ind2].name;
      //=== 取的天氣 ===
      this.getWeather(
        this.ethnicCitySelected,
        this.ethnicZoneSelected,
        this.postTime
      );
      this.getWeatherDesc(this.getCityDesc);
      // console.log(
      //   "code",
      //   this.ethnicCitySelected,
      //   "zoneList",
      //   ind[0].cityList[0].zoneList
      // );
    },
    ethnicZoneOnChange() {
      //=== 取的天氣 ===
      this.getWeather(
        this.ethnicCitySelected,
        this.ethnicZoneSelected,
        this.postTime
      );
      // var ind = this.groupList.filter((item) => {
      //   return item.val == this.ethnicSelected; // 0
      // });
      // console.log("族別/地區");
      // this.ethnicZoneSelected = ind[0].cityList[0];
    },

    getWeather(citySelected, zoneSelected, postTime) {
      console.log("給的參數", citySelected, zoneSelected, postTime);
      axios
        .get(
          "https://opendata.cwb.gov.tw/api/v1/rest/datastore/" + citySelected,
          {
            params: {
              Authorization: "CWB-709B1F96-9EBB-4070-9462-B9FD6831459F",
              locationName: zoneSelected,
              sort: "time",
              elementName: "PoP6h,RH,Wind,Wx,T,AT,WeatherDescription,WS,WD", //取得6小時內的降雨機率、濕度、風速、天氣現象(晴天、雨天之類的)、體感溫度、天氣描述、風速、風向
              //不給就預設全部回傳，T 溫度 露點溫度、、RH 相對濕度、Wind 風向風速、AT 體感溫度、CI 舒適度、
              //Wx 天氣現象、PoP12h 降雨機率12小時分段、Pop6h 降雨機率6小時分段、MaxT 最高溫度、MinT 最低溫度、MaxCI 最高舒適度、MinCI 最低舒適度、
              //MaxAT 最高體感溫度、MinAT 最低體感溫度、UVI 紫外線指數、WeatherDescription 天氣描述

              //- startTime: '', //取得現在時間或固定時間，格式：2021-07-12T20:00:00

              timeFrom: postTime, //有點像從這個時間點開始篩選，會給最接近的時間
              //- timeTo: '',//有點像想要取得資料的最後時間點
            },
          }
        )
        .then((response) => {
          // console.table("花蓮縣的天氣預報", response.data);
          //console.log('花蓮縣地區名',response.data.records.locations[0].location[0].locationName);
          //- console.log(response.data.records.weatherElement.time.parameter.parameterName);
          const locationArr = response.data.records.locations[0].location;
          console.log(
            "縣市是：",
            response.data.records.locations[0].locationsName
          );
          //=== 縣市 ===
          this.weatherData.city =
            response.data.records.locations[0].locationsName;
          //=== 區域 ===
          this.weatherData.location =
            response.data.records.locations[0].location[0].locationName;
          //=== 天氣資訊 ===
          const weatherArr = locationArr[0].weatherElement;
          // console.log(weatherArr);
          this.$.each(weatherArr, (ind, val) => {
            // console.log(val);
            if (val.elementName == "PoP6h") {
              this.weatherData.pop = val.time[0].elementValue[0].value; //PoP6h
            } else if (val.elementName == "RH") {
              this.weatherData.rh = val.time[0].elementValue[0].value; //RH
            } else if (val.elementName == "WS") {
              this.weatherData.ws = val.time[0].elementValue[0].value; //WS
              this.weatherData.wsMeas = val.time[0].elementValue[0].measures; //WS 描述風速
            } else if (val.elementName == "Wx") {
              this.weatherData.wx = val.time[0].elementValue[0].value; //Wx
              this.weatherData.wxNumber = val.time[0].elementValue[1].value; //Wx 對應代碼
              this.weatherData.iconWeather = require("@/assets/img/icon_weather/weather_0" +
                this.weatherData.wxNumber +
                ".svg"); //天氣圖示
            } else if (val.elementName == "T") {
              this.weatherData.temp = val.time[0].elementValue[0].value; //T
            } else if (val.elementName == "AT") {
              this.weatherData.at = val.time[0].elementValue[0].value; //AT
            } else if (val.elementName == "WeatherDescription") {
              this.weatherData.weatherDesc = val.time[0].elementValue[0].value; //WeatherDescription
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getWeatherDesc(cityName){
      console.log("給給給 ",cityName);
      //=== 天氣總敘述 ===
      axios
        .get("https://opendata.cwb.gov.tw/api/v1/rest/datastore/W-C0033-002", {
          params: {
            Authorization: "CWB-709B1F96-9EBB-4070-9462-B9FD6831459F",
            locationName: cityName,//'新北市', //縣市名稱，不設定的話會回傳所有縣市
            phenomena: '濃霧,大雨,豪雨,大豪雨,超大豪雨,陸上強風',
          },
        })
        .then((response) => {
          console.log("天氣事件：", response.data);
          const weatherArr = response.data.records.record;
          for (let i = 0; i < weatherArr.length; i++) {
            const _weatherEventTitle =
              weatherArr[i].datasetInfo.datasetDescription; //天氣事件名稱
            const _weatherEventCont = weatherArr[i].contents.content.contentText; //敘述內容
            const _postEventTime = weatherArr[i].datasetInfo.validTime.startTime; //發布開始時間
            this.weatherEvent.title = _weatherEventTitle;
            this.weatherEvent.date = _postEventTime;
            this.weatherEvent.cont = _weatherEventCont;
          }
          console.log(this.weatherEvent.cont)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>