<template>
  <div class="loading-box" v-if="isLoad">
    <div class="loading">
      <img src="@/assets/img/nav_logo.svg" alt="" />
      <span>Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  data() {
    return {
      isLoad: false,
    };
  },
  mounted() {
    this.$bus.$on("loading", (data) => {
      this.isLoad = data;
    });
  },
  //== 元件銷毀前要取消監聽 ==
  beforeDestroy() {
    this.$bus.$off("loading");
  },
};
</script>