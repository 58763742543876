<template>
  <div class="swiper-container program-sub-swiper" :id="swName + (ind + 1)">
    <div class="swiper-wrapper">
      <ProgramSubVideo
        v-for="(item, index) in swList"
        :key="index"
        :id="item.id"
        :img="item.img"
        :tit="item.tit"
        :cont="item.cont"
        :videoUrl="item.videoUrl"
      />
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import ProgramSubVideo from "@/components/ProgramSubVideo.vue";

export default {
  name: "ProgramBlurSw",
  props: [
    "ind",
    "swId",
    "swPage",
    "swPageTotal",
    "swName",
    "swList",
    "isBanner",
    "bannerImg",
  ], //bannerImg-標題圖
  data() {
    return {
      swCont: "",
    };
  },
  mounted() {
    var swName = this.swName + (this.ind + 1);
    console.log("=== mounted ===" + swName);
    this.swCont = new Swiper("#" + swName, {
      // slidesPerView: "auto",
      slidesPerView: 3,
      spaceBetween: 15,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    this.swCont.on("slideChange", () => {
      // console.log("slide changed");
      // // console.log(this.hostSw.activeIndex);
      // console.log(this.swCont.isEnd);
      if (this.swCont.isEnd && this.swPage != this.swPageTotal) {
        var sendData = {
          isBanner: this.isBanner,
          typeId: this.swId,
          page: this.swPage + 1,
        };
        this.$bus.$emit("programSwPage", sendData);
      }
    });
  },
  updated() {
    this.swCont.update();
  },
  components: {
    ProgramSubVideo,
  },
};
</script>