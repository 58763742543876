<template>
  <div>
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
        <router-link to="/" class="nav-btn">
          <img src="@/assets/img/icon_home.svg" alt="" />
        </router-link>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="page-m-wrap">
          <div class="line-tit">會員資料管理</div>
          <div class="acc-photo-area">
            <!-- {{ userInfo.clan }} -->
            <div class="clan-txt">{{ setClan }}</div>
            <div class="acc-photo-box">
              <div class="acc-photo-icon acc-icon-fb" v-if="threePart"></div>
              <div class="acc-photo">
                <img :src="userInfo.userImg" alt="" v-if="userInfo.userImg" />
                <!-- <img src="@/assets/img/acc_img.png" alt="" /> -->
              </div>
            </div>
            <div class="acc-name">{{ userInfo.name }}</div>
          </div>
          <div class="acc-info-area">
            <div class="acc-info-box">
              <div class="tit">帳號</div>
              <div class="cont">{{ userInfo.acc }}</div>
            </div>
            <div class="acc-info-box">
              <div class="tit">生日</div>
              <div class="cont">{{ dataShow(userInfo.birth) }}</div>
            </div>
            <div class="acc-info-box">
              <div class="tit">性別</div>
              <div class="cont">{{ userInfo.Gender }}</div>
              <!-- <div class="cont">女{{ genderChange(userInfo.Gender) }}</div> -->
            </div>
            <div class="acc-info-box">
              <div class="tit">聯絡信箱</div>
              <div class="cont">{{ userInfo.mail }}</div>
            </div>
            <div class="acc-info-box">
              <div class="tit">聯絡電話</div>
              <div class="cont">{{ dataShow(userInfo.phone) }}</div>
            </div>
          </div>
          <div class="btn-area">
            <router-link to="/accountEdit" class="btn-w w-btn">
              編輯會員資料
            </router-link>
            <!-- <router-link to="/pwdEdit" class="btn-w red-btn" v-if="!threePart">
              變更密碼
            </router-link> -->
            <!-- <div class="btn-w blue-btn" @click="vuexTestBtn">登出</div> -->
          </div>
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
import { apiTest, apiGetClanList } from "@/api/api.js";

export default {
  data() {
    return {
      threePart: false,
      //== 族別 ==
      clanList: [],
    };
  },
  mounted() {
    //=== 取的族別 ===
    this.getClan();
    //測試API資料
    // apiTest({
    //   ID: "1",
    // })
    //   .then((result) => {
    //     console.log("=== 看我測試 ===");
    //     console.log(result.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    vuexTestBtn() {
      console.log("喵嗚R");
      var chageData = {
        name: "笛布絲",
        acc: "tipus@gmail.com",
        clan: 1,
        birth: "1992/10/12",
        Gender: "1",
        mail: "tipus1992@gmail.com",
        phone: "0952055879",
      };
      this.userInfo = chageData;
    },
    //=== 取的族別API ===
    getClan() {
      apiGetClanList()
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            console.log("族別成功！");
            this.clanList = result.data.RetData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 如果有資料就顯示 沒有顯示 -  ===
    dataShow(txt) {
      if (txt) {
        return txt;
      } else {
        return "-";
      }
    },
    //=== 性別顯示文字 ===
    genderChange(val) {
      if (val == 0) {
        return "男";
      } else if (val == 1) {
        return "女";
      } else {
        return "-";
      }
    },
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
  },
  computed: {
    //=== 取的使用者資訊 ===
    userInfo: {
      get() {
        // return this.$store.getters["userInfoGet"];
        return this.$store.state.userInfo;
      },
      set(val) {
        // this.$store.dispatch("GETTOUSERINFO", val);
        this.$store.commit("SETUSERINFO", val);
      },
    },
    //=== 讀取使用者族別 ===
    setClan: {
      get() {
        var clanTxt;
        if (this.clanList.length != 0 && this.userInfo.clan != "-") {
          // console.log("有族別");
          // console.log(this.userInfo.clan);
          var num = this.clanList.findIndex((item) => {
            return item.Id == this.userInfo.clan; // 0
          });
          clanTxt = this.clanList[num].Title;
        } else {
          // console.log("無族別");
          clanTxt = "-";
        }
        return clanTxt;
      },
    },
  },
};
</script>