<template>
  <div>
    <VideoLightBox />
    <transition name="fade" mode="out-in">
      <div class="opening-page" v-if="homeOpenPageShow">
        <!-- :class="{'ani-remove':openingPage }  -->
        <div class="opening-logo">
          <img src="@/assets/img/land_logo.svg" alt="" />
        </div>
        <div class="bot-fot">
          財團法人原住民族文化事業基金會 版權所有<br />
          Copyright © 2021 Indigenous Peoples Cultural Foundation All Rights
          Reserved .<br />
          電話：<a href="tel:(02)2788-1600">(02)2788-1600</a> 傳真：<a
            href="tel:(02)2788-1500"
            >(02)2788-1500</a
          >
          <br />
          地址：台北市南港區重陽路 120 號 5 樓
        </div>
      </div>
    </transition>
    <div class="mask" v-if="homeAdmShow"></div>
    <div class="popup-adm-area" v-if="homeAdmShow">
      <div class="popup-cloes" @click="popClose"></div>
      <div class="popup-adm-box scroll-dark">
        <div class="popup-adm-img">
          <img :src="popupAdm.img" alt="" />
        </div>
        <div class="popup-adm-cont">
          <div class="title">{{ popupAdm.tit }}</div>
          <div class="date">{{ popupAdm.date }}</div>
          <div v-html="popupAdm.cont"></div>
        </div>
      </div>
    </div>

    <div class="m-wrap-bg"></div>
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap">
      <div class="news-banner-area">
        <IndexBannerSw :swNews="swNewsList" v-if="swNewsList.length" />
        <!-- <div class="swiper-container news-banner-wrap" id="swiperNews">
          <div class="swiper-wrapper">
            <SlideNew
              v-for="(item, ind) in swNews"
              :key="ind"
              :imgName="item.imgName"
              :tit="item.tit"
              :txt="item.txt"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// // import Swiper JS
// import Swiper from "swiper";

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
import IndexBannerSw from "@/components/IndexBannerSw.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";

import {
  apiGetHomeAdm,
  apiGetIndexBanner,
  apiGetProgramList,
  apiGetProgramDetail,
  apiBannerEnter,
} from "@/api/api.js";

export default {
  data() {
    return {
      popupAdm: {
        img: "",
        tit: "--",
        date: "--",
        cont: "--",
      },
      //=== 取得未分類節目列表  ===
      swProgramAllData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: ["21080616363557440"], //預設使用 節目分類：新聞．體育．時事
        AirDate: "",
      },
      swNewsList: [
        {
          videoId: "https://www.facebook.com/zalan.seeaustronesian",
          imgName: "ind_ban_01.jpeg",
          tit: "zalan‧見識南島",
          txt: "「Zalan見識南島」由謝哲青主持，是一個從南島語族視角重新連結台灣的人文知識節目",
        },
        {
          videoId: "https://www.facebook.com/2019isaisa",
          imgName: "ind_ban_02.jpeg",
          tit: "isa isa動次動",
          txt: "不論是山上、海邊，還是都市的族人朋友，一起輕鬆開心地動次動!",
        },
        {
          videoId: "https://www.facebook.com/limahelp/?ref=page_internal",
          imgName: "ind_ban_03.jpeg",
          tit: "Lima幫幫忙",
          txt: "衝撞不同的觀點，打破社會對原住民的刻板印象",
        },
        {
          videoId:
            "https://www.facebook.com/Ila%E9%83%A8%E8%90%BD%E4%BD%8F%E4%B8%80%E6%99%9A-107262910738276/",
          imgName: "ind_ban_04.jpeg",
          tit: "ila部落住一晚",
          txt: "透過二天一夜的部落生活，感受族人的熱情，分享部落美好的人事物!",
        },
        {
          videoId: "https://www.facebook.com/titv16",
          imgName: "ind_ban_05.jpeg",
          tit: "部落防疫總動員",
          txt: "帶給您疫情之下的相關資訊!舒緩身心情，增加免疫力。",
        },
        {
          videoId: "https://www.facebook.com/tahu2016.ipcf",
          imgName: "ind_ban_06.jpeg",
          tit: "Tahu生火吧",
          txt: "全新的一季《Tahu生火吧》在大同大禮部落體驗當地生活，過程中也有大大小小的任務在等著他們挑戰喔!",
        },
        {
          videoId:
            "https://www.facebook.com/Semenay%E6%88%91%E5%80%91%E7%9A%84%E6%AD%8C-109878543855074",
          imgName: "ind_ban_07.jpeg",
          tit: "Semenay我們的歌",
          txt: "全新一季《Semenay我們的歌》一起聽屬於我們的歌！",
        },
        {
          videoId:
            "https://www.facebook.com/Kari%E4%B8%8D%E5%81%9C%E5%AD%B8-105759088533611",
          imgName: "ind_ban_08.jpeg",
          tit: "kari不停學",
          txt: "由主持人Buya哥哥活潑逗趣的方式，和各族族語老師依照短片學習族語，在日常對話句型中增加族語的詞彙，達成族語學習!",
        },
        {
          videoId: "https://www.facebook.com/Dapin2015",
          imgName: "ind_ban_09.jpeg",
          tit: "跟著dapin去旅行",
          txt: "dapin是賽德克語，走過土地的印記。邀請你帶著對旅行的渴望出發！",
        },
        {
          videoId: "https://www.facebook.com/SeeYouAtTheMarket",
          imgName: "ind_ban_010.jpeg",
          tit: "我在市場待了一整天",
          txt: "不只是美食，更是一個充滿美妙人事地物的節目！",
        },
        {
          videoId:
            "https://www.facebook.com/titv.ipcf/videos/2768504473386323/",
          imgName: "ind_ban_011.jpeg",
          tit: "'ilol的味道",
          txt: "《'ilol》是阿美族語想念、思念的意思。探訪山林自然食材，找回令人懷念的部落生活!",
        },
        {
          videoId:
            "https://www.facebook.com/Alang%E6%88%91%E7%9A%84%E5%90%8D%E5%AD%97-112147897135924",
          imgName: "ind_ban_012.jpeg",
          tit: "Alang，我的名字",
          txt: "Alang，太魯閣族語是｢部落｣的意思。以部落地名為起點，恢復傳統地名的記憶",
        },
        {
          videoId:
            "https://www.facebook.com/Picul%E7%90%83%E5%8B%9D%E9%9D%92%E6%98%A5-108732754144932",
          imgName: "ind_ban_013.jpeg",
          tit: "Picul球勝青春",
          txt: "改編自屏東縣內高中的真人真事，以排灣族為主的原住民組成黑潮籃球隊…",
        },
        {
          videoId:
            "https://www.facebook.com/%E5%8E%9F%E6%96%87%E6%9C%83-%E6%97%8F%E8%AA%9E%E5%AA%92%E9%AB%94%E4%BA%BA%E6%89%8D%E6%96%B0%E5%8B%A2%E5%8A%9B-103031698149895",
          imgName: "ind_ban_014.jpeg",
          tit: "族語新勢力",
          txt: "透過族語新勢力的青年視角，來關心部落議題。",
        },
        // {
        //   imgName: "slide_02.jpg",
        //   tit: "部落防疫總動員",
        //   txt: "要帶給觀眾疫情之下的相關資訊，<br/ >最重要的是透過視訊的方式，<br/ >了解台灣各部落的防疫措施；<br/ >還有生活所面臨到的大小事；<br/ >重要的是為了緩解大家的不安情緒，<br/ >透過專家和在家運動短片，<br/ >舒緩身心情，增加免疫力。<br/ > 主持人：臧芮軒(泰雅族)<br/ >撒基努(排灣族)",
        // },
        // {
        //   imgName: "slide_03.jpg",
        //   tit: "部落影響力",
        //   txt: "在我們身邊，有許多默默做事但很有影響力的人，我們要挖掘典藏，他與她的人生故事。<br/ >首播:2020/08/01起 每周六、周日 晚間19:30-20:00<br/ >重播:2020/08/01起 每周六、周日 晚間23:30-00:00",
        // },
        // {
        //   imgName: "slide_04.jpg",
        //   tit: "金鐘五六，廣播金鐘獎",
        //   txt: "原文會慶賀「第56屆廣播金鐘獎」 Alian FM96.3原廣台榮獲8項入圍肯定",
        // },
        // {
        //   imgName: "slide_05.jpg",
        //   tit: "Alang，我的名字",
        //   txt: "Alang，太魯閣族語是｢部落｣的意思。以部落地名為起點，恢復傳統地名的記憶<br/ >為找回傳統地名，藉由耆老以當地語言進行族語敘述傳統地名歷史，有如部落發展的歷史根源，刻劃著部落歷史文化的變遷，是部落發展歷程的重要起源，也是提出原住民歷史正義和轉型正義的觀點。<br/ >｢Alang･我的名字｣ 作為本節目的起點，帶領觀眾深入台灣各部落，透過部落現景、耆老敘述、生活樣貌、文化變遷等，讓我們看見部落發展與歷史文化特色。<br/ ><br/ >播出時間: 2021年05月08日 週六 22:30-2300 首播",
        // },
      ],
    };
  },
  mounted() {
    //=== 是否顯示廣告 ===
    if (this.homeAdmShow) {
      console.log("廣告pop");
      this.getHomeAdm();
    } else {
      console.log("廣告pop已取消");
    }
    //=== 取得全部節目列表 ===
    this.getBannerEnter();
    //** */ this.getGetIndexBanner();
    // this.getAllProgramList();
    //=== scroll bar ===
    window.$(".scroll-dark").mCustomScrollbar({
      theme: "dark",
    });
    // //== swiper ==
    // var swiperNews = new Swiper("#swiperNews", {
    //   slidesPerView: "1",
    //   spaceBetween: 15,
    //   // freeMode: true,
    // });
    // swiperNews.slideTo(1, 0);

    //** landing page
    if (this.homeOpenPageShow) {
      this.setTimeoutFun();
    } else {
      console.log("已略過open page");
    }
  },
  methods: {
    //=== 取得popup廣告 ===
    getHomeAdm() {
      apiGetHomeAdm()
        .then((result) => {
          console.log("pop廣告");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var { Icon, ShowDate, Title, Text } = resData[0];
            this.popupAdm.img = Icon.ImgUrl;
            this.popupAdm.date = ShowDate;
            this.popupAdm.tit = Title;
            this.popupAdm.cont = Text;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得新廣告 ===
    getGetIndexBanner() {
      apiGetIndexBanner({
        params: {
          place: 1,
          index: 1,
          size: 8,
        },
      })
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            // console.log(resData);
            var firstList = resData.slice(0, 8);
            console.log(firstList);
            this.$.each(firstList, (ind, val) => {
              var { ID, Title, Img, StraightImg, Summary } = val;
              var bannerImg;
              StraightImg ? (bannerImg = StraightImg) : (bannerImg = Img);
              this.swNewsList.push({
                videoId: ID,
                imgName: StraightImg,
                tit: Title,
                txt: Summary,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得全部節目列表API ===
    getAllProgramList() {
      apiGetProgramList(this.swProgramAllData)
        .then((result) => {
          console.log("節目'全'列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            this.$.each(resData, (ind, val) => {
              //=== 取得節目詳細資訊 ===
              this.getAllProgramDetail(val.ID.toString(), false);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 節目詳細資料 ===
    getAllProgramDetail(id, isBanner) {
      apiGetProgramDetail({ ID: id })
        .then((result) => {
          console.log("節目'全'詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var { ID, Title, Img, StraightImg, Introduction } = resData.Program;
            var bannerImg;
            StraightImg ? (bannerImg = StraightImg) : (bannerImg = Img);
            this.swNewsList.push({
              videoId: ID.toString(),
              imgName: StraightImg,
              tit: Title,
              txt: Introduction,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //=== 取得後台輪播首頁 1/15===
    //=== 取得全部節目列表API ===
    getBannerEnter() {
      apiBannerEnter()
        .then((result) => {
          console.log("後台列表");
          console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            console.log("??");
            var resData = result.data.data;
            var dataList = [];
            this.$.each(resData, (ind, val) => {
              var { sid, title, subtitle, link, introduction, img_url } = val;
              dataList.push({
                sid,
                videoId: link,
                subtitle,
                imgName: img_url,
                tit: title,
                txt: introduction,
              });
            });
            console.log(dataList);
            this.swNewsList = dataList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 關廣告 ===
    popClose() {
      this.homeAdmShow = false;
    },

    setTimeoutFun() {
      let vm = this;
      setTimeout(() => {
        let openPage = document.querySelector(".opening-page");
        //3秒後關閉landing page
        openPage.classList.add("ani-remove");
        this.homeOpenPageShow = false;
      }, 3000);
    },
  },
  computed: {
    homeAdmShow: {
      get() {
        return this.$store.state.homeAdm;
      },
      set(val) {
        this.$store.commit("SETHOMEADM", val);
      },
    },
    homeOpenPageShow: {
      get() {
        return this.$store.state.openPage;
        //$.store 儲存狀態(true/false)
      },
      set(val) {
        this.$store.commit("SETHOMEOPENPAGE", val);
      },
    },
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    IndexBannerSw,
    VideoLightBox,
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>