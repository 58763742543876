<template>
  <div class="radio-cude-sw-area">
    <div class="swiper-container radio-cude-sw" id="radioCudeSw">
      <div class="swiper-wrapper">
        <RadioCudeBoxSw
          v-for="(item, index) in list"
          :key="index"
          :videoId="item.videoId"
          :img="item.img"
          :tit="item.tit"
          :host="item.host"
          :time="item.time"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import RadioCudeBoxSw from "@/components/RadioCudeBoxSw.vue";

export default {
  name: "RadioCudeBoxSwArea",
  props: ["list"],
  data() {
    return {
      radioCudeSw: "",
    };
  },
  mounted() {
    this.radioCudeSw = new Swiper("#radioCudeSw", {
      slidesPerView: "auto",
      spaceBetween: 15,
      //- centeredSlides: true,
      freeMode: true,
    });
  },
  updated() {
    this.radioCudeSw.update();
  },
  components: {
    RadioCudeBoxSw,
  },
};
</script>