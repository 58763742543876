<template>
  <transition name="fade">
    <div class="light-video-area" id="lightVideoArea" v-show="lgVideoShow">
      <div class="light-video-wrap">
        <div class="light-video-close">
          <div class="icon" id="lightVideoClose" @click="closeYTVideo">
            <!-- @click="lgVideoShow = !lgVideoShow" -->
            <img src="@/assets/img/icon_m_close.svg" alt="" />
          </div>
        </div>
        <div class="light-video-box" id="lightYTVideoBox">
          <!-- <video class="video-js vjs-theme-forest video-banner" id="lgVideo">
            controls="controls"
            preload="auto"
            poster="@/assets/img/video_default.png"
            data-setup="{}"
            <source :src="videoSrc" :type="videoType" />
            <source src="@/assets/video/adv.mp4" type="video/mp4" />
            <source src="video/adv.mp4" type="video/mp4"/>
            <source src="video/adv.webm" type="video/webm"/>
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that<a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
                >supports HTML5 video</a
              >
            </p>
          </video> -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "videoYTLightBox",
  // props: ["show", "videoLink"],
  data() {
    return {
      lgVideoShow: false,
      // videoSrc: "https://streamipcfapp.akamaized.net/live/_definst_/smil:liveabr.smil/playlist.m3u8",
      videoSrc: "https://youtu.be/87OMBCgaZDk",
      // videoVal : "",
      // videoSrc: "",
      // videoType: "video/webm",
      // videoTyps: "video/mp4",
      // videoType: "application/x-mpegURL",//要對應影片的格式，這個是live m3u8用
      videoType: "video/youtube",
    };
  },
  mounted() {
    //== 設置videojs ==
    // window.videojs("lgVideo", {
    //   controls: true,
    //   autoplay: false,
    //   preload: "auto",
    //   poster: require("@/assets/img/video_default.png"),
    // });
    //=== 接收影片打開 ===

    this.$bus.$on("swOpenYTVideo", (data) => {
      console.log("我收到啥",data)
      this.lgVideoShow = true;
      this.videoSrc = 'https://www.youtube.com/watch?v='+data;
      // this.videoSrc = require("@/assets/video/adv.mp4");
      // this.videoType = "video/mp4";//要注意影片內容Type
      //== 關閉時 dispose 是移除整個dom元件 所以打開時要再加入dom 在宣告使用 ==
      //data-setup=\'{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "' +this.videoSrc +'"}],"youtube": { "ytControls": 1 ,"autoplay": 1 }}\'>'
      var liveVideo =
        '<video class="video-js vjs-theme-forest video-banner" id="lgVideoYt" data-setup=\'{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "' +this.videoSrc +'"}],"youtube": { "ytControls": 2 ,"autoplay": 1 }}\'>' +
        '<source src="' + this.videoSrc + '" type="' + this.videoType +
        '" />' +
        '<p class="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser that' +
        '<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>' +
        "</p></video>";
      
      this.$("#lightYTVideoBox").append(liveVideo);
      //== 設置videojs ==
      window.videojs("lgVideoYt", {
        controls: true, //如果自動播放，就不用 true , false 就好
        autoplay: true, //點擊後直接播放 false
        preload: "auto",
        // poster: require("@/assets/img/video_default.png"),
      });

      //== 改變影片網址 == * 如果是是直接更換可以這樣直接更換
      //window.videojs("lgVideo").src({ type: this.videoType, src: this.videoSrc });

      //   var delId = this.list.findIndex((item) => {
      //     return item.subcategory_id == data; // 0
      //   });
      //   this.list.splice(delId, 1);
    });
  },
  methods: {
    //=== 關閉lightbox ===
    closeYTVideo() {
      console.log("自己關關");
      this.lgVideoShow = false;
      //== 關閉的時候也要取消video，dispose 是移除整個dom元件 所以打開時要再加入dom==
      window.videojs("lgVideoYt").dispose();
      // this.$bus.$emit("closeVideo", "close");
    },
  },
  beforeDestroy() {
    console.log("離開lightbox");
    // window.videojs("lgVideo").dispose();
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("swOpenYTVideo");
    // this.$bus.$off("closeVideo");
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>