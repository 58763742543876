<template>
  <div class="program-video-wrap">
    <div class="swiper-container program-video" id="programVideo">
      <div class="swiper-wrapper">
        <ProgramVideo
          v-for="(item, index) in programVideoList"
          :key="index"
          :id="item.id"
          :img="item.img"
          :tit="item.tit"
          :sub="item.sub"
          :videoUrl="item.videoUrl"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import ProgramVideo from "@/components/ProgramVideo.vue";

export default {
  name: "ProgramBannerVideo",
  props: ["programVideoList"],
  mounted() {
    console.log("收到",this.programVideoList)
    //== swiper ==
    var programVideo = new Swiper("#programVideo", {
      slidesPerView: "1",
      spaceBetween: 20,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    programVideo.slideTo(1, 0);
  },
  components: {
    ProgramVideo,
  },
};
</script>