<template>
  <!-- <div class="sw-area" :class="swAreaBg"> -->
  <div class="swiper-container onair-sw-area" :id="playSwName">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in playList" :key="index">
        <!-- item.Img -->
        <div
          data-swiper-parallax-y="70%"
          data-swiper-parallax-opacity="0"
          data-swiper-parallax-scale="1.2"
          class="sw-area"
          :style="{
            background: '#000 url(' + item.strImg + ') no-repeat top 40px center/100%',
          }"
          
        >
          <div class="sw-program-area" :class="'ani' + playSwName + index">
            <div class="tab-box" data-swiper-parallax="-100">
              <div class="tab tab-on" v-if="item.isOnair">ON AIR</div>
              <div class="tab tab-tv" v-if="isTitv">TITV</div>
              <div class="tab tab-alian" v-if="isAlian">Alian</div>
            </div>
            <div class="sw-program-cont">
              <div class="tit" data-swiper-parallax="-200">{{ item.Title }}</div>
              <div class="cont" data-swiper-parallax="-300">
                {{ spSecond(item.PlayStartTime) }}
                <!-- - {{ spSecond(endTime) }} -->
                <!-- 在我們身邊，有許多默默做事但很有影響力的人，<br />我們要挖掘典藏，他與她的人生故事。<br />首播:2020/08/01起
          每周六、周日 晚間19:30-20:00<br />重播:2020/08/01起 每周六、周日
          晚間23:30-00:00 -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, EffectFade } from "swiper";
Swiper.use([EffectFade]);

export default {
  name: "OnairBox",
  props: ["isTitv", "isAlian", "playSwName", "playList", "swTo"], //"swAreaBg","isOnair","title","startTime","endTime","showStatu"
  data() {
    return {
      swCont: "",
    };
  },
  mounted() {
    this.swCont = new Swiper("#" + this.playSwName, {
      direction: "vertical",
      // effect: "fade", //android渲染度很差，會壞掉一片黑
      speed: 800,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      spaceBetween: 0,
      parallax: true,
      // observer: true,
      // observeParents: true,
      //- freeMode: true,
      // on: {
      //   slideChange() {
      //     console.log("123");
      //   },
      // },
    });
    // = 因為一開始讀取進來，沒有辦法跑所以要再更新時跑 =
    // this.swCont.slideTo(this.swTo, 0);
    this.swCont.on("slideChange", () => {
      //== 加動畫 ==
      var actIndex = this.swCont.activeIndex;
      // this.changeDataAni(actIndex); //改用swiperjs內建的parallax
    });
    //=== 收到同步 ===
    this.$bus.$on("swSameGoTo", (data) => {
      this.swCont.slideTo(data, 300);
    });
  },
  methods: {
    //=== 轉換除秒數00 ===
    spSecond(val) {
      if (!val) {
        return val;
      } else {
        var timeArr = val.split(":");
        return timeArr[0] + ":" + timeArr[1];
      }
    },
    //=== 動畫轉場 ===
    changeDataAni(ind) {
      var element = document.querySelector(".ani" + this.playSwName + ind);
      // var element = this.$(".sw-program-area");
      // element.classList.add('aaa', 'bbb');
      element.classList.add("change-data");
      element.addEventListener("animationend", () => {
        // do something
        element.classList.remove("change-data");
      });
    },
  },
  beforeDestroy() {
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("swSameGoTo");
  },
  // watch: {
  //== 如果是使用 v-show 跑 sw 的話會還沒有跑好dom就啟動，所以需要加 showStatu 近來監聽時就要更新 sw ==
  //   showStatu(val) {
  //     console.log("換狀態", val);
  //     this.swCont.update();
  //     // this.swCont.on("slideChange", () => {
  //     //   var actIndex = this.swCont.activeIndex;
  //     //   console.log(actIndex);
  //     //   this.changeDataAni(actIndex);
  //     // });
  //   },
  // },
  updated() {
    //=== 如果外層是 v-show 的話，本身就已經存在 並沒有更換值而觸發 updated ===
    // console.log("有更新");
    // console.log(this.$("#" + this.swId).find(".live-type-video-box").length);
    this.swCont.update();
    this.swCont.slideTo(this.swTo, 300);
    // this.swCont.slideTo(0, 0);
    // this.swiperMarquee.slideTo(0, 0);
  },
};
</script>