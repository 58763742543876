<template>
  <div class="tit-blur-area2">
    <img :src="bg" alt="" />
    <!-- <img :src="require('@/assets/img/' + bg)" alt="" /> -->
    <div class="tit-blur-box">
      <div class="tit-blur">
        <div class="tit">{{ tit }}</div>
        <div class="sup">{{ sup }}</div>
      </div>
      <div class="btn-box">
        <a :href="linkName" class="w-blur-btn" target="_blank">
          {{ btnName }}
        </a>
        <!-- <router-link :to="{ name: linkName }" class="w-blur-btn">
          {{ btnName }}
        </router-link> -->
        <!-- <a class="w-blur-btn" href="radio.html"></a> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TitBlurArea",
  props: ["bg", "tit", "sup", "linkName", "btnName"],
};
</script>