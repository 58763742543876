<template>
  <div>
    <VideoLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="search-box">
        <div class="page-tit">SEARCH</div>
        <div class="page-area">
          <!-- 點選列表出現內容 -->
          <transition name="fade">
            <div class="result-box" v-show="islgShow">
              <div class="close-result" @click="hideDetail">
                <!-- 圖或影片 --><img
                  src="@/assets/img/icon_news_close.svg"
                  alt=""
                />
              </div>
              <h2>{{ detail.tit }}</h2>
              <div class="result-img" v-if="detail.videoUrl == null">
                <img :src="detail.img" alt="" />
                <!-- <img src="@/assets/img/new_program_05.jpg" alt="" /> -->
              </div>

              <div
                class="news-video"
                id="newsVideoBox"
                v-show="detail.videoUrl != null"
              >
                <!-- 這裡要改 show 因為lightbox打開時會要加影片的話會找不到這個 newsVideoBox 項目，就無法加影片 -->
                <!-- <video
                  class="video-js vjs-theme-forest video-banner"
                  id="LiveVideo"
                  :poster="require('@/assets/img/' + mainNews.mainVideoImg)"
                  data-setup='{"techOrder": ["youtube"], "sources": [{ 
                  "type": "video/youtube", 
                  "src": "https://www.youtube.com/watch?v=Kh642l0sROA"
                }], 
                "youtube": { "ytControls": 2 }
              }'
                >
                  controls="controls" preload="auto"
                  poster="@/assets/img/video_default.png"
                  <source
                    src="https://streamipcfapp.akamaized.net/live/_definst_/smil:liveabr.smil/playlist.m3u8"
                    type="application/x-mpegURL"
                  />

                  <source src="@/assets/video/adv.mp4" type="video/mp4" />
                  <source src="@/assets/video/adv.mp4" type="video/webm" />

                  <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider
                    upgrading to a web browser that<a
                      href="https://videojs.com/html5-video-support/"
                      target="_blank"
                      >supports HTML5 video</a
                    >
                  </p>
                </video> -->
              </div>
              <div class="result-cont" v-html="detail.cont"></div>
            </div>
          </transition>
          <div class="search-type">
            <label
              v-for="(item, ind) in searchTypeList"
              :key="ind"
              :for="item.type"
            >
              <input
                type="radio"
                name="type"
                :id="item.type"
                :value="item.val"
                v-model="searchType"
              />
              <div class="radio-sty"></div>
              <span>{{ item.name }}</span>
            </label>
            <!-- <label for="alian">
              <input type="radio" name="type" id="alian" value="2"/>
              <div class="radio-sty"></div><span>廣播</span>
            </label>
            <label for="news">
              <input type="radio" name="type" id="news" value="4"/>
              <div class="radio-sty"></div><span>新聞</span>
            </label> -->
          </div>
          <div class="search-ui">
            <input
              type="text"
              value=""
              placeholder="請輸入關鍵字"
              v-model="searchInfo.Key"
            />
            <input
              class="btn-search"
              type="submit"
              value=""
              @click="toApiSearch"
            />
          </div>
          <div class="search-resulted">
            <ul>
              <li v-if="!searchList.length && searchNoData">
                <div class="no-data">查無符合資料。</div>
              </li>
              <!-- <li v-if="!searchList.length && !searchNoData">
                <div class="no-data">無搜尋項目。</div>
              </li> -->
              <li
                v-for="(item, ind) in searchList"
                :key="ind"
                @click="searchTypeAct == 4 ? showDetail(item) : goResPage(item)"
              >
                <div class="search-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="search-info">
                  <h2>{{ item.tit }}</h2>
                  <div class="cont">{{ item.cont }}</div>
                </div>
              </li>
              <!-- <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>原住民族教育成果展13日起登場 六大主題區吸睛</h2>
                  <div class="cont">在原民會主委Icyang．Parod、教...</div>
                </div>
              </li>
              <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>原住民實驗課程推動 學者：師資培育是關鍵</h2>
                  <div class="cont">「2018原住民族教育改革研討會...</div>
                </div>
              </li>
              <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>探討原住民族實驗教育 原教改革研討會31日展開</h2>
                  <div class="cont">「2018原住民族教育改革研討會...</div>
                </div>
              </li>
              <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>全國首例 內政部通過原住民族特定區域計劃</h2>
                  <div class="cont">為了落實原住民族自治及國土永...</div>
                </div>
              </li>
              <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>原住民族綜發貸款宣導 首場在花蓮舉行</h2>
                  <div class="cont">為了協助族人朋友申請各項貸款...</div>
                </div>
              </li>
              <li>
                <div class="search-img">
                  <img src="@/assets/img/new_program_05.jpg" alt="" />
                </div>
                <div class="search-info">
                  <h2>2018台北電影獎 多部原住民作品入選</h2>
                  <div class="cont">台灣電影界的年度盛事「台北電...</div>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// // import Swiper JS
// import Swiper from "swiper";

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";

import { apiSearch, apiGetArticleNewsList } from "@/api/api.js";

export default {
  data() {
    return {
      searchInfo: {
        Types: [], //1 - 節目,2 - 廣播,4 - 新聞
        Key: "",
        Index: 0,
        PageSize: 100,
      },
      searchType: 1,
      searchTypeAct: 1,
      searchTypeList: [
        { type: "program", name: "節目", val: 1 },
        { type: "alian", name: "廣播", val: 2 },
        { type: "news", name: "新聞", val: 4 },
      ],
      searchNoData: false,
      searchList: [
        // {
        //   id: "1",
        //   img: require("@/assets/img/new_program_05.jpg"),
        //   tit: "原住民族特定區域計畫 賴揆指示內政部積極推動",
        //   cont: "行政院長賴清德25日主持「行政...",
        // },
        // {
        //   id: "2",
        //   img: require("@/assets/img/new_program_05.jpg"),
        //   tit: "原住民族特定區域計畫 賴揆指示內政部積極推動 02",
        //   cont: "行政院長賴清德25日主持「行政...",
        // },
        // {
        //   id: "3",
        //   img: require("@/assets/img/new_program_05.jpg"),
        //   tit: "原住民族特定區域計畫 賴揆指示內政部積極推動 03",
        //   cont: "行政院長賴清德25日主持「行政...",
        // },
      ],
      islgShow: false,
      //== 新聞詳細資料 ==
      newsDetailData: {
        index: 1,
        IsAll: false,
        KeyWord: "",
        NewsCategorys: [],
        NewsID: "",
      },
      videoType: "application/x-mpegURL",
      detail: {
        img: require("@/assets/img/new_program_05.jpg"),
        tit: " - ",
        cont: " - ",
      },
    };
  },
  mounted() {
    //=== 如果網址有帶值，需要先做搜尋 ===
    var actType = this.$route.query.type;
    var actKey = this.$route.query.key;
    console.log(actType);
    if (actType != undefined) {
      console.log("有值要搜尋");
      this.searchType = actType;
      this.searchTypeAct = actType;
      this.searchInfo.Key = actKey;
      this.toApiSearch();
    }
    //=== scroll bar ===
    // window.$(".scroll-w").mCustomScrollbar();
    // 如果要加要另外加一個div放html內容 沒有放的話會被html蓋掉裡面的設定內容div
  },
  methods: {
    //=== 搜尋API ===
    toApiSearch() {
      this.searchTypeAct = this.searchType;
      this.searchInfo.Types = [];
      this.searchInfo.Types.push(this.searchTypeAct);
      if (this.searchInfo.Key == "") {
        alert("請輸入關鍵字。");
      } else {
        //== 帶入網址 因為要進內頁觀看 返回時需要搜尋內容，前往要消除catch ==
        this.$router
          .replace({
            query: {
              type: this.searchTypeAct,
              key: this.searchInfo.Key,
            },
          })
          .catch((err) => {
            err;
          });
        apiSearch(this.searchInfo)
          .then((result) => {
            console.log("搜尋");
            console.log(result.data);
            var resStatuse = result.data.success;
            var resCode = result.data.errorcode;
            if (resStatuse == true && resCode == 0) {
              var resData = result.data.RetData;
              console.log(resData);
              this.searchNoData = false;
              var list = [];
              this.$.each(resData, (ind, val) => {
                var { ID, Image, Title, Summary, M3u8Url } = val;
                var poster = "";
                Image
                  ? (poster = Image)
                  : (poster = require("@/assets/img/default_program_hor.jpg"));
                var videoUrl = "";
                M3u8Url ? (videoUrl = M3u8Url) : (videoUrl = null);
                list.push({
                  id: ID,
                  img: poster,
                  tit: Title,
                  cont: Summary,
                  videoCover: Image,
                  videoUrl,
                });
              });
              this.searchList = list;
            } else if (resStatuse == true && resCode == 1) {
              //== 查無符合資料 ==
              this.searchNoData = true;
              this.searchList = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //=== 點擊開啟"新聞"詳細資料 ===
    showDetail(data) {
      this.islgShow = true;
      console.log(data);
      var { id, img, tit, cont, videoCover, videoUrl } = data;
      this.detail = { img, tit, cont, videoCover, videoUrl };
      //== 更新新聞內容 ==
      this.newsDetailData.NewsID = id;
      apiGetArticleNewsList(this.newsDetailData)
        .then((result) => {
          // console.log("取得新聞詳細資料");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            // console.log(resData);
            var { Content } = resData[0];
            this.detail.cont = Content;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //== 加入新聞影片 ==
      if (this.detail.videoUrl != null) {
        var liveVideo =
          '<video class="video-js vjs-theme-forest video-banner" id="newsVideo" data-setup={}>' +
          '<source src="' +
          videoUrl +
          '" type="' +
          this.videoType +
          '" />' +
          '<p class="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser that' +
          '<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>' +
          "</p></video>";
        this.$("#newsVideoBox").append(liveVideo);
        //== 設置videojs ==
        var poster = "";
        videoCover
          ? (poster = videoCover)
          : (poster = require("@/assets/img/video_default.png"));
        window.videojs("newsVideo", {
          controls: true, //如果有自動播放，就 false，如果沒有自動播放，就 true
          autoplay: false, //點擊後直接播放 false
          preload: "auto",
          poster: poster,
          // poster: require("@/assets/img/video_default.png"),
        });
      }
    },
    hideDetail() {
      this.islgShow = false;
      if (this.detail.videoUrl != null) {
        window.videojs("newsVideo").dispose();
      }
    },
    //=== 去搜尋結果內頁 ===
    goResPage(data) {
      console.log("內頁前往");
      console.log(data);
      //== 前往要消除catch ==
      this.$router
        .push({
          name: "SearchCont",
          params: { id: data.id },
          query: { type: this.searchTypeAct },
        })
        .catch((err) => {
          err;
        });
    },
  },
  computed: {},
  components: {
    NavTop,
    NavSide,
    NavBar,
    VideoLightBox,
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>