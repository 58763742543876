<template>
  <div>
    <VideoYTLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-tit">NEWS PROGRAM</div>
      <div class="page-area">
        <div class="program-video-wrap">
          <div class="swiper-container program-video" id="newsVideo">
            <div class="swiper-wrapper">
              <!-- 抓官網的新聞節目群 -->
              <NewsProgramVideo
                v-for="(item, index) in NewsProgramVideoList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :sub="item.sub"
                :videoUrl="item.videoUrl"
              />
            </div>
          </div>
        </div>
        <NewsProgramList
          v-for="(item, index) in NewsProgramItemList"
          :key="index"
          :ind="index"
          :swName="ProgramSwName"
          :swIsShow.sync="item.swIsShow"
          :img="item.img"
          :fb="item.fb"
          :tit="item.tit"
          :desc="item.desc"
          :episodeList="item.episodeList"
        />
        <!-- <div @click="testChange">TTT</div> -->

        <AdmArea admType="原視新聞" />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";


import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
import NewsProgramVideo from "@/components/NewsProgramVideo.vue";
import NewsProgramList from "@/components/NewsProgramList.vue";
import AdmArea from "@/components/AdmArea.vue";

import VideoYTLightBox from "@/components/VideoYTLightBox.vue";

export default {
  data() {
    return {
      //=== 節目分類 ===
      newsType: [
        { id: 1, txt: "原鄉", isAct: true },
        { id: 2, txt: "深度", isAct: false },
        { id: 3, txt: "政經", isAct: false },
        { id: 4, txt: "教文", isAct: false },
        { id: 5, txt: "生活", isAct: false },
        { id: 6, txt: "體育", isAct: false },
        { id: 7, txt: "國際", isAct: false },
        { id: 8, txt: "人物", isAct: false },
        { id: 9, txt: "專題", isAct: false },
        { id: 10, txt: "時事議題", isAct: false },
      ],
      //=== 最上面推薦節目列表 ===
      NewsProgramVideoList: [
        {
          img: "news_program_1.jpg",
          tit: "原住民族狩獵文化權下一步關鍵報導",
          sub: "憲法法庭3月9號進行言詞辯論，邀請相關機關和學者專家表示意見，其中原住民族是否只能使用，自製獵槍狩獵是重點爭點之一...",
          videoUrl: "https://youtu.be/sl8umqHR68I",
        },
        {
          img: "news_program_2.jpg",
          tit: "花東交通改革漫長路關鍵報導",
          sub: "清明連假首日，台鐵408次太魯閣號從樹林發車，行經花蓮大清水隧道時，撞上一輛停車不慎...",
          videoUrl: "https://youtu.be/vA0QV-BNumY",
        },
        {
          img: "news_program_3.jpg",
          tit: "世代對話",
          sub: "世代正義 影像解殖!原住民族老照片的觀看與詮釋 邀請來賓：1.「烈日疊影」客座策展人 謝博剛 Fotol/Ciang...",
          videoUrl: "https://youtu.be/3TlChlKDGHU",
        },
        {
          img: "news_program_4.png",
          tit: "部落影響力",
          sub: "在我們身邊，有許多默默做事但很有影響力的人，我們要挖掘典藏，他與她的人生故事。",
          videoUrl: "https://youtu.be/aIp7J00gdt8",
        },
        {
          img: "news_program_5.jpeg",
          tit: "原觀點",
          sub: "「洄夢.找路–原舞者」1991年，原住民族正名運動時期，臺灣第一個原住民族職業舞團...",
          videoUrl: "https://youtu.be/UdfHBogQUtc",
        },
      ],
      //=== 節目列表 ===
      ProgramSwName: "newsProgramSwiper",
      NewsProgramItemList: [],
      // NewsProgramItemList: [
      //   {
      //     img: "news_program_6.jpg",
      //     tit: "Ita看世界",
      //     desc: "《ITA・看世界》為國際新聞節目。Ita為台灣原住民族群共通語詞，「我們」的意思，更有彼此...",
      //     swIsShow: true,
      //     episodeList: [
      //       "第 1 集",
      //       "第 2 集",
      //       "第 3 集",
      //       "第 4 集",
      //       "第 5 集",
      //       "第 6 集",
      //     ],
      //   },
      //   {
      //     img: "news_program_7.jpg",
      //     tit: "Mata!看天下",
      //     desc: "節目中將透過親自採訪、與世界各原住民族電視台以及各知名通訊社合作，匯整、分析各國原住民族現況...",
      //     swIsShow: true,
      //     episodeList: [
      //       "第 1 集",
      //       "第 2 集",
      //       "第 3 集",
      //       "第 4 集",
      //       "第 5 集",
      //       "第 6 集",
      //     ],
      //   },
      //   {
      //     img: "news_program_8.jpg",
      //     tit: "部落大小聲",
      //     desc: "念2月21日世界母語日，我們一起來通盤分析族語推動的策略方案！台灣是否可以借鏡紐西蘭毛...",
      //     swIsShow: true,
      //     episodeList: [
      //       "第 1 集",
      //       "第 2 集",
      //       "第 3 集",
      //       "第 4 集",
      //       "第 5 集",
      //       "第 6 集",
      //     ],
      //   },
      //   {
      //     img: "news_program_9.png",
      //     tit: "東海岸之聲",
      //     desc: "今年地方公職人員選舉已在11月底結束，選民以選票選出心中的最佳人選。依據107年10月統...",
      //     swIsShow: true,
      //     episodeList: [
      //       "第 1 集",
      //       "第 2 集",
      //       "第 3 集",
      //       "第 4 集",
      //       "第 5 集",
      //       "第 6 集",
      //     ],
      //   },
      //   {
      //     img: "news_program_10.jpg",
      //     tit: "LiMA新聞世界",
      //     desc: "今年地方公職人員選舉已在11月底結束，選民以選票選出心中的最佳人選。依據107年10月統...",
      //     swIsShow: true,
      //     episodeList: [
      //       "第 1 集",
      //       "第 2 集",
      //       "第 3 集",
      //       "第 4 集",
      //       "第 5 集",
      //       "第 6 集",
      //     ],
      //   },
      // ],
      // // === 節目列表 ===
      // SwProgramVideoList: [
      //   {
      //     img: "prog01.jpg",
      //     tit: "部落影響力1",
      //     cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //     thumbNum: "10",
      //   },
      //   {
      //     img: "prog02.jpg",
      //     tit: "部落影響力2",
      //     cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //     thumbNum: "20",
      //   },
      //   {
      //     img: "prog03.jpg",
      //     tit: "部落影響力3",
      //     cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //     thumbNum: "30",
      //   },
      //   {
      //     img: "prog01.jpg",
      //     tit: "部落影響力4",
      //     cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //     thumbNum: "40",
      //   },
      //   {
      //     img: "prog02.jpg",
      //     tit: "部落影響力5",
      //     cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //     thumbNum: "50",
      //   },
      // ],
      // //=== 節目分類列表 ===
      // ProgramBlurList: [
      //   {
      //     titCont: {
      //       img: "bg_blur03.jpg",
      //       tit: "a'iyalaeho:開會了",
      //       sup: "因為有這麼多的語言聲音，<br />這個世界才豐富美麗。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      //   {
      //     titCont: {
      //       img: "bg_blur04.jpg",
      //       tit: "kai試英雄之全原出動",
      //       sup: "熱歌勁舞加Rap，<br />最熱情的中排灣族來了。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      //   {
      //     titCont: {
      //       img: "bg_blur05.jpg",
      //       tit: "kai試英雄之全原出動",
      //       sup: "熱歌勁舞加Rap，<br />最熱情的中排灣族來了。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      // ],
      // //=== 需執行的節目SW ===
      // //   /swFunctionList: ["programSubSwiper1"],

    };
  },
  mounted() {
    //== swiper ==
    var newsVideo = new Swiper("#newsVideo", {
      slidesPerView: "1",
      spaceBetween: 20,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    newsVideo.slideTo(1, 0);
    // //== newsTypeVideo ==
    // var newsTypeVideo = new Swiper("#newsTypeVideo", {
    //   spaceBetween: 15,
    //   freeMode: true,
    // });
    //=== 啟動swiper ===
    this.NewsProgramItemList = [
      {
        img: "new_program_01.jpg",
        tit: "《ITA看世界》",
        desc: "《ITA・看世界》為國際新聞節目。Ita為台灣原住民族群共通語詞，「我們」的意思，更有彼此站在同一個立場的...。",
        fb: "",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/OIMMqBzc4qw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAGr1RKvKJ_U01H8O02e5nlo2B_bQ",
            episodeTit: "【ITA看世界 第95集】泰國僧侶駕行動賑濟車 載物資送民眾",
            videoId: "OIMMqBzc4qw",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/KkKEmIe-z7E/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDGS3YhvT3NbupaXg3jDL3jeOStFA",
            episodeTit: "【ITA看世界 第95集】馬賽人面臨政權.氣遷困境 憂文化失傳",
            videoId: "KkKEmIe-z7E",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/Db36FrnCf1Q/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB615BfvT8kv0J-LKq1oSsCi9RUWw",
            episodeTit: "【ITA看世界 第95集】魚皮衣躍上俄國時尚 消耗漁產廢棄物",
            videoId: "Db36FrnCf1Q",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/7SWpnMM21j0/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDoRfgY3WpjEzb58-jFNL743epTpw",
            episodeTit: "【ITA看世界 第95集】智利Mapuche族要自治 與政府爆衝突",
            videoId: "7SWpnMM21j0",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/01ah84sFfTY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAeQIn25VZ4kDXA46zQKmtlhx_7sw",
            episodeTit: "【ITA看世界 第95集】美原住民寄宿學校 用教育.宗教同化兒童",
            videoId: "01ah84sFfTY",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/ksXgD9v13v0/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC79wCY5UsmUPoWhBFgQ2cO-H7B4g",
            episodeTit: "【ITA看世界 第94集】Quechua語受歧視 秘魯挹注資源推族語",
            videoId: "ksXgD9v13v0",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/vu_oRdGFnNA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD_7dxcbYZdqRA8z--elVAnSQiqaQ",
            episodeTit: "【ITA看世界 第94集】歷史里程碑! 澳洲政府歸還原民土地",
            videoId: "vu_oRdGFnNA",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/koPb48MTYPg/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDiway_qSu9y_FLbMAhrNkjYk6Wsg",
            episodeTit: "【ITA看世界 第94集】墨國獨立200年 總統承諾還原民土地",
            videoId: "koPb48MTYPg",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/YatfEx9q_JU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDgG9kOxNnwbQ3SbrLnejw8mTbRdg",
            episodeTit: "【ITA看世界 第94集】🌏全球掃描🌏",
            videoId: "YatfEx9q_JU",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/XnzYrRvWcLY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA4q8DQsloZ7p0b61YMcShlq70whQ",
            episodeTit: "【ITA看世界 第94集】氣遷+採礦Poopo湖乾涸 3部落失依",
            videoId: "XnzYrRvWcLY",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/Xjo2gL1uCP8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBCAqMDy2yPp8hwjzL1WnXXdMG0Cw",
            episodeTit: "【ITA看世界 第94集】秘魯無雨缺水 山區'捕霧網'緩解危機",
            videoId: "Xjo2gL1uCP8",
          },
        ],
      },
      {
        img: "new_program_02.jpg",
        tit: "《LIMA新聞世界》",
        desc: "「LiMA」是南島語族的共同詞彙，也是數字「五」的意思。「LiMA新聞世界」以原住民主體出發的深度電視新聞專題...",
        fb: "",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/q24HCphdEu0/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDROn5wrXxc2V48vPN83T7vPl7yVg",
            episodeTit: "LiMA新聞世界第404集　預告【城市邊境 尋找一個貼近部落的家】",
            videoId: "q24HCphdEu0",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/ZqM26g3nrdA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE29F95dl2aLVnDntZgxMJzNfmgw",
            episodeTit: "LiMA新聞世界第403集　完整專題【讓世界看見布農】",
            videoId: "ZqM26g3nrdA",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/IFVGDgyn0YU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCt2_Crl8QZNRnQxPxIVD24WM65zQ",
            episodeTit: "LiMA新聞世界第403集　完整專題【亞泥案紀事錄】",
            videoId: "IFVGDgyn0YU",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/MMmRDSo9Rqk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAURLvVDj7-8doCasVO7DjUPKxs5w",
            episodeTit: "LiMA新聞世界第403集　預告【讓世界看見布農】",
            videoId: "MMmRDSo9Rqk",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/6_Wg-yZcw20/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDSWXQIP7dB4oOY9k1rTgFR3mUNng",
            episodeTit: "LiMA新聞世界第402集　完整專題【搭橋者 我的家族我的故事】",
            videoId: "6_Wg-yZcw20",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/e73e91O84mc/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDUq9B9rLQr0r8ji2E0Cwffqz8j1Q",
            episodeTit: "LiMA新聞世界第402集　預告【搭橋者 我的家族我的故事】",
            videoId: "e73e91O84mc",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/uN9pzMaxiGA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjE4_2Ax2BqSMlzDa14D-8eeiXuQ",
            episodeTit: "LiMA新聞世界第401集　完整專題【原民媽媽 快樂大掃】",
            videoId: "uN9pzMaxiGA",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/YyIg_xPgZIw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDYQf8F-NaNNaWTDSk7tHitxfmkwg",
            episodeTit: "LiMA新聞世界第401集　完整專題【指標向前 德鹿谷村】",
            videoId: "YyIg_xPgZIw",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/49a_uXe7Sgs/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA-1ZFm0nkIgaK9Ob1UcN5R8pjm3A",
            episodeTit: "LiMA新聞世界第401集　預告【指標向前 德鹿谷村】",
            videoId: "49a_uXe7Sgs",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/LYRxFMLpzxU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCWvySd3W2K8P1sdowTNmkdDzqOvQ",
            episodeTit: "【原民媽媽 快樂大掃】",
            videoId: "LYRxFMLpzxU",
          },
        ],
      },
      {
        img: "new_program_03.jpg",
        tit: "《部落影響力》",
        desc: "在我們身邊，有許多默默做事但很有影響力的人，我們要挖掘典藏，他與她的人生故事。...",
        fb: "",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/aMv-iGHqZ3Y/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCVoikZs_xrxiOTvfxfHzViZJOvg",
            episodeTit: "部落影響力_45_回復祖先的傳統土地命名 德鹿谷-卓上龍_Seediq(下)",
            videoId: "aMv-iGHqZ3Y",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/7UZ5PeiuG20/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA2wV1hejMuAu_52AGd6wimd6L1CA",
            episodeTit: "部落影響力_45_回復祖先的傳統土地命名 德鹿谷-卓上龍_Seediq(上)",
            videoId: "7UZ5PeiuG20",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/qnZB_FE0T9k/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCM_l-F_pRjVPh5W5uWfPSk6Sl0xA",
            episodeTit: "部落影響力_44_杜神父與12座天主教堂-杜勇雄_Rukai(上)",
            videoId: "qnZB_FE0T9k",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/cg6CRWjOCgk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBjDA4oFi8dfOnnUVKIYebJzGCxAg",
            episodeTit: "部落影響力_43_勇扛部落傳統文化責任-蕭惠美_Paiwan(下)",
            videoId: "cg6CRWjOCgk",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/FzCb5NxbneQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCw8tOQtXiB-QlubaKxXjxeEiJoHA",
            episodeTit: "部落影響力_43_勇扛部落傳統文化責任-蕭惠美_Paiwan(上)",
            videoId: "FzCb5NxbneQ",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/LgqW0P6FQlM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBw-5rm1HB5aqefHI-UJd1C8QRxww",
            episodeTit: "部落影響力_42_走過的歷史與痕跡-華加志_Paiwan(下)",
            videoId: "LgqW0P6FQlM",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/VuZPNM58agE/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDWpPiF85onOnynhnYSYXfIwD5G9w",
            episodeTit: "部落影響力_42_走過的歷史與痕跡-華加志_Paiwan(上)",
            videoId: "VuZPNM58agE",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/GebjkuIDhzw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAJF-j6hkYxM4GGqI-b2pZQG3uFYQ",
            episodeTit: "部落影響力_37_謙卑行走山林之人-谷明光_Bunun(下)",
            videoId: "GebjkuIDhzw",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/Ss-C5WOCD20/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDAfhpLm8cNyrV2EqKk3C7Fb23cqw",
            episodeTit: "部落影響力_37_謙卑行走山林之人-谷明光_Bunun(上)",
            videoId: "Ss-C5WOCD20",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/KJBE_n5Dbg8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLmscmvOsXZf4DvhLPgvtLjHxRIA",
            episodeTit: "部落影響力_36_貼心聆聽守護部落族人健康-林德文_Atayal(下)",
            videoId: "KJBE_n5Dbg8",
          },
        ],
      },
      {
        img: "new_program_04.jpg",
        tit: "《原觀點》",
        desc: "原視作為全國性原住民族媒體有責任建立發聲平台滿足不同區域之收視觀眾需求，繼東部新聞中心營...",
        fb: "https://www.facebook.com/%E5%8E%9F%E8%A7%80%E9%BB%9E-308257826470770/",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/vjw4mH-OKg4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAu4keoNJ81fCRB-W0dGi8QRZWK0Q",
            episodeTit: "原觀點部落進行式第141集預告 【isa Zintun a wazaqan a ruza 日月潭上的獨木舟】",
            videoId: "vjw4mH-OKg4",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/JByT_5HC8M0/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBHw_pcGKTOcgcob5tykNPy8tRRWw",
            episodeTit: "原觀點部落進行式第144集預告 【isa Zintun a wazaqan a ruza 日月潭上的獨木舟】",
            videoId: "JByT_5HC8M0",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/bEODKvlnevM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAF_4LSH_50Udn1gFKfncPYObmXAg",
            episodeTit: "原觀點東海岸之聲第58集 海洋永續論壇齊聚臺東 談南島航海遷移史",
            videoId: "vi/bEODKvlnevM",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/Bs2JrD2qPgM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC627eZyMLKHishu2UMpR2Oqz77sg",
            episodeTit: "原觀點東海岸聲第55集 東傳統刺繡工藝文化資產 傳統結合在地之美",
            videoId: "Bs2JrD2qPgM",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/T3B5mnVN_0k/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCgjg3y7zdx3nxj7YeHqjKBAXyx1A",
            episodeTit: "原觀點東海岸之聲第49集 追憶回顧年度事件 探討原住民族重大議題",
            videoId: "T3B5mnVN_0k",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/8KaG-iun4pk/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCVDCLQp8xevg0Dx2WqGsq8g16C3w",
            episodeTit: "原觀點東海岸之聲第49集預告 追憶回顧年度事件 探討原住民族重大議題",
            videoId: "8KaG-iun4pk",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/9PEBUY-P3Hg/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLACZNl49IyeslVgkG8YyVsel3v6MA",
            episodeTit: "📢原觀點第143集 預告【東奧迴響 我的未來不是夢】",
            videoId: "9PEBUY-P3Hg",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/8oAB13mTzDw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDFyMpRZjlcyXtLNYcrD9DmCC_5TQ",
            episodeTit: "原觀點部落進行式第141集 【部落裡的文化館】",
            videoId: "8oAB13mTzDw",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/cGe2gFkVhv8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC3IyzphkUCIUUMjPFX61uV0qodOQ",
            episodeTit: "原觀點東海岸之聲第142集 部落的釀 預告",
            videoId: "cGe2gFkVhv8",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/WUJHHFkLV-M/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBio5Wq9b0Pfu7SNYF3_XXiJ3sdgA",
            episodeTit: "原觀點部落進行式第141集預告 【部落裡的文化館】",
            videoId: "WUJHHFkLV-M",
          },
        ],
      },
      {
        img: "new_program_05.jpg",
        tit: "《世代對話》",
        desc: "「世代對話」是原視談話性節目。透過不同世代的「對話」，激盪出多元觀點，並創造對話平台。",
        fb: "",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/qpBOhcf-7xs/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBx8sb16m3-oIsvC8q64yODU5ZqNw",
            episodeTit: "✨世代對話第28集✨來賓-阮志軍",
            videoId: "qpBOhcf-7xs",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/iQ9u4O3rUPQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBsl1UH586WHh4ED8QQ3Ojv3806iw",
            episodeTit: "✨世代對話第27集✨來賓-Apang 張瑋帆",
            videoId: "iQ9u4O3rUPQ",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/wOe8qNtMTd4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAkEjKlOaVmh7OjCTvh0-zzAjdbow",
            episodeTit: "✨世代對話第27集✨來賓-龔山水",
            videoId: "wOe8qNtMTd4",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/_6frGU_u48E/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAYlgXhckOgVnOygSS3Yh8VoYjwdA",
            episodeTit: "✨世代對話第26集✨",
            videoId: "_6frGU_u48E",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/aA5dUseZ7h4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDsMjQRArJU3sWplQljJpERXCTnVg",
            episodeTit: "✨世代對話第29集 預告✨",
            videoId: "aA5dUseZ7h4",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/kQeovCGedMs/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBmVeeEIJCSZtb3bsYPc3o2s8eRbA",
            episodeTit: "✨世代對話第25集✨來賓-江念欣",
            videoId: "kQeovCGedMs",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/EbTtKpbJEGY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAYxeNTk6qfeXy-T56GOFl486aQfQ",
            episodeTit: "✨世代對話第24集✨來賓-寶杜･巴燕 Bawtu．Payen",
            videoId: "EbTtKpbJEGY",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/WsFXTbHWwZY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB6GWBUNOZGghNjg_XIgHlFychYmQ",
            episodeTit: "✨世代對話第24集✨來賓-潘弘旻 Opay Omos",
            videoId: "WsFXTbHWwZY",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/NiilGE56K1w/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAUbGYeNbXnRcqE5rGIccj20GNO4g",
            episodeTit: "✨世代對話第24集✨來賓-陳哲宇 Kagi Piyas",
            videoId: "NiilGE56K1w",
          },
          
          {
            episodeImg: "https://i.ytimg.com/vi/He1Z8Hrqs_g/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBPUGMq92vkI6ijKxdrtlvvZLWQcg",
            episodeTit: "✨世代對話第25集 預告✨",
            videoId: "He1Z8Hrqs_g",
          },
        ],
      },
      {
        img: "new_program_06.jpg",
        tit: "《族語新勢力》",
        desc: "透過族語新勢力的青年視角，來關心部落議題。今天，排灣族的kuliw葉王靖，要用「偏鄉新交通，原鄉心幸...",
        fb: "",
        swIsShow: true,
        episodeList: [
          {
            episodeImg: "https://i.ytimg.com/vi/B9WjRGqP1Ng/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC0cvpCmdYPKuSNjGGcOm3ayNjniw",
            episodeTit: "原文會 族語新勢力 Part 1",
            videoId: "B9WjRGqP1Ng",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/2p5BFqP9qV8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD9TU0keayR8lF15RLxdaUbH1jBFA",
            episodeTit: "原文會 族語新勢力 Part 2",
            videoId: "2p5BFqP9qV8",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/-7XkP5mEm9c/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDaTgpJBDziS6Z9KlFHf4qz6xIoUQ",
            episodeTit: "原視 族語新勢力 #01 【長輩的背簍】promo",
            videoId: "-7XkP5mEm9c",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/PPG--NzNee8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC7GbAGMXc8qByHmEvAddLrVMHLPQ",
            episodeTit: "原視 族語新勢力 #02【偏鄉新交通，原鄉心幸福】",
            videoId: "PPG--NzNee8",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/i1ajd9snD3w/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA99_tg0otj5OggxU1ymlntE3lNoA",
            episodeTit: "原文會 族語媒體人才新勢力",
            videoId: "i1ajd9snD3w",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/Qrd1h9-5WFY/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBWuOVC8NfdAtAaULdb_cL7193Zhg",
            episodeTit: "原視 族語新勢力 #03【偏鄉新交通，原鄉心幸福】 10/19 2200",
            videoId: "Qrd1h9-5WFY",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/OHyhEY_ZhWw/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAn6Mv_HKiM8rhY5V4vcfzbhrDu3Q",
            episodeTit: "族語新勢力! 原文會媒體人才培訓班結業頒獎 2021-08-22 IPCF-TITV 原文會 原視新聞",
            videoId: "OHyhEY_ZhWw",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/B1Kk_fFisK8/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBctL83hae0fBNAgacebyO0lBU3iA",
            episodeTit: "培訓族語媒體人才新勢力 原文會8月開班授課 2020-07-15 IPCF-TITV 原文會 原視新聞",
            videoId: "B1Kk_fFisK8",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/DQEevnfyxuU/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBfW-2vvulnsfAZ-IGDp0WkGtmC_A",
            episodeTit: "培訓族語媒體人才新勢力 原文會8月開班授課 2020-07-17 SaySiyat IPCF-TITV 原文會 原視新聞",
            videoId: "DQEevnfyxuU",
          },
          {
            episodeImg: "https://i.ytimg.com/vi/k4lCyuN297E/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBGje4H6ca6P2TDzKhZ214pflW4fA",
            episodeTit: "原視推\"族語新勢力\" 精彩節目今晚首播 2021-10-05 IPCF-TITV 原文會 原視新聞",
            videoId: "k4lCyuN297E",
          },
        ],
      },
      {
        img: "new_program_07.jpg",
        tit: "《a'iyalaeho:開會了》",
        desc: "【'a'iyalaeho:】是賽夏族語「聚在一起討論重要事物的意思」，呈現各族群傳統的文化教育及公共議題...",
        fb: "https://www.facebook.com/pages/category/TV-Show/Aiyalaeho%E9%96%8B%E6%9C%83%E4%BA%86-216735628869823/",
        swIsShow: true,
        episodeList: [],
      },
      
    ];
    //== 因為這裡是固定在data內所以第一次跑會成功 但如果是api近來資料會無法跑到這 所以改在component內執行 ==
    // console.log(this.swFunctionList);
    // var testaaa = this.$("#newsProgramSwiper1").find(".swiper-slide").length;
    // console.log(testaaa);
    // this.$.each(this.swFunctionList, (ind, val) => {
    //   new Swiper("#" + val, {
    //     slidesPerView: "auto",
    //     spaceBetween: 15,
    //     freeMode: true,
    //   });
    // });
    //=== 啟動後關閉 sub swiper ===
    // this.$.each(this.NewsProgramItemList, (ind, val) => {
    //   val.swIsShow = false;
    // });
    //=== admSwiper ===
    // var admSwiper = new Swiper("#admSwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
  },
  methods: {
    // testChange() {
    //   this.NewsProgramItemList = [
    //     {
    //       id: "2",
    //       img: "news_program_6.jpg",
    //       tit: "Ita看世界",
    //       desc: "《ITA・看世界》為國際新聞節目。Ita為台灣原住民族群共通語詞，「我們」的意思，更有彼此...",
    //       swIsShow: true,
    //       episodeList: [
    //         "第 1 集",
    //         "第 2 集",
    //         "第 3 集",
    //         "第 4 集",
    //         "第 5 集",
    //         "第 6 集",
    //       ],
    //     },
    //   ];
    // },
  },
  computed: {
    //=== 抓取需要跑 swiper 的 Id ===
    swFunctionList() {
      var list = [];
      this.$.each(this.NewsProgramItemList, (ind, val) => {
        list.push(this.ProgramSwName + (ind + 1));
      });
      return list;
    },
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    NewsProgramVideo,
    NewsProgramList,
    VideoYTLightBox,
    AdmArea,
  },
};
</script>