<template>
  <div>
    <nav>
      <div class="nav-box nav-l-box">
        <div class="nav-btn" @click="backPage">
          <img src="@/assets/img/icon_back.svg" alt="" />
        </div>
      </div>
    </nav>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-area">
        <div class="logo"><img src="@/assets/img/logo.svg" alt="" /></div>
        <div class="login-area">
          <div class="txt">忘記密碼</div>
          <form id="form">
            <div class="input-area">
              <div class="input-box">
                <input
                  class="input-sty required email"
                  id="acc"
                  type="text"
                  name="acc"
                  v-model="mail"
                  placeholder="輸入您註冊的帳號(電子信箱)"
                />
              </div>
              <div class="er" v-if="note">
                查無此帳號，請再次確認電子信箱是否正確！
              </div>
              <div class="btn-area">
                <div class="btn-w w-btn" id="submitBtn" @click="submitBtn">
                  送出
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="login-footer-txt">
          財團法人原住民族文化事業基金會 版權所有<br />
          Copyright © 2021 Indigenous Peoples Cultural Foundation All Rights
          Reserved.<br />
          電話：(02)2788-1600 傳真：(02)2788-1500 地址：台北市南港區重陽路 120
          號 5 樓
        </div>
      </div>
      <div class="header-bg"></div>
      <div class="footer-bg"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      note: false,
      mail: "",
    };
  },
  methods: {
    //=== 送出 ===
    submitBtn() {
      var _form = window.$("#form").valid();
      if (_form) {
        console.log("送出驗證信。");
      }
    },
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>