<template>
  <div class="news-marquee-wrap">
    <div class="swiper-container news-marquee" id="swiperMarquee">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in newsList"
          :key="index"
        >
          <a class="news-marquee-box" :href="item.link" target="_blank">
            <div class="tab tab-on">NEW</div>
            <div class="cont">
              {{ item.tit }}
            </div>
          </a>
        </div>
        <!-- <div class="swiper-slide">
              <a class="news-marquee-box" href="###">
                <div class="tab tab-on">NEWS</div>
                <div class="cont">
                  2021-04-02 / 停水兩天等不到水 台中女痛批
                </div></a
              >
            </div>
            <div class="swiper-slide">
              <a class="news-marquee-box" href="###">
                <div class="tab tab-on">NEWS</div>
                <div class="cont">
                  2021-04-03 / 停水兩天等不到水 台中女痛批
                </div></a
              >
            </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

export default {
  name: "SwNews",
  props: ["newsList"],
  data() {
    return {
      swiperMarquee: "",
    };
  },
  mounted() {
    this.swiperMarquee = new Swiper("#swiperMarquee", {
      direction: "vertical",
      spaceBetween: 0,
      //- freeMode: true,
      noSwipingClass: "news-marquee-box",
      loop: true,
      speed: 500,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  },
  updated() {
    console.log("== news updated ==");
    // console.log(this.$("#" + this.swId).find(".live-type-video-box").length);
    this.swiperMarquee.update();
    // this.swiperMarquee.slideTo(0, 0);
  },
};
</script>