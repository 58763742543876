<template>
  <div class="swiper-container program-video" id="topNewsVideo">
    <div class="swiper-wrapper">
      <NewsVideo
        v-for="(item, index) in NewsVideoList"
        :key="index"
        :img="item.img"
        :tit="item.tit"
        :sub="item.sub"
        :videoId="item.videoLink"
      />
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

import NewsVideo from "@/components/NewsVideo.vue";

export default {
  name: "TopVideoSw",
  props: ["NewsVideoList"],
  data() {
    return {
      swCont: "",
    };
  },
  mounted() {
    //== video newsVideo==
    this.swCont = new Swiper("#topNewsVideo", {
      slidesPerView: "1",
      spaceBetween: 25,
      followFinger: false,
      observer: true,
      observeParents: true,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
  },
  updated() {
    // this.swCont.update();
    // this.swCont.slideTo(1, 0);
  },
  components: {
    NewsVideo,
  },
};
</script>