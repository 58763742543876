<template>
  <div class="nav-program-bar">
    <div class="sw-sched-area" :class="schedList ? 'act' : ''" id="swSchedArea">
      <div class="sw-sched-tit" id="swSchedBtn" @click="schedList = !schedList">
        <span>PROGRAM</span
        ><img
          src="@/assets/img/icon_nav_arr.svg"
          alt=""
          id="swSchedArrow"
          :class="schedList ? 'act' : ''"
        /><span>SEHEDULE</span>
      </div>
      <!-- style="display: none" -->
      <transition name="slide">
        <div class="sw-sched-wrap" id="swSchedWrap" v-show="schedList">
          <div class="sw-sched-tab-box">
            <div
              class="tab tab-tv tab-btn"
              :class="isLiveShowData ? 'tab-btn-on' : ''"
              @click="liveShow"
            >
              TITV
            </div>
            <div
              class="tab tab-alian tab-btn"
              :class="!isLiveShowData ? 'tab-btn-on' : ''"
              @click="radioShow"
            >
              Alian
            </div>
          </div>
          <div class="sw-sched-box">
            <!-- 要用 scroll bar 所以要用 v-show，需要內容有東西算 -->
            <!-- 節目 -->
            <div v-show="isLiveShowData" class="sw-sched-s">
              <div class="no-data" v-if="liveAllTimeList.length == 0">
                無節目時刻表
              </div>
              <div
                class="sw-sched"
                v-for="(item, ind) in liveAllTimeList"
                :key="ind"
                :id="item.isTop ? 'liveTop' : ''"
                @click="swGoTo(ind)"
              >
                <div class="tab-box">
                  <div class="tab tab-tv">TITV</div>
                  <div class="tab tab-on" v-if="item.isOnair">ON AIR</div>
                </div>
                <div class="sw-sched-cont" :class="item.isOnair ? 'onair' : ''">
                  <div class="time">
                    {{ spSecond(item.PlayStartTime) }}
                    <!-- - {{ spSecond(item.PlayEndTime) }} -->
                  </div>
                  <div class="name">{{ item.Title }}</div>
                </div>
                <div class="sw-sched-img" v-if="item.Img">
                  <img :src="item.Img" alt="" />
                </div>
              </div>
              <!-- <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-tv">TITV</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">原視《午安報新聞》</div>
              </div>
            </a>
            <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-tv">TITV</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">原視《午安報新聞》</div>
              </div>
            </a>
            <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-tv">TITV</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">原視《午安報新聞》</div>
              </div>
            </a> -->
            </div>
            <!-- 廣播 -->
            <div v-show="!isLiveShowData" class="sw-sched-s">
              <div class="no-data" v-if="radioAllTimeList.length == 0">
                無廣播時刻表
              </div>
              <div
                class="sw-sched"
                v-for="(item, ind) in radioAllTimeList"
                :key="ind"
                :id="item.isTop ? 'radioTop' : ''"
                @click="swGoTo(ind)"
              >
                <div class="tab-box">
                  <div class="tab tab-alian">Alian</div>
                  <div class="tab tab-on" v-if="item.isOnair">ON AIR</div>
                </div>
                <div class="sw-sched-cont" :class="item.isOnair ? 'onair' : ''">
                  <div class="time">
                    {{ spSecond(item.PlayStartTime) }}
                    <!-- - {{ spSecond(item.PlayEndTime) }} -->
                  </div>
                  <div class="name">{{ item.Title }}</div>
                </div>
                <div class="sw-sched-img" v-if="item.Img">
                  <img :src="item.Img" alt="" />
                </div>
              </div>
              <!-- <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-alian">Alian</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">太平洋的風</div>
              </div>
            </a>
            <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-alian">Alian</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">太平洋的風</div>
              </div>
            </a>
            <a class="sw-sched" href="###">
              <div class="tab-box">
                <div class="tab tab-alian">Alian</div>
                <div class="tab tab-on">ON AIR</div>
              </div>
              <div class="sw-sched-cont">
                <div class="time">12:00 - 13:00</div>
                <div class="name">太平洋的風</div>
              </div>
            </a> -->
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="nav-bar-wrap">
      <div class="nav-bar-box" id="navBarBtn">
        <div class="nav-bar"></div>
        <div class="tit">IPCF&TITV</div>
      </div>
      <div class="nav-bar-area" id="navBarArea" style="display: none">
        <router-link to="/live" class="nav-bar-btn">
          <div class="icon">
            <img src="@/assets/img/icon_live.svg" alt="" />
          </div>
          <div class="txt">Live</div>
        </router-link>
        <router-link to="/radio" class="nav-bar-btn">
          <div class="icon">
            <img src="@/assets/img/icon_music.svg" alt="" />
          </div>
          <div class="txt">廣播</div>
        </router-link>
        <router-link to="/news" class="nav-bar-btn">
          <div class="icon">
            <img src="@/assets/img/icon_news.svg" alt="" />
          </div>
          <div class="txt">新聞</div>
        </router-link>
        <router-link to="/program" class="nav-bar-btn">
          <div class="icon">
            <img src="@/assets/img/icon_program.svg" alt="" />
          </div>
          <div class="txt">節目</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavProBar",
  props: ["isLiveShow", "liveAllTimeList", "radioAllTimeList"],
  data() {
    return {
      schedList: false,
    };
  },
  mounted() {
    //=== scroll bar ===
    // 滑起來效能好像沒有很好，先拿掉 window.$(".sw-sched-box").mCustomScrollbar();
  },
  methods: {
    //=== 第一次點開節目要滾到當前項目 ===
    // swSchedBtnOpen() {
    //   this.schedList = !this.schedList;
    // },
    //=== 切換廣播和節目 ===
    liveShow() {
      this.isLiveShowData = true;
    },
    radioShow() {
      this.isLiveShowData = false;
    },
    //=== 點選同步大圖 ===
    swGoTo(ind) {
      this.$bus.$emit("swSameGoTo", ind);
    },
    //=== 轉換除秒數00 ===
    spSecond(val) {
      var timeArr = val.split(":");
      if (val == "") {
        return "";
      } else {
        return timeArr[0] + ":" + timeArr[1];
      }
    },
  },
  computed: {
    isLiveShowData: {
      get() {
        return this.isLiveShow;
      },
      set(val) {
        this.$emit("update:isLiveShow", val);
      },
    },
  },
  // watch: {
  //   isLiveShowData(newVal) {
  //     console.log("== watch ==");
  //     console.log(newVal);
  //     if (this.isLiveShowData) {
  //       console.log("顯示節目");
  //       var topBox = document.getElementById("liveTop");
  //       // var topBox = $('#liveTop').offset().top;
  //       var radioTopNum = topBox.offsetTop;
  //       console.log(radioTopNum);
  //       console.log(topBox);
  //       this.$(".sw-sched-box").scrollTop(radioTopNum);
  //       var nnn = this.$(".sw-sched-box").find(".sw-sched").length;
  //       console.log(nnn);
  //     } else {
  //       console.log("顯示廣播");
  //       var topBoxR = document.getElementById("radioTop");
  //       // var topBox = $('#liveTop').offset().top;
  //       var radioTopNumR = topBoxR.offsetTop;
  //       console.log(radioTopNumR);
  //       this.$(".sw-sched-box").scrollTop(radioTopNumR);
  //     }
  //   },
  // },
  updated() {
    if (this.isLiveShowData) {
      // console.log("顯示節目");
      // 需抓 ID 因為兩個都會存在用class會抓不到
      var topBox = document.getElementById("liveTop");
      // var topBox = $('#liveTop').offset().top;//這樣是抓到與 window top 的距離
      var radioTopNum = topBox.offsetTop; //這樣是抓與父層 position: relative 的距離
      this.$(".sw-sched-box").scrollTop(radioTopNum);
    } else {
      // console.log("顯示廣播");
      var topBoxR = document.getElementById("radioTop");
      // var topBox = $('#liveTop').offset().top;
      var radioTopNumR = topBoxR.offsetTop;
      this.$(".sw-sched-box").scrollTop(radioTopNumR);
    }
  },
};
</script>
<style>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: ease-in; */
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
}

.program-sub-swiper-area {
  margin: 0;
  height: 235px;
}

.slide-enter-to,
.slide-leave {
  /* max-height: 226px; */
  height: 235px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  /* max-height: 0; */
  height: 0;
}

.sw-sched-s {
  position: relative;
}
.sw-sched {
  position: relative;
}
</style>