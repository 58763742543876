<template>
  <div class="swiper-slide">
    <div class="program-video-box" @click="openVideo">
      <img :src="require('@/assets/img/' + img)" alt="" />
    </div>
    <div class="news-program-info">
      <h2>{{ tit }}</h2>
      <p>{{ sub }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsTopVideo",
  props: ["img", "tit", "sub", "videoUrl"],
  methods: {
    //== 傳送項目 ==
    openVideo() {
      this.$bus.$emit("swOpenVideo", this.videoUrl);
    },
  },
};
</script>