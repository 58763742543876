<template>
  <div>
    <VideoLightBox />
    <RadioLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-tit">LIVE</div>
      <div class="page-area">
        <div class="live-status-area">
          <div class="live-status-box">
            <div class="tit">現在播出</div>
            <div class="cont">{{ liveTime.now }}</div>
          </div>
          <div class="live-status-box">
            <div class="tit">下節播出</div>
            <div class="cont">{{ liveTime.next }}</div>
          </div>
        </div>
        <div class="live-banner-box" id="liveBannerArea">
          <!-- 
          播放youtube的語法
          data-setup='{ "techOrder": ["youtube"], "sources": [{  "type": "video/youtube", "src": "https://youtu.be/GeeM2V15QFY"}] }'
        -->
          <video
            autoplay
            preload="auto"
            class="video-js vjs-theme-forest video-banner"
            id="LiveVideo"
          >
            <!-- controls="controls"
          preload="auto"
          poster="@/assets/img/video_default.png" -->
            <source
              src="https://streamipcfapp.akamaized.net/live/_definst_/smil:liveabr.smil/playlist.m3u8"
              type="application/x-mpegURL"
            />
            <!-- <source src="@/assets/video/adv.mp4" type="video/mp4" />
          <source
            src="@/assets/video/adv.mp4"
            type="video/webm"
          /> -->
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that<a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
                >supports HTML5 video</a
              >
            </p>
          </video>
        </div>
        <SwNews :newsList="newsList" v-if="newsList.length" />
        <TitBlurArea
          :bg="titBlurData.img"
          :tit="titBlurData.tit"
          :sup="titBlurData.sup"
          :linkName="titBlurData.link"
          btnName="前往觀看"
        />
        <!-- 舊：<TitBlurArea
          bg="bg_blur01.jpg"
          tit="Alian Radio Station"
          sup="Alian 廣播電台"
          linkName="Radio"
          btnName="收聽節目"
        /> -->
        <!-- <div class="tit-blur-area">
        <img src="@/assets/img/bg_blur01.jpg" alt="" />
        <div class="tit-blur-box">
          <div class="tit-blur">
            <div class="tit">Alian Radio Station</div>
            <div class="sup">Alian 廣播電台</div>
          </div>
          <div class="btn-box">
            <a class="w-blur-btn" href="radio.html">收聽節目</a>
          </div>
        </div>
      </div> -->
        <!-- 廣播類 -->
        <div class="live-type-area">
          <TypeTabSw
            swId="radioTypeTab"
            tabType="radio"
            :tabList.sync="liveRadioType"
            v-if="liveRadioType.length != 0"
          />
          <!-- <div class="live-type-tab-area">
          <div class="swiper-container live-type-tab" id="liveTypeTab">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in liveRadioType" :key="item.id">
                <div class="type-tab" :class="item.isAct ? 'act' : ''">
                  {{ item.txt }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
          <LiveVideoSwArea
            swType="radio"
            swId="liveTypeVideo"
            :swList.sync="SwLiveRadioVideoList"
            v-if="SwLiveRadioVideoList.length"
          />
          <!-- <div class="live-type-video-wrap">
          <div class="swiper-container live-type-video" id="liveTypeVideo">
            <div class="swiper-wrapper">
              <SwLiveVideo
                v-for="(item, index) in SwLiveRadioVideoList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :cont="item.cont"
                :thumbNum="item.thumbNum"
                :videoUrl="item.videoUrl"
              />
            </div>
          </div>
        </div> -->
        </div>
        <!-- <TitBlurArea
          bg="bg_blur02.jpg"
          tit="Program"
          sup="節目"
          linkName="Program"
          btnName="節目"
        /> -->
        <!-- <div class="tit-blur-area">
        <img src="@/assets/img/bg_blur02.jpg" alt="" />
        <div class="tit-blur-box">
          <div class="tit-blur">
            <div class="tit">Program</div>
            <div class="sup">節目</div>
          </div>
          <div class="btn-box">
            <a class="w-blur-btn" href="program.html">觀看節目</a>
          </div>
        </div>
      </div> -->
        <!-- 節目類 -->
        <div class="live-type-area">
          <TypeTabSw
            swId="programTypeTab"
            tabType="program"
            :tabList.sync="liveProgramType"
            v-if="liveProgramType.length != 0"
          />
          <!-- <div class="live-type-tab-area">
          <div class="swiper-container live-type-tab" id="liveTypeTabProgram">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in liveProgramType"
                :key="item.id"
              >
                <div class="type-tab" :class="item.isAct ? 'act' : ''">
                  {{ item.txt }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
          <LiveVideoSwArea
            swType="program"
            swId="liveProgramVideo"
            :swList.sync="SwLiveProgramVideoList"
            v-if="SwLiveProgramVideoList.length"
          />
          <!-- <div class="live-type-video-wrap">
          <div
            class="swiper-container live-type-video"
            id="liveTypeVideoProgram"
          >
            <div class="swiper-wrapper">
              <SwLiveVideo
                v-for="(item, index) in SwLiveProgramVideoList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :cont="item.cont"
                :thumbNum="item.thumbNum"
                :videoUrl="item.videoUrl"
              />
            </div>
          </div>
        </div> -->
        </div>
        <!-- 改成跟上面廣播一樣的呈現方式
      <div class="cube-video-area">
        <CubeVideoBox
          v-for="(item, index) in cubeVideoList"
          :key="index"
          :img="item.img"
          :tit="item.tit"
          :cont="item.cont"
        />
      </div> 
      -->
        <AdmArea admType="主頁" />
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";

import SwNews from "@/components/SwNews.vue";
import TitBlurArea from "@/components/TitBlurArea.vue";
import TypeTabSw from "@/components/TypeTabSw.vue";
import LiveVideoSwArea from "@/components/LiveVideoSwArea.vue";
// import SwLiveVideo from "@/components/SwLiveVideo.vue";
// import CubeVideoBox from "@/components/CubeVideoBox.vue";
import AdmArea from "@/components/AdmArea.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";
import RadioLightBox from "@/components/RadioLightBox.vue";

import {
  apiGetProgramTimeList,
  apiGetCategorys,
  apiGetProgramList,
  apiGetProgramDetail,
  apiGetRadioDetail,
  apiGetNewsList,
  apiGetNews,
  apiBannerMiddle,
} from "@/api/api.js";

export default {
  data() {
    return {
      liveArea: "",
      videoDefault: require("@/assets/img/video_default.png"),
      //=== 中幅廣告 ===
      titBlurData: {
        img: "",
        tit: "",
        sup: "",
        link: "",
      },
      //=== 上下段節目 ===
      nowTime: "",
      liveTimeData: {
        //radioTimeData
        index: 0,
        ProgramType: 0, //0=原視節目, 1=廣播
        AirDate: "",
      },
      liveTimeList: [], //radioTimeList
      liveTime: {
        //radioTime
        now: " - ", //世代對話
        next: " - ", //族語晚間新聞
      },
      //=== 新聞消息 ===
      newsListData: {
        MenuTypeID: "21050416184989967", //固定由 /ArticleNews/GetArticleNewsMenuType 取得 { "ID": 21050416184989967,"Title": "主頁"},
        index: 0,
        CategoryID: [],
        Keyword: "",
        IsAll: false,
      },
      newsList: [
        // { link: "###", tit: "桃源勤和-復興便道開放通行 一早車輛大排長龍" },
        // { link: "###", tit: "聚會所啟用.頒原保地所有權狀 福原熱鬧慶祝" },
        // { link: "###", tit: "56廣播金鐘獎入圍名單揭曉 Alian獲8項提名" },
        // { link: "###", tit: "睽違已久再現本土 0 指揮中心:把握清零機會" },
        // { link: "###", tit: "'minngayan醫猶未盡' 張維倫攝影展新竹登場" },
        // { link: "###", tit: "參與運動競賽曾受阻 展覽陳列女性發聲過程" },
        // { link: "###", tit: "台東大溪基礎設施未完善 通過發振興禮金引質疑" },
      ],
      //=== 廣播分類 ===
      radioTypeData: {
        index: 0,
        ProgramType: 1, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
      },
      liveRadioType: [
        // { ID: 1, txt: "全部", isAct: true },
        // { ID: 2, txt: "大眾娛樂類", isAct: false },
        // { ID: 3, txt: "公共服務類", isAct: false },
        // { id: 4, txt: "教育文化類", isAct: false },
        // { id: 5, txt: "產學合作類", isAct: false },
        // { id: 6, txt: "新聞資訊類", isAct: false },
      ],
      // === 廣播列表 ===
      getRadioListData: {
        index: 0,
        ProgramType: 1, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      SwLiveRadioVideoList: [
        // {
        //   img: "radio_01.jpg",
        //   tit: "哈勞的音樂小酒館",
        //   cont: "主持人：李明德 | 播出時間：每週一至週五 20:00~21:00",
        //   thumbNum: "10",
        //   videoUrl: "https://youtu.be/fMd1B-lgbv8",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "音樂不夜城",
        //   cont: "主持人：Alian愛音樂 | 播出時間：每週一至週五 00:00~02:00",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_03.jpg",
        //   tit: "阿塔里歐音樂廳",
        //   cont: "主持人：Nick 阿塔溜 | 播出時間：每週六、週日 19:00~20:00",
        //   thumbNum: "10",
        //   videoUrl: "",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "Kamaro'an!輕輕聽",
        //   cont: "主持人：寶杜･巴燕 | 播出時間：22:00~23:00",
        //   thumbNum: "10",
        //   videoUrl: "https://youtu.be/xecLoyoDlHs",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "senai na kacalisian",
        //   cont: "主持人：muakay | 播出時間：18:00-19:00",
        //   thumbNum: "10",
        //   videoUrl: ""
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "哈娜麥莎微醺時刻",
        //   cont: "主持人：Suyang蘇婭 | 播出時間：",
        //   thumbNum: "10",
        //   videoUrl: ""
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "Alian原創力",
        //   cont: "主持人：尹儷 | 播出時間：週一至週五 P.M.23-24",
        //   thumbNum: "10",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "Sawali Music Style",
        //   cont: "主持人：馬耀˙喇外 | 播出時間：週六日 23:00-24:00",
        //   thumbNum: "10",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "聽見心在唱歌",
        //   cont: "主持人：發法·頂戈嚕倫 | 播出時間：00:00-01:00",
        //   thumbNum: "10",
        // },
        // {
        //   img: "radio_default.png",
        //   tit: "1201讓我陪您",
        //   cont: "主持人：Umus高瑞蓮播出時間：每週一 00:00~01:00",
        //   thumbNum: "10",
        // },
      ],
      //=== 節目分類 ===
      programTypeData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
      },
      liveProgramType: [
        // { ID: 1, txt: "兒童。青少年", isAct: true },
        // { ID: 2, txt: "文化。藝術", isAct: false },
        // { ID: 3, txt: "生活休閒", isAct: false },
        // { id: 4, txt: "紀錄片", isAct: false },
        // { id: 5, txt: "銀髮族服務", isAct: false },
        // { id: 6, txt: "戲劇。電影", isAct: false },
        // { id: 7, txt: "音樂。綜藝", isAct: false },
        // { id: 8, txt: "新聞。體育。時事", isAct: false },
      ],
      //=== 節目列表 ===
      getProgramListData: {
        index: 0,
        ProgramType: 0, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      SwLiveProgramVideoList: [
        // {
        //   img: "program_01.png",
        //   tit: "2019第五屆原住民族語單詞競賽",
        //   cont: "2019-06-09 星期日首播 | Facebook、YouTube同步播出",
        //   thumbNum: "10",
        //   videoUrl: "https://youtu.be/TOt5gc7gllI",
        // },
        // {
        //   img: "program_02.jpg",
        //   tit: "第十屆全國原住民族族語戲劇競賽",
        //   cont: "週四至週一 13;00-15:00",
        //   thumbNum: "20",
        //   videoUrl: "https://youtu.be/fNCxLgT5cfI",
        // },
        // {
        //   img: "program_03.jpg",
        //   tit: "酷wawa",
        //   cont: "2021年03月12日起每週五 17:30-18:00",
        //   thumbNum: "30",
        //   videoUrl: "https://youtu.be/u02MQmi-pCA",
        // },
        // {
        //   img: "program_04.jpg",
        //   tit: "壯遊闖天下",
        //   cont: "週三 18:30-19:00",
        //   thumbNum: "40",
        //   videoUrl: "https://youtu.be/YNNYOx_Ah4M",
        // },
        // {
        //   img: "program_05.jpg",
        //   tit: "出發騎幻島",
        //   cont: "2021年3月8日起星期一到星期五 | 18:30-19:00",
        //   thumbNum: "50",
        //   videoUrl: "https://youtu.be/E7X6JOvgsns",
        // },
        // {
        //   img: "program_06.jpg",
        //   tit: "polo",
        //   cont: "2021-03-01起 每週一至週五 | 17:30-18:00",
        //   thumbNum: "0",
        //   videoUrl: null,
        // },
        // {
        //   img: "program_07.jpg",
        //   tit: "海洋鮮聞",
        //   cont: "2021年03月08日 起星期一到星期五 | 18:00-18:30",
        //   thumbNum: "0",
        //   videoUrl: "https://youtu.be/YKeUYjQYPT0",
        // },
        // {
        //   img: "program_08.jpg",
        //   tit: "山豬 飛鼠 撒可努",
        //   cont: "2021-02-22起 每週一至週五 | 17:00-17:30",
        //   thumbNum: "0",
        //   videoUrl: "https://youtu.be/oeHhSushMo0",
        // },
        // {
        //   img: "program_09.jpg",
        //   tit: "驚奇VR生態館",
        //   cont: "每週三 | 22:00-23:00",
        //   thumbNum: "0",
        //   videoUrl: ""
        // },
        // {
        //   img: "program_010.jpg",
        //   tit: "第九屆原住民族語戲劇競賽",
        //   cont: "週一 | 13:00-15:00",
        //   thumbNum: "0",
        //   videoUrl: ""
        // },
      ],
      //=== 節目方塊 ===
      cubeVideoList: [
        {
          img: "cube01.png",
          tit: "部落影響力1",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
        {
          img: "cube02.png",
          tit: "部落影響力2",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
        {
          img: "cube03.png",
          tit: "部落影響力3",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
        {
          img: "cube04.png",
          tit: "部落影響力4",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
        {
          img: "cube05.png",
          tit: "部落影響力5",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
        {
          img: "cube06.png",
          tit: "部落影響力6",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
        },
      ],
    };
  },
  mounted() {
    //=== 取得節目撥出時間列表 ===
    var newDate = new Date();
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var date = newDate.getDate();
    var hours = newDate.getHours();
    var min = newDate.getMinutes();
    var sec = newDate.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    var today = year + "/" + month + "/" + date;
    var nowTimeTxt =
      year + "/" + month + "/" + date + " " + hours + ":" + min + ":" + sec;
    this.nowTime = nowTimeTxt;
    this.liveTimeData.AirDate = today;
    this.getProgramTimeList(this.liveTimeData);
    //=== 廣播 type tap 取得 ===
    this.getCategorys(this.radioTypeData, "radio");
    //=== 節目 type tap 取得 ===
    this.getCategorys(this.programTypeData, "program");
    //=== News最新消息 ===
    // this.getNewsList();
    this.getNews();
    //=== 暫停Live ===
    this.$bus.$on("setLivePause", (data) => {
      this.liveArea.pause();
    });
    //=== 中幅廣告取得 ===
    this.getBannerMiddle();
    //=== 取得類別節目列表 ===
    this.$bus.$on("toProgramTypeId", (data) => {
      console.log("點擊tab", data.type);
      console.log("點擊接收ID", data.typeId);
      // this.getProgramListData.CategoryID = [data];
      // //=== 取得節目列表 ===
      // this.getProgramList(false);
      if (data.type == "radio") {
        console.log("radio type");
        //=== 取得 廣播列表 ===
        this.getRadioListData.CategoryID = [data.typeId]; //reList[0].ID //產學合作 21082514143851952
        this.getProgramList(this.getRadioListData, "radio");
      } else if (data.type == "program") {
        console.log("program type");
        //=== 取得 節目列表 ===
        this.getProgramListData.CategoryID = [data.typeId]; //帶入第一個 新聞・體育・時事 的ID
        this.getProgramList(this.getProgramListData, "program");
      }
    });
    // //=== video.js 設置 ===
    // var liveVideo =
    //   '<video class="video-js vjs-theme-forest video-banner"' +
    //   'id="LiveVideo" controls="controls" preload="auto" poster="/img/video_default.png">' +
    //   '<source src="https://streamipcfapp.akamaized.net/live/_definst_/smil:liveabr.smil/playlist.m3u8" type="application/x-mpegURL" />' +
    //   '<p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>' +
    //   "</video>";
    // this.$("#liveBannerArea").append(liveVideo);
    // window.videojs("LiveVideo");
    var liveAreaData = window.videojs("LiveVideo", {
      controls: true,
      autoplay: true,
      preload: "auto",
      playsinline: true,
      poster: require("@/assets/img/video_default.png"),
    });
    this.liveArea = liveAreaData;
    this.liveArea.on("play", () => {
      var isPause = true;
      //== 關閉目前播放的Radio ==
      this.$bus.$emit("swOpenRadioClose", isPause);
    });
    // window.videojs("lgVideo", {
    //   controls: true,
    //   autoplay: false,
    //   preload: "auto",
    //   poster: require("@/assets/img/video_default.png"),
    // });
    //== swiper ==
    // var swiperMarquee = new Swiper("#swiperMarquee", {
    //   direction: "vertical",
    //   spaceBetween: 0,
    //   //- freeMode: true,
    //   noSwipingClass: "news-marquee-box",
    //   loop: true,
    //   speed: 500,
    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false,
    //   },
    // });
    // swiperMarquee.update();
    //== 廣播liveTypeTab ==
    // var liveTypeTab = new Swiper("#liveTypeTab", {
    //   slidesPerView: "auto",
    //   spaceBetween: 25,
    //   freeMode: true,
    // });

    //== 廣播liveTypeVideo ==
    // var liveTypeVideo = new Swiper("#liveTypeVideo", {
    //   slidesPerView: "1",
    //   spaceBetween: 25,
    //   // freeMode: true,
    // });
    //== 節目liveTypeTab ==
    // var liveTypeTabProgram = new Swiper("#liveTypeTabProgram", {
    //   slidesPerView: "auto",
    //   spaceBetween: 25,
    //   freeMode: true,
    // });
    //== 節目liveTypeVideo ==
    // var liveTypeVideoProgram = new Swiper("#liveTypeVideoProgram", {
    //   slidesPerView: "1",
    //   spaceBetween: 25,
    //   // freeMode: true,
    // });

    // liveTypeVideo.slideTo(1, 0);
    //=== lightVideo ===
    // this.$(".live-type-video-img").on("click", function () {
    //   this.$("#lightVideoArea").fadeIn();
    // });
    // //=== admSwiper ===
    // var admSwiper = new Swiper("#admSwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
  },
  methods: {
    //=== 取得節目撥出時間列表 ===
    getProgramTimeList(data) {
      apiGetProgramTimeList(data)
        .then((result) => {
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log("時刻表");
            console.log(resData);
            this.$.each(resData, (ind, val) => {
              var { PlayEndTime } = val;
              // console.log(PlayEndTime);
              var PlayEndTimeChange = PlayEndTime.split(" ");
              var PlayEndTimeChangeData = PlayEndTimeChange[0]
                .split("-")
                .join("/");
              var PlayEndTimeChangeTxt =
                PlayEndTimeChangeData + " " + PlayEndTimeChange[1];
              var endTime = new Date(PlayEndTimeChangeTxt);
              // var endTime = new Date(PlayEndTime);
              var endTimeStr = endTime.getTime();
              // console.log(endTime.getTime());
              var newNowTime = new Date(this.nowTime);
              var NowTimeStr = newNowTime.getTime();
              if (endTimeStr > NowTimeStr) {
                this.liveTimeList.push(val);
              }
            });

            //= 如果剩下的第一筆開始時間沒有在目前時間內 =
            var PlayStartTimeChange =
              this.liveTimeList[0].PlayStartTime.split(" ");
            var PlayStartTimeChangeData = PlayStartTimeChange[0]
              .split("-")
              .join("/");
            var PlayStartTimeChangeTxt =
              PlayStartTimeChangeData + " " + PlayStartTimeChange[1];
            var stratTime = new Date(PlayStartTimeChangeTxt);
            var startTimeStr = stratTime.getTime();
            var newNowTime = new Date(this.nowTime);
            var NowTimeStr = newNowTime.getTime();
            if (Number(startTimeStr) > Number(NowTimeStr)) {
              this.liveTimeList.unshift({ Title: "目前無節目" });
            }

            //= 填字 =
            if (this.liveTimeList.length == 0) {
              this.liveTime.now = "-";
              this.liveTime.next = "-";
            } else {
              this.liveTime.now = this.liveTimeList[0].Title;
            }
            this.liveTimeList.length == 1
              ? (this.liveTime.next = "-")
              : (this.liveTime.next = this.liveTimeList[1].Title);
          } else if (resStatuse == true && resCode == 1) {
            console.log("=== 無節目時刻表 ===");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目類別 ===
    getCategorys(data, area) {
      apiGetCategorys(data)
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            var reList = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title } = val;
              ind == 0
                ? reList.push({ ID: ID.toString(), Title: Title, isAct: true })
                : reList.push({
                    ID: ID.toString(),
                    Title: Title,
                    isAct: false,
                  });
            });
            if (area == "radio") {
              this.liveRadioType = reList;
              //=== 第一次取得 廣播列表 ===
              this.getRadioListData.CategoryID.push(reList[0].ID); //reList[0].ID //產學合作 21082514143851952
              this.getProgramList(this.getRadioListData, "radio");
            } else if (area == "program") {
              this.liveProgramType = reList;
              //=== 第一次取得 節目列表 ===
              this.getProgramListData.CategoryID.push(reList[0].ID); //帶入第一個 新聞・體育・時事 的ID
              this.getProgramList(this.getProgramListData, "program");
            }

            //=== 第一次取得 節目列表 ===
            // this.getProgramListData.CategoryID.push(reList[6].ID); //帶入第一個 新聞・體育・時事 的ID
            // this.getProgramList(true);
            //=== 取得列表 ===
            // this.getProgramDetail("21081609193308851");
            // this.getProgramEpisode("21081609193308851");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目列表ＡＰＩ ===
    getProgramList(apiData, type) {
      apiGetProgramList(apiData)
        .then((result) => {
          console.log("節目列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var dataList = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Img, Title } = val;
              dataList.push({
                videoId: ID.toString(),
                img: Img,
                tit: Title,
                cont: "",
              });
            });
            if (type == "radio") {
              console.log("radioList");
              this.SwLiveRadioVideoList = dataList;
              //== 取的廣播詳細資訊 ==
              this.$.each(this.SwLiveRadioVideoList, (ind, val) => {
                var { videoId } = val;
                this.getRadioDetail(videoId); //21082515011733126:Vuvu樂起來
              });
            } else if (type == "program") {
              console.log("programList");
              this.SwLiveProgramVideoList = dataList;
              //== 取的節目詳細資訊 ==
              this.$.each(this.SwLiveProgramVideoList, (ind, val) => {
                var { videoId } = val;
                this.getProgramDetail(videoId); //21082515011733126:Vuvu樂起來
              });
              // this.getProgramDetail("21082703080578337");
            }
            // //=== 取得節目詳細資訊 ===
            // this.getProgramDetail("21082703080578337");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== "節目"詳細資料 ===
    getProgramDetail(id) {
      apiGetProgramDetail({ ID: id })
        .then((result) => {
          console.log("節目詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            console.log("programDetail");
            var { PlayDuration } = resData.Program;
            var ind = this.SwLiveProgramVideoList.findIndex(function (item) {
              return item.videoId == id; // 21082703080578337:id
            });
            this.SwLiveProgramVideoList[ind].cont = PlayDuration;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== "廣播"詳細資料 ===
    getRadioDetail(id) {
      apiGetRadioDetail({ ID: id })
        .then((result) => {
          console.log("廣播詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            console.log("radioDetail");
            var { PlayDuration } = resData.Program;
            // console.log(PlayDuration);
            var ind = this.SwLiveRadioVideoList.findIndex(function (item) {
              return item.videoId == id; // 21082515011733126:id
            });
            this.SwLiveRadioVideoList[ind].cont = PlayDuration;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== News最新消息 ===
    getNewsList() {
      apiGetNewsList(this.newsListData)
        .then((result) => {
          console.log("News列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title } = val;
              list.push({
                newsId: ID.toString(),
                tit: Title,
                link: "###",
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNews() {
      apiGetNews({
        params: {
          part: 1,
        },
      })
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Content, Link } = val;
              list.push({
                newsId: ID.toString(),
                tit: Content,
                link: Link,
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 中幅廣告 ===
    getBannerMiddle() {
      apiBannerMiddle()
        .then((result) => {
          console.log("=== 中幅廣告 ===");
          // console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            console.log(resData);
            //category -> Live,廣播,新聞
            this.$.each(resData, (ind, val) => {
              var { category, img_url, title, subtitle, link } = val;
              
              if (category === "LIVE") {
                console.log(val)
                this.titBlurData.img = img_url;
                this.titBlurData.tit = title;
                this.titBlurData.sup = subtitle;
                this.titBlurData.link = link;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  //== 元件銷毀前要取消監聽 ==
  beforeDestroy() {
    console.log("離開live");
    //this.$("#liveBannerArea").append(liveVideo);
    window.videojs("LiveVideo").dispose();
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("setLivePause");
    this.$bus.$off("toProgramTypeId");
    // this.$bus.$off("toProgramEpisodeId");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    SwNews,
    TitBlurArea,
    TypeTabSw,
    LiveVideoSwArea,
    // SwLiveVideo,
    // CubeVideoBox,
    VideoLightBox,
    RadioLightBox,
    AdmArea,
  },
};
</script>
<style scoped>
/* 自定義bar */
.radio-fixed {
  width: auto;
  background: none !important;
  height: 50px;
  position: fixed;
  top: auto;
  left: 11px;
  right: 11px;
  bottom: 70px;
  z-index: 2;
}
.radio-fixed .light-video-area {
  background: none;
}
.radio-fixed .light-video-wrap {
  width: 100%;
  position: static;
  transform: translate(0, 0);
  height: 50px;
}
.radio-fixed .light-video-close {
  width: 28px;
  padding: 0;
  margin: 0;
  top: 11px;
  right: 14px;
  position: absolute;
  z-index: 1;
}
.radio-fixed .light-video-close .icon {
  width: 100%;
}
.radio-fixed .light-video-close .icon img {
  width: 100%;
}

.radio-fixed .light-video-box .radio-banner {
  width: 100%;
  height: 50px;
  background: none;
  border-radius: 40px;
  overflow: hidden;
  margin: 0 auto;
}
.radio-fixed .radio-banner .video-js {
  background: none;
}
.radio-fixed .light-video-box .radio-banner .vjs-fullscreen-control {
  display: none;
}
.radio-fixed .vjs-has-started .vjs-control-bar {
  color: #1e242c;
  height: 50px;
  padding: 0 50px 0 0;
  background: rgb(255 255 255 / 90%);
}
.radio-fixed .light-video-box .video-js .vjs-play-control {
  width: 80px;
}
.radio-fixed .light-video-box .vjs-button > .vjs-icon-placeholder:before {
  font-size: 3em;
  line-height: 50px;
}
.radio-fixed .light-video-box .video-js .vjs-time-control {
  font-size: 1.7em;
}
</style>