<template>
  <div class="swiper-slide">
    <div class="program-video-box video-play" @click="openVideo(id)">
      <img :src="img" alt="" />
      <!-- <img :src="require('@/assets/img/' + img)" alt="" /> -->
      <div class="program-video-cont">
        <div class="tit">{{ tit }}</div>
        <span style="display: none">{{ videoUrl }}</span>
        <div class="sub">{{ sub }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetProgramEpisodeList, apiGetProgramEpisode } from "@/api/api.js";
export default {
  name: "ProgramVideo",
  props: ["id", "img", "tit", "sub", "videoUrl"],
  data() {
    return {
      // videoUrl: "",
    };
  },
  mounted() {
    //**  console.log("lightbox收到",this.ProgramVideo)
  },
  methods: {
    
    //== 傳送項目 ==
    openVideo(id) {
      console.log("我傳了",this.videoUrl)
      //開YTlightbox
      this.$bus.$emit("swOpenYTVideo", this.videoUrl);

      
      /** ** 10/27先改成取得ytoutube的影片
      * //== 取的節目集數列表 才能拿到第一集 ==
      if (this.videoUrl == "") {
        this.getProgramEpisodeList(id);
      } else {
        var videoData = {
          videoLink: this.videoUrl,
          swType: "program",
        };
        this.$bus.$emit("swOpenVideo", videoData);
      }
      // this.$bus.$emit("swOpenVideo", this.videoUrl);
      */
    },
    //== 取的節目集數列表 ==
    /* ** 10/27先改成取得ytoutube的影片
    getProgramEpisodeList(id) {
      apiGetProgramEpisodeList({
        index: 0,
        ProgramType: 0,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("banner節目級數列表");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            // console.log("=== bannerVideo ===");
            // console.log(resData);
            // console.log(resData[0].ID);
            var videoLink = resData[0].m3u8Url;
            if (videoLink != null) {
              this.videoUrl = videoLink;
              var videoData = {
                videoLink: this.videoUrl,
                swType: "program",
              };
              this.$bus.$emit("swOpenVideo", videoData);
              // this.$bus.$emit("swOpenVideo", this.videoUrl);
            } else {
              alert("目前沒有影音檔案！");
            }
            // this.getProgramEpisode(resData[0].ID);
          } else if (resStatuse == true && resCode == 1) {
            console.log("沒有節目級數列表");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    */
    //=== 取得單集內容 ===
    // getProgramEpisode(id) {
    //   apiGetProgramEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("集數節目詳細資料");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var videoLink = result.data.RetData.m3u8_url;
    //         if (videoLink != null) {
    //           this.videoUrl = videoLink;
    //           this.$bus.$emit("swOpenVideo", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有影音檔案");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>