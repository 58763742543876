<template>
  <div>
    <VideoLightBox />
    <VideoYTLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <transition name="fade">
      <div class="host-info-page" v-if="hostInfo">
        <div class="host-info-box">
          <div class="clost-host-page" @click="hostInfoClose">
            <img src="@/assets/img/icon_news_close.svg" alt="" />
          </div>
          <div
            class="host-head"
            :style="{ backgroundImage: 'url(' + hostInfoData.img + ')' }"
          ></div>
          <!-- :style="{
              backgroundImage:
                'url(' + require('@/assets/img/host_01.png') + ')',
            }" -->
          <h2 class="host-name">{{ hostInfoData.name }}</h2>
          <div class="host-intro" v-html="hostInfoData.cont">
            <!-- 在我們身邊，有許多默默做事但很有11影響力的人，
            我們要挖掘典藏，他與她的人生故事。 首播:2020/08/01起
            每周六、周日 晚間19:30-20:00 重播:2020/08/01起 每周六、周日
            晚間23:30-00:00 在我們身邊，有許多默默做事但很有影響力的人，
            我們要挖掘典藏，他與她的人生故事。
            在我們身邊，有許多默默做事但很有11影響力的人，
            我們要挖掘典藏，他與她的人生故事。 首播:2020/08/01起
            每周六、周日 晚間19:30-20:00 重播:2020/08/01起 每周六、周日
            晚間23:30-00:00 在我們身邊，有許多默默做事但很有影響力的人，
            我們要挖掘典藏，他與她的人生故事。 -->
          </div>
        </div>
      </div>
    </transition>
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-tit">NEWS</div>
      <div class="page-area">
        <div class="program-video-wrap">
          <!-- 快訊 -->
          <SwNews :newsList="newsList" v-if="newsList.length" />
          <!-- 官網即時新聞 直接播放影音 -->
          <TopVideoSw
            :NewsVideoList="NewsVideoList"
            v-if="NewsVideoList.length"
          />
          <!-- <div
            class="swiper-container program-video"
            id="topNewsVideo"
            v-if="NewsVideoList.length"
          >
            <div class="swiper-wrapper">
              <NewsVideo
                v-for="(item, index) in NewsVideoList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :sub="item.sub"
                :videoId="item.videoId"
              />
            </div>
          </div> -->
        </div>

        <div class="live-type-area">
          <TypeTabSw
            swId="newsTypeTab"
            tabType="news"
            :tabList.sync="newsType"
            v-if="newsType.length != 0"
          />
          <!-- <div class="live-type-tab-area">
            <div class="swiper-container live-type-tab" id="newsTypeTab">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="item in newsType"
                  :key="item.id"
                >
                  <div class="type-tab" :class="item.isAct ? 'act' : ''">
                    {{ item.txt }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 新聞分類 會進入內頁有影音、標題、內文 -->
          <div class="live-type-video-wrap">
            <div class="swiper-container live-type-video" id="newsTypeVideo">
              <div class="swiper-wrapper">
                <NewsListTest
                  v-for="(item, index) in NewsListItem"
                  :key="index"
                  :ID="item.ID"
                  :typeId="actNewsType"
                  :Title="item.Title"
                  :Content="item.Content"
                  :VideoID="item.VideoID"
                  :CustomCreateTime="item.CustomCreateTime"
                  :Journalist="item.Journalist"
                  :Image="item.Image"
                />
                <!-- <NewsList
                  v-for="(item, index) in NewsListItem"
                  :key="index"
                  :img="item.img"
                  :tit="item.tit"
                  :cont="item.cont"
                  :thumbNum="item.thumbNum"
                /> -->
              </div>
            </div>
          </div>
        </div>

        <TitBlurArea
          :bg="titBlurData.img"
          :tit="titBlurData.tit"
          :sup="titBlurData.sup"
          :linkName="titBlurData.link"
          btnName="前往觀看"
        />
        <!-- 舊： <TitBlurArea
          bg="news_program_1.jpg"
          tit="News Program"
          sup="新聞節目群"
          linkName="NewsProgram"
          btnName="觀看節目"
        /> -->

        <!-- <div class="tit-blur-area">
          <img src="@/assets/img/news_program_1.jpg" alt="" />
          <div class="tit-blur-box">
            <div class="tit-blur">
              <div class="tit">News Program</div>
              <div class="sup">新聞節目群</div>
            </div>
            <div class="btn-box">
              <router-link to="/newsProgram" class="w-blur-btn">
                觀看節目
              </router-link>
            </div>
          </div>
        </div> -->
        <HostNewsSwList
          :hostPerList="hostPerList"
          :pageIndex="hostPage"
          :pageTotal="hostPageTotal"
        />

        <!-- <ProgramBlurArea
          v-for="(item, index) in ProgramBlurList"
          :key="index"
          :ind="index"
          :titCont="item.titCont"
          :swIsShow.sync="item.swIsShow"
          :swName="ProgramBlurSwName"
          :swList="item.swList"
        /> -->
        <AdmArea admType="原視新聞" />
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
import SwNews from "@/components/SwNews.vue";
import TypeTabSw from "@/components/TypeTabSw.vue";
// import NewsVideo from "@/components/NewsVideo.vue";
import TopVideoSw from "@/components/TopVideoSw.vue";
// import NewsList from "@/components/NewsList.vue";
import NewsListTest from "@/components/NewsList_test.vue";
// import ProgramBlurArea from "@/components/ProgramBlurArea.vue";
import HostNewsSwList from "@/components/HostNewsSwList.vue";
import TitBlurArea from "@/components/TitBlurArea.vue";
import AdmArea from "@/components/AdmArea.vue";

import VideoLightBox from "@/components/VideoLightBox.vue";
import VideoYTLightBox from "@/components/VideoYTLightBox.vue";

import {
  apiGetCategorys,
  apiGetHostList,
  apiGetNewsList,
  apiGetArticleNewsList,
  apiGetNews,
  apiNewsTop,
  apiBannerMiddle,
} from "@/api/api.js";

export default {
  data() {
    return {
      //=== 中幅廣告 ===
      titBlurData: {
        img: "",
        tit: "",
        sup: "",
        link: "",
      },
      //=== 新聞消息 ===
      newsListData: {
        MenuTypeID: "21050416184989967", //固定由 /ArticleNews/GetArticleNewsMenuType 取得 { "ID": 21050416184989967,"Title": "主頁"},
        index: 0,
        CategoryID: [],
        Keyword: "",
        IsAll: false,
      },
      newsList: [
        // { link: "###", tit: "桃源勤和-復興便道開放通行 一早車輛大排長龍" },
        // { link: "###", tit: "聚會所啟用.頒原保地所有權狀 福原熱鬧慶祝" },
        // { link: "###", tit: "56廣播金鐘獎入圍名單揭曉 Alian獲8項提名" },
        // { link: "###", tit: "睽違已久再現本土 0 指揮中心:把握清零機會" },
        // { link: "###", tit: "'minngayan醫猶未盡' 張維倫攝影展新竹登場" },
        // { link: "###", tit: "參與運動競賽曾受阻 展覽陳列女性發聲過程" },
        // { link: "###", tit: "台東大溪基礎設施未完善 通過發振興禮金引質疑" },
      ],
      //=== 新聞分類 ===
      newsTypeData: {
        index: 0,
        ProgramType: 2, //節目種類: 0=原視節目, 1=廣播, 2=新聞 = ['0', '1', '2'],
        IsAll: false,
      },
      newsType: [
        // { ID: 1, Title: "原鄉", isAct: true },
        // { id: 2, txt: "深度", isAct: false },
        // { id: 3, txt: "政經", isAct: false },
        // { id: 4, txt: "教文", isAct: false },
        // { id: 5, txt: "生活", isAct: false },
        // { id: 6, txt: "體育", isAct: false },
        // { id: 7, txt: "國際", isAct: false },
        // { id: 8, txt: "人物", isAct: false },
        // { id: 9, txt: "專題", isAct: false },
        // { id: 10, txt: "時事議題", isAct: false },
      ],
      //=== 最上面推薦節目列表 ===
      NewsVideoList: [
        // {
        //   img: "news_top_01_1.gif",
        //   tit: "", //《原視新聞網》正式上線！每日提供最即時、最深入的原民觀點新訊
        //   sub: "",
        //   videoId: "8Pa7KqJg3UA",
        // },
        // {
        //   img: "news_top_02_1.gif",
        //   tit: "", //原觀點
        //   sub: "",
        //   videoId: "idYHAGi4jRk",
        // },
        // {
        //   img: "news_top_03_1.gif",
        //   tit: "", //族語新聞
        //   sub: "",
        //   videoId: "Q5rZyryJbKY",
        // },
        // {
        //   img: "news_top_04_1.gif",
        //   tit: "", //a'iyalaeho:開會了
        //   sub: "",
        //   videoId: "vVqvhvnpjvc",
        // },
        // {
        //   img: "news_top_05_1.gif",
        //   tit: "", //部落影響力
        //   sub: "",
        //   videoId: "eMiyKJgl-Ng",
        // },
        // {
        //   img: "news_top_06_1.jpeg",
        //   tit: "", //《原視新聞網》正式上線！每日提供最即時、最深入的原民觀點新訊
        //   sub: "",
        //   videoId: "r51Jmw7m7lg",
        // },
        // {
        //   img: "news_top_07_1.jpeg",
        //   tit: "", //《族語新勢力》第1集-長輩的背簍
        //   sub: "",
        //   videoId: "-7XkP5mEm9c",
        // },
      ],
      //=== 節目列表 ===
      SwProgramVideoList: [
        {
          img: "prog01.jpg",
          tit: "部落影響力1",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
          thumbNum: "10",
        },
        {
          img: "prog02.jpg",
          tit: "部落影響力2",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
          thumbNum: "20",
        },
        {
          img: "prog03.jpg",
          tit: "部落影響力3",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
          thumbNum: "30",
        },
        {
          img: "prog01.jpg",
          tit: "部落影響力4",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
          thumbNum: "40",
        },
        {
          img: "prog02.jpg",
          tit: "部落影響力5",
          cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
          thumbNum: "50",
        },
      ],
      //=== 節目列表 ===
      NewsListItem: [
        // {
        //   img: "news_01.jpg",
        //   tit: "聚會所啟用.頒原保地所有權狀 福原熱鬧慶祝",
        //   cont: "2021-08-25",
        //   thumbNum: "10",
        //   videoUrl: "https://youtu.be/C_6xUYFPxtc",
        // },
        // {
        //   img: "news_02.jpg",
        //   tit: "原舞者樂舞文化工坊 30學員齊聚花蓮學習",
        //   cont: "2021-08-25",
        //   thumbNum: "20",
        //   videoUrl: "https://youtu.be/mjM7pLz1y2c",
        // },
        // {
        //   img: "news_03.jpg",
        //   tit: '"追風少女FALI"入圍金曲 阿美姊妹花闖樂壇',
        //   cont: "2021-08-25",
        //   thumbNum: "20",
        //   videoUrl: "https://youtu.be/35Fo6kfH7_Q",
        // },
        // {
        //   img: "news_04.jpg",
        //   tit: "56廣播金鐘獎入圍名單揭曉 Alian獲8項提名",
        //   cont: "2021-08-25",
        //   thumbNum: "20",
        //   videoUrl: "https://youtu.be/-yIxloj9z5A",
        // },
        // {
        //   img: "news_05.jpg",
        //   tit: "花蓮辦三級棒球垂直整合訓練營 郭泓志任講師",
        //   cont: "2021-08-25",
        //   thumbNum: "20",
        //   videoUrl: "https://youtu.be/xEjSMD6eNKY",
        // },
      ],
      //=== 節目分類列表 ===
      // ProgramBlurSwName: "programSubSwiper",
      // ProgramBlurList: [
      //   {
      //     titCont: {
      //       img: "bg_blur03.jpg",
      //       tit: "a'iyalaeho:開會了",
      //       sup: "因為有這麼多的語言聲音，<br />這個世界才豐富美麗。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      //   {
      //     titCont: {
      //       img: "bg_blur04.jpg",
      //       tit: "kai試英雄之全原出動",
      //       sup: "熱歌勁舞加Rap，<br />最熱情的中排灣族來了。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      //   {
      //     titCont: {
      //       img: "bg_blur05.jpg",
      //       tit: "kai試英雄之全原出動",
      //       sup: "熱歌勁舞加Rap，<br />最熱情的中排灣族來了。",
      //     },
      //     swIsShow: true,
      //     swList: [
      //       {
      //         img: "pro_sub01.png",
      //         tit: "部落影響力1",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub02.png",
      //         tit: "部落影響力2",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub03.png",
      //         tit: "部落影響力3",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub04.png",
      //         tit: "部落影響力4",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //       {
      //         img: "pro_sub05.png",
      //         tit: "部落影響力5",
      //         cont: "在我們身邊，有許多默默做事但很有影響力的人，他與她的人生故事。",
      //       },
      //     ],
      //   },
      // ],
      //=== 需執行的節目SW ===
      //   /swFunctionList: ["programSubSwiper1"],
      //=== 主持人列表 ===
      hostInfo: false,
      hostInfoData: {
        img: require("@/assets/img/host_default.jpg"),
        name: "主持人",
        cont: "-",
      },
      hostListData: {
        index: 0,
        Keyword: "",
        IsAll: false,
        Parts: ["原視新聞"], // 原視節目/Alian廣播/原視新聞/原視新聞族語
      },
      hostRandomPage: "",
      hostPage: "",
      hostPageTotal: "",
      hostPageList: [],
      //=== 新聞主播 ===
      hostPerList: [
        // { img: "host_01.png", name: "Ohay Sewana" },
        // { img: "host_02.png", name: "Iwan Nawe" },
        // { img: "host_03.png", name: "Awe Nawi" },
        // { img: "host_04.png", name: "Sulryape Gadhu" },
        // { img: "host_05.png", name: "Miming Karaw" },
        // { img: "host_06.png", name: "Ava'e poiconʉ" },
        // { img: "host_07.png", name: "okay a’ataw hayawan" },
        // { img: "host_08.png", name: "Tasaw．Watan" },
        // { img: "host_09.png", name: "Landuun Ismahasan" },
        // { img: "host_010.png", name: "Vagyatan" },
        // { img: "host_011.png", name: "Araytay paregi" },
        // { img: "host_012.png", name: "Muni Kadrangian" },
        // { img: "host_013.png", name: "Ruyu" },
        // { img: "host_014.png", name: "Djupelang Tjaljiges" },
        // { img: "host_015.png", name: "Imi. Mayaw" },
        // { img: "host_016.png", name: "kurali lhkashnawanan" },
        // { img: "host_017.png", name: "Pani Kanapaniana" },
        // { img: "host_018.png", name: "Vintiia masiatuhluhlʉ" },
      ],
      //新聞列表
      actNewsType: "",
      getArticleNewsData: {
        index: 0,
        IsAll: true,
        KeyWord: "",
        //"NewsType": [0],
        NewsCategorys: [], //預設文化小辭典 - 21083117324248840
      },
    };
  },
  mounted() {
    //=== 取得新聞類別 ===
    apiGetCategorys(this.newsTypeData)
      .then((result) => {
        console.log(result.data);
        var resStatuse = result.data.success;
        var resCode = result.data.errorcode;
        if (resStatuse == true && resCode == 0) {
          var resData = result.data.RetData;
          var reList = [];
          this.$.each(resData, (ind, val) => {
            var { ID, Title } = val;
            ind == 0
              ? reList.push({ ID: ID.toString(), Title: Title, isAct: true })
              : reList.push({ ID: ID.toString(), Title: Title, isAct: false });
          });
          this.newsType = reList;
          this.actNewsType = this.newsType[0].ID;
          //=== 第一次取得 節目列表 ===
          // this.getProgramListData.CategoryID.push(reList[0].ID); //帶入第一個 新聞・體育・時事 的ID
          // this.getProgramList(true);
          this.getArticleNewsData.NewsCategorys.push(this.newsType[0].ID);
          this.getArticleNews();
          //=== 取得列表 ===
          // this.getProgramDetail("21081609193308851");
          // this.getProgramEpisode("21081609193308851");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //=== 取得新聞上方影片列表 ===
    this.getNewsTop();
    //=== News最新消息 ===
    // this.getNewsList();
    this.getNews();
    //=== 主持人列表 ===
    // this.getHostList();
    this.getHostPageList();
    //=== 中幅廣告 ===
    this.getBannerMiddle();
    //=== 取得類別節目列表 ===
    this.$bus.$on("toProgramTypeId", (data) => {
      console.log("點擊接收資料", data);
      console.log("點擊接收ID", data.typeId);
      this.getArticleNewsData.NewsCategorys = [data.typeId];
      this.actNewsType = data.typeId;
      // this.actNewsType = [data.typeId];//這樣會變成陣列
      //取得新聞列表
      this.getArticleNews();
    });
    //=== 主持人列表頁數 ===
    this.$bus.$on("hostPageData", (data) => {
      // console.log("接收主持人列表頁數", data);
      // this.hostListData.index = data;
      this.hostListData.index = this.hostPageList[data];
      this.getMoreHostList();
    });
    //=== 主持人詳細資料 ===
    this.$bus.$on("hostInfoData", (data) => {
      this.setHostSrcData(data);
    });

    //新聞文章列表
    // this.getArticleNews();

    //== swiper ==
    //== 公告跑馬燈 ==
    // var swiperMarquee = new Swiper("#swiperMarquee", {
    //   direction: "vertical",
    //   spaceBetween: 0,
    //   //- freeMode: true,
    //   noSwipingClass: "news-marquee-box",
    //   loop: true,
    //   speed: 500,
    //   autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: false,
    //   },
    // });
    // //== video ==
    // var newsVideo = new Swiper("#topNewsVideo", {
    //   slidesPerView: "1",
    //   spaceBetween: 25,
    //   followFinger: false,
    //   observer: true,
    //   observeParents: true,
    //   longSwipes: true,
    //   longSwipesMs: 500,
    //   speed: 800,
    //   // freeMode: true,
    // });

    // newsVideo.slideTo(1, 0);
    //== newsTypeVideo ==
    var newsTypeVideo = new Swiper("#newsTypeVideo", {
      slidesPerView: "1",
      spaceBetween: 25,
      observer: true,
      observeParents: true,
      followFinger: false,
      longSwipes: true,
      longSwipesMs: 500,
      speed: 800,
      // freeMode: true,
    });
    //== newsTypeTab ==
    var newsTypeTab = new Swiper("#newsTypeTab", {
      slidesPerView: "auto",
      spaceBetween: 25,
      freeMode: true,
    });
    // newsTypeVideo.slideTo(1, 0);
    //=== 啟動swiper ===
    this.$.each(this.swFunctionList, (ind, val) => {
      new Swiper("#" + val, {
        slidesPerView: "auto",
        spaceBetween: 25,
        freeMode: true,
      });
    });
    //=== 啟動後關閉 sub swiper ===
    this.$.each(this.ProgramBlurList, (ind, val) => {
      val.swIsShow = false;
    });
    //== hostSw ==
    // var hostSw = new Swiper("#hostSw", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
    //=== admSwiper ===
    // var admSwiper = new Swiper("#admSwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
  },
  methods: {
    //=== 上方影片列表 ===
    getNewsTop() {
      apiNewsTop()
        .then((result) => {
          console.log("新聞上方影片");
          console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            var dataList = [];
            this.$.each(resData, (ind, val) => {
              var { id, title, program_title, img, m3u8_url } = val;
              dataList.push({
                id,
                img,
                tit: program_title,
                sub: title,
                videoLink: m3u8_url,
                swType: "program",
              });
            });
            this.NewsVideoList = dataList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 主持人列表 ===
    getHostPageList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人列表頁數處理");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var { Page, TotalPage } = result.data;
            console.log(result.data);
            //== 隨機初始頁碼 ==
            //因為如果只有一筆的話，random 會是0 但沒關係，因為給 0 api 會回第一頁
            //最後一筆因為不會 random 到，所以可以判斷 loading 到最後一筆時不要在call api
            var maxPage = TotalPage; //TotalPage + 1 先不加一 因為最後一頁可能只有一筆會跑不了
            console.log(maxPage);
            this.hostRandomPage = Math.floor(Math.random() * maxPage);
            //== 如果為 0 雖然會是第一頁 但是進到讀取列表內 會無法判斷到 0 第一頁不會排除掉 ==
            if (this.hostRandomPage == 0) {
              this.hostRandomPage = 1;
            }
            //== 放入所有頁碼 ==
            for (var i = 0; i < TotalPage; i++) {
              var page = i + 1;
              if (this.hostRandomPage != page) {
                this.hostPageList.push(page);
              }
            }
            this.hostPage = 0;
            this.hostPageTotal = this.hostPageList.length;
            //== 呼叫主持人隨機頁列表 ==
            this.hostListData.index = this.hostRandomPage;
            this.getHostList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHostList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("新聞主持人列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            // var { Page, TotalPage } = result.data;
            // console.log(Page, TotalPage);
            // this.hostPage = Page;
            // this.hostPageTotal = TotalPage;
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Name, Icon, Description } = val;
              list.push({
                hostId: ID.toString(),
                img: Icon.ImgUrl,
                name: Name,
                Description,
              });
            });
            this.hostPerList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMoreHostList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人後續列表");
          // console.log(JSON.parse(result.data));
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            // var { Page, TotalPage } = result.data;
            // console.log(Page, TotalPage);
            // this.hostPage = Page;
            // this.hostPageTotal = TotalPage;
            this.hostPage = this.hostPage + 1;
            var resData = result.data.RetData;
            console.log(resData);
            this.$.each(resData, (ind, val) => {
              var { ID, Name, Icon, Description } = val;
              this.hostPerList.push({
                hostId: ID.toString(),
                img: Icon.ImgUrl,
                name: Name,
                Description,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setHostSrcData(hostId) {
      this.hostInfo = true;
      var actHost = this.hostPerList.filter((item) => {
        return item.hostId == hostId;
      });
      var { img, name, Description } = actHost[0];
      this.hostInfoData.img = img;
      this.hostInfoData.name = name;
      this.hostInfoData.cont = Description;
    },
    //=== News最新消息 ===
    getNewsList() {
      apiGetNewsList(this.newsListData)
        .then((result) => {
          console.log("News列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Title } = val;
              list.push({
                newsId: ID.toString(),
                tit: Title,
                link: "###",
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNews() {
      apiGetNews({
        params: {
          part: 4,
        },
      })
        .then((result) => {
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var { ID, Content, Link } = val;
              list.push({
                newsId: ID.toString(),
                tit: Content,
                link: Link,
              });
            });
            this.newsList = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取得新聞文章
    getArticleNews() {
      apiGetArticleNewsList(this.getArticleNewsData)
        .then((result) => {
          console.log("取得新聞列表");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var list = [];
            this.$.each(resData, (ind, val) => {
              var {
                ID,
                Title,
                m3u8Url,
                Content,
                CustomCreateTime,
                Journalist,
                Image,
              } = val;
              list.push({
                ID: ID.toString(),
                Title: Title,
                m3u8Url: m3u8Url,
                Content: Content,
                CustomCreateTime: CustomCreateTime,
                Journalist: Journalist,
                Image: Image,
              });
            });
            this.NewsListItem = list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 中幅廣告 ===
    getBannerMiddle() {
      apiBannerMiddle()
        .then((result) => {
          console.log("=== 中幅廣告 ===");
          // console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            console.log(resData);
            //category -> Live,廣播,新聞
            this.$.each(resData, (ind, val) => {
              var { category, img_url, title, subtitle, link } = val;
              if (category == "新聞") {
                this.titBlurData.img = img_url;
                this.titBlurData.tit = title;
                this.titBlurData.sup = subtitle;
                this.titBlurData.link = link;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 關閉新聞主持人資料 ===
    hostInfoClose() {
      this.hostInfo = false;
    },
  },
  computed: {
    //=== 抓取需要跑 swiper 的 Id ===
    swFunctionList() {
      var list = [];
      this.$.each(this.ProgramBlurList, (ind, val) => {
        list.push(this.ProgramBlurSwName + (ind + 1));
      });
      return list;
    },
  },
  //== 元件銷毀前要取消監聽 ==
  beforeDestroy() {
    this.$bus.$off("toProgramTypeId");
    this.$bus.$off("hostPageData");
    this.$bus.$off("hostInfoData");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    SwNews,
    // NewsVideo,
    TopVideoSw,
    HostNewsSwList,
    // NewsList,
    NewsListTest,
    TypeTabSw,
    TitBlurArea,
    AdmArea,
    // ProgramBlurArea,
    VideoLightBox,
    VideoYTLightBox,
  },
};
</script>
<style scoped>
.live-type-video-img img {
  width: 200px;
  height: 110px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>