<template>
  <!-- v-if="dataL != 0" -->
  <div>
    <div class="program-group" :style="{backgroundImage:'url('+titCont.img+')'}">
      <div class="program-mask"></div>
      <ProgramBlurTit
        :id="titCont.id"
        :img="titCont.img"
        :tit="titCont.tit"
        :sup="titCont.sup"
        :cont="titCont.cont"
        :swIsShow.sync="swShowStatus"
      />
      <transition name="slide">
        <div class="program-sub-swiper-area" v-show="swShowStatus">
          <ProgramBlurSw
            :ind="ind"
            :swId="titCont.id"
            :swPage="swPage"
            :swPageTotal="swPageTotal"
            :swName="swName"
            :swList="swList"
            :isBanner="false"
            :bannerImg="titCont.img"
            v-if="swList.length != 0"
          />
          <!-- <div
            class="swiper-container program-sub-swiper"
            :id="swName + (ind + 1)"
          >
            <div class="swiper-wrapper">
              <ProgramSubVideo
                v-for="(item, index) in swList"
                :key="index"
                :img="item.img"
                :tit="item.tit"
                :cont="item.cont"
              />
            </div>
          </div> -->
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import ProgramBlurTit from "@/components/ProgramBlurTit.vue";
import ProgramBlurSw from "@/components/ProgramBlurSw.vue";
// import ProgramSubVideo from "@/components/ProgramSubVideo.vue";

export default {
  name: "ProgramBlurArea",
  props: [
    "dataL",
    "ind",
    "titCont",
    "swPage",
    "swPageTotal",
    "swIsShow",
    "swName",
    "swList",
  ],
  data() {
    return {
      // swCont: "",
    };
  },
  mounted() {
    //把sw拆出去因為節目集數需要另外API取得
    // var swName = this.swName + (this.ind + 1);
    // console.log("=== mounted ===" + swName);
    // this.swCont = new Swiper("#" + swName, {
    //   slidesPerView: "auto",
    //   spaceBetween: 15,
    //   freeMode: true,
    // });
    // this.swCont.slideTo(1, 0);
    //需要跑 Swiper ，跑完後關閉
    // this.swShowStatus = false;
  },
  computed: {
    swShowStatus: {
      get() {
        return this.swIsShow;
      },
      set(val) {
        this.$emit("update:swIsShow", val);
      },
    },
  },
  watch: {
    //watch props 傳進來的一個值，有改變才會有啟動監控，更換資料就算！
    // titCont(newVal) {
    //   console.log("== watch ==");
    //   // this.swShowStatus = false;
    //   // this.swCont.slideTo(0, 0);
    //   // console.log(newVal);
    // },
    // swList(newVal) {
    //   // this.swCont.update();
    // },
  },
  components: {
    ProgramBlurTit,
    ProgramBlurSw,
    // ProgramSubVideo,
  },
};
</script>
<style>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: ease-in; */
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
}

.program-sub-swiper-area {
  margin: 0;
  height: 200px;
}

.slide-enter-to,
.slide-leave {
  /* max-height: 226px; */
  height: 210px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  /* max-height: 0; */
  height: 0;
}
</style>